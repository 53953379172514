import { memo } from "react";

import { Box, Image } from "@chakra-ui/react";

type Props = {
  image: string;
};

function Img(props: Props) {
  return (
    <Box w="full" h="full">
      <Image
        w="full"
        h="full"
        objectFit="fill"
        src={props.image}
        borderRadius={{
          base: "1rem",
          mobile8: "1.5rem",
          mobile10: "1.9rem",
          tablet12: "3.0rem",
          desktop15: "3.3rem",
        }}
      />
    </Box>
  );
}

export default memo(Img);
