import { PropertyTypes } from "interfaces/property";
import { NavButtonVillas } from "../NavButtons/NavButton";

import SectionOne from "./ForthOptionButtons/SectionOne";
import SectionTwo from "./ForthOptionButtons/SectionTwo";
import SectionThree from "./ForthOptionButtons/SectionThree";
import SectionFour from "./ForthOptionButtons/SectionFour";
import SectionFive from "./ForthOptionButtons/SectionFive";

export function TownhouseOption(props: { displayed: boolean }) {
  const display = props.displayed ? undefined : "none";
  const type = PropertyTypes.TOWNHOUSE;

  return (
    <>
      <SectionOne display={display} type={type} />
      <SectionTwo display={display} type={type} />
      <SectionThree display={display} type={type} />
      <SectionFour display={display} type={type} />
      <SectionFive display={display} type={type} />
    </>
  );
}

export function FiveBedroomVillaOption(props: { displayed: boolean }) {
  const display = props.displayed ? undefined : "none";

  const bedroom = 5;
  const type = PropertyTypes.VILLA;

  return (
    <>
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={346}
        buttonWidth="6%"
        buttonHeight="6.2%"
        topOffset="31.9%"
        leftOffset="75%"
        hasUnits={true}
        plotNumber="300"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={346}
        buttonWidth="6%"
        buttonHeight="6.2%"
        topOffset="23.9%"
        leftOffset="74.7%"
        hasUnits={true}
        plotNumber="301"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={315}
        buttonWidth="6%"
        buttonHeight="9.3%"
        topOffset="13.6%"
        leftOffset="73.7%"
        hasUnits={true}
        plotNumber="302"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={286}
        buttonWidth="6%"
        buttonHeight="8.3%"
        topOffset="8.4%"
        leftOffset="69.3%"
        hasUnits={true}
        plotNumber="303"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={251}
        buttonWidth="5.2%"
        buttonHeight="6%"
        topOffset="10.6%"
        leftOffset="64.5%"
        hasUnits={true}
        plotNumber="304"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={251}
        buttonWidth="5.2%"
        buttonHeight="6%"
        topOffset="13.6%"
        leftOffset="60.7%"
        hasUnits={true}
        plotNumber="305"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={251}
        buttonWidth="5.2%"
        buttonHeight="6%"
        topOffset="15.8%"
        leftOffset="57.2%"
        hasUnits={true}
        plotNumber="306"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={251}
        buttonWidth="4.9%"
        buttonHeight="6%"
        topOffset="19.2%"
        leftOffset="53.8%"
        hasUnits={true}
        plotNumber="307"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={251}
        buttonWidth="4.9%"
        buttonHeight="9%"
        topOffset="21%"
        leftOffset="49.9%"
        hasUnits={true}
        plotNumber="308"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={268}
        buttonWidth="4.9%"
        buttonHeight="9%"
        topOffset="23.3%"
        leftOffset="44.5%"
        hasUnits={true}
        plotNumber="309"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={282}
        buttonWidth="6.3%"
        buttonHeight="8.8%"
        topOffset="23.5%"
        leftOffset="38.5%"
        hasUnits={true}
        plotNumber="310"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={282}
        buttonWidth="6.3%"
        buttonHeight="8.8%"
        topOffset="22.2%"
        leftOffset="33.4%"
        hasUnits={true}
        plotNumber="311"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={282}
        buttonWidth="6.3%"
        buttonHeight="7.8%"
        topOffset="21.2%"
        leftOffset="28.6%"
        hasUnits={true}
        plotNumber="312"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={289}
        buttonWidth="5.8%"
        buttonHeight="7.8%"
        topOffset="18.3%"
        leftOffset="24.2%"
        hasUnits={true}
        plotNumber="313"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        isDisplayed={display}
        buildingType={type}
        rotateDegree={289}
        buttonWidth="4.9%"
        buttonHeight="7.8%"
        topOffset="15%"
        leftOffset="19.6%"
        hasUnits={true}
        plotNumber="314"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="25.7%"
        leftOffset="16.1%"
        buttonWidth="4.6%"
        buttonHeight="9%"
        rotateDegree={21}
        hasUnits={true}
        plotNumber="315"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="29%"
        leftOffset="21.2%"
        buttonWidth="3.2%"
        buttonHeight="9%"
        rotateDegree={17}
        hasUnits={true}
        plotNumber="316"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="32%"
        leftOffset="25%"
        buttonWidth="3%"
        buttonHeight="10%"
        rotateDegree={17}
        hasUnits={true}
        plotNumber="317"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="35%"
        leftOffset="28.5%"
        buttonWidth="3%"
        buttonHeight="10%"
        rotateDegree={17}
        hasUnits={true}
        plotNumber="318"
      />
      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="37%"
        leftOffset="32.3%"
        buttonWidth="5%"
        buttonHeight="10%"
        rotateDegree={17}
        hasUnits={true}
        plotNumber="319"
      />
    </>
  );
}
