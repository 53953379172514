import { useGetQueryValue } from "../../utils/Values";

export enum PropertyTypes {
  APARTMENT = "Apartment",
  PENTHOUSE = "Penthouse",
  DUPLEX = "Duplex",
  TOWNHOUSE = "Townhouse",
  BR3_VILLA_A = "Villa-A",
  BR3_VILLA_B = "Villa-B",
  BR4_VILLA = "Villa",
  BR5_VILLA = "Villa",
}

export default function useQueryPropertyType() {
  const propertyTypeQueryValue = useGetQueryValue("type");

  if (!propertyTypeQueryValue) {
    return "apartment";
  }

  switch (propertyTypeQueryValue) {
    case PropertyTypes.APARTMENT:
      return PropertyTypes.APARTMENT;

    case PropertyTypes.PENTHOUSE:
      return PropertyTypes.PENTHOUSE;

    case PropertyTypes.DUPLEX:
      return PropertyTypes.DUPLEX;

    case PropertyTypes.TOWNHOUSE:
      return PropertyTypes.TOWNHOUSE;

    case PropertyTypes.BR3_VILLA_A:
      return PropertyTypes.BR3_VILLA_A;

    case PropertyTypes.BR3_VILLA_B:
      return PropertyTypes.BR3_VILLA_B;

    case PropertyTypes.BR4_VILLA:
    case PropertyTypes.BR5_VILLA:
      return PropertyTypes.BR4_VILLA;

    default:
      console.error(`Invalid property type: ${propertyTypeQueryValue}`);
      return PropertyTypes.APARTMENT;
  }
}
