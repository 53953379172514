import {
  useChoseStyleOnLandscape,
  useChoseValueOnLandscape,
} from "../../../utils/Selecting";
import { VStack, HStack } from "@chakra-ui/react";

//? Icons Sources
import { BsCartFill } from "react-icons/bs";
import { IoBookSharp, IoSchoolSharp } from "react-icons/io5";
import { ImAidKit } from "react-icons/im";
import { RiBuilding2Fill } from "react-icons/ri";
import SingleOptionButton from "./single-option-button/SingleOptionButton";
import RestaurantIcon from "../../../img/icons/location-map/RestaurantIcon";

export default function OptionsButtons() {
  const StackType = useChoseValueOnLandscape(VStack, HStack);

  // Landscape Styling.
  const containerStylesInLandscape = {
    w: {
      "1xsm": "21%",
    },
    h: {
      "1xsm": "100%",
    },
    spacing: {
      "1xsm": "0.8rem",
      sm: "0.5rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  // Landscape Styling.
  const subContainerStylesInLandscape = {
    spacing: {
      "1xsm": "0.7rem",
      sm: "0.5rem",
      xl: "1.3rem",
      lg: "1.2rem",
      ipadPro: "2.5rem",
      MacBookPro16: "1.3rem",
    },
    h: {
      sm: "50%",
      "1xsm": "45%",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  return (
    <VStack
      w="100%"
      h={{ base: "13%", mobile8: "15%", tablet16: "13%" }}
      spacing={{ base: "0.5rem", tablet16: "1rem" }}
      flex={{ base: 4 }}
      {...styles}
    >
      <StackType
        w="97%"
        h="49%"
        spacing={{ base: "0.5rem", tablet16: "1rem" }}
        {...subContainerStyles}
      >
        <SingleOptionButton optionName="Shopping" icon={BsCartFill} />
        <SingleOptionButton optionName="University" icon={IoSchoolSharp} />
        <SingleOptionButton optionName="School" icon={IoBookSharp} />
      </StackType>
      <StackType
        w="97%"
        h="49%"
        spacing={{ base: "0.5rem", tablet16: "1rem" }}
        {...subContainerStyles}
      >
        <SingleOptionButton optionName="Club" icon={RestaurantIcon} />
        <SingleOptionButton optionName="Medical" icon={ImAidKit} />
        <SingleOptionButton isResidential optionName="Residential" icon={RiBuilding2Fill} />
      </StackType>
    </VStack>
  );
}
