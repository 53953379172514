import { memo, useEffect } from "react";

import { useAnimation } from "framer-motion";

import MotionIcon from "../../../../../animated-components/MotionIcon/MotionIcon";

import { IoLocation } from "react-icons/io5";

import { IconType } from "react-icons/lib";

import { useSelector } from "react-redux";

import { selectIconAnimationState } from "../InfoBoxSlice";

type Props = {
  icon?: IconType;
};

function InfoIcon(props: Props) {
  const shouldAnimationStart = useSelector(selectIconAnimationState);

  const controls = useAnimation();

  useEffect(() => {
    if (shouldAnimationStart) {
      controls.start({
        height: ["100%", "30%"],
      });
    }
    
    if (!shouldAnimationStart) {
      controls.start({
        height: ["30%", "100%"],
      });
    }
  }, [shouldAnimationStart, controls]);

  return (
    <MotionIcon
      animate={controls}
      transition={{
        duration: 1,
      }}
      asIcon={props.icon || IoLocation}
      iconSize={undefined}
      w="full"
      iconColor="#bf1723"
    />
  );
}

export default memo(InfoIcon);
