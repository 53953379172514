import useHideAfterTimeIfOpen, {
  ReactSetState,
} from "./useHideAfterTimeIfOpen";

import axios, { AxiosResponse } from "axios";
import { useEffect } from "react";
import Property, { Status } from "../../../interfaces/property";

// import baseURL from "../../../constants/baseURL";

type OpenConfigs = {
  isOpen: boolean;
  closeElement: ReactSetState<boolean>;
  time?: number;
};

export default function useMakeUnitPending(
  unitID: string,
  openConfig: OpenConfigs
) {
  const { isOpen, closeElement, time } = openConfig;

  useHideAfterTimeIfOpen(isOpen, closeElement, time);

  // const property = getUnit(unitID);
  // console.debug(property);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (isOpen) {
      timer = setInterval(() => {
        updateUnitToBePending(unitID);
      }, 1500);
    }

    return () => {
      updateUnitToItsPrevStatus(
        unitID,
        Status.NOT_AVAILABLE
      );

      if (timer) clearInterval(timer);
    };
  }, [isOpen, unitID]);
}

function getUrl(): string {
  return "https://celltek-real-estate.herokuapp.com";
}

/*
function getUnit(id: string): Property | null {
  let property: Property | null = null;

  axios
    .get<Property>(`/properties/${id}`, {
      withCredentials: true,
      baseURL,
    })
    .then((response) => {
      property = response.data;
      // console.log(property);
    })
    .catch(() => {
      console.error("Error fetching property");
      property = null;
    });

  return property;
}
*/

export function updateUnitToBePending(id: string | null) {
  if (id === null) {
    return;
  }

  axios
    .put<Property, AxiosResponse<Property>, Partial<Property>>(
      `/properties/${id}`,
      {
        Status: Status.PENDING,
      },
      {
        withCredentials: true,
        baseURL: getUrl(),
      }
    )
    .then((response) => {
      console.debug(`Calling After Property ${id} Updated To Pending`);
    })
    .catch(() => {
      console.error("Error updating property");
    });
}

export function updateUnitToItsPrevStatus(id: string | null, status: Status) {
  if (id === null) {
    return;
  }

  axios
    .put<Property, AxiosResponse<Property>, Partial<Property>>(
      `/properties/${id}`,
      {
        Status: status,
      },
      {
        withCredentials: true,
        baseURL: getUrl(),
      }
    )
    .then(() => {
      // console.debug(`Unit status updated to ${status}`);
    })
    .catch((err) => {
      console.log(err);
    });
}
