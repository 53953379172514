import { VStack } from "@chakra-ui/react";

import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

export default function Container(props: any) {
  const containerStylesInLandscape = {
    h: {
      "1xsm": "90%",
    },
    overflow: "hidden",
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <VStack
      w="100%"
      h="55%"
      spacing={{
        base: "0.2rem"
      }}
      flex={2}
      {...styles}
    >
      {props.children}
    </VStack>
  );
}
