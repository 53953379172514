import { IconType } from "react-icons";

import { IoBookSharp, IoSchoolSharp, IoGitPullRequest } from "react-icons/io5";
import { RiBuilding2Fill } from "react-icons/ri";
import { BsCartFill } from "react-icons/bs";
import { ImAidKit } from "react-icons/im";

import RestaurantIcon from "../../../img/icons/location-map/RestaurantIcon";

export const enum IconsNames {
  Residential = "Residential",
  University = "University",
  Shopping = "Shopping",
  Medical = "Medical",
  School = "School",
  Club = "Club",
}

export function pickIcon(iconName: IconsNames): IconType | any {
  switch (iconName) {
    case IconsNames.Residential:
      return RiBuilding2Fill;
    case IconsNames.University:
      return IoSchoolSharp;
    case IconsNames.Shopping:
      return BsCartFill;
    case IconsNames.Medical:
      return ImAidKit;
    case IconsNames.School:
      return IoBookSharp;
    case IconsNames.Club:
      return RestaurantIcon;
    default:
      return IoGitPullRequest;
  }
}
