import {HStack} from "@chakra-ui/react";

import OptionBtn from "../SingleOption";
import Container from "./Container";

type OptionsButtonsProps = {
    setActiveSwimmingPoolPin: (x: boolean) => any;
    setActiveFootballPin: (x: boolean) => any;
    setActiveKidsAreaPin: (x: boolean) => any;
    setActiveMedicalPin: (x: boolean) => any;
    setActiveRetailPin: (x: boolean) => any;
    setActiveMosquePin: (x: boolean) => any;
    setActiveTennisPin: (x: boolean) => any;
    setActiveClubPin: (x: boolean) => any;
    setActiveGYMPin: (x: boolean) => any;
};

export default function OptionsButtons(props: OptionsButtonsProps) {
    function ActiveOnlySwimmingPoolPin() {
        props.setActiveSwimmingPoolPin(true);

        props.setActiveFootballPin(false);
        props.setActiveKidsAreaPin(false);
        props.setActiveMedicalPin(false);
        props.setActiveRetailPin(false);
        props.setActiveMosquePin(false);
        props.setActiveTennisPin(false);
        props.setActiveClubPin(false);
        props.setActiveGYMPin(false);
    }

    function ActiveOnlyFootballPin() {
        props.setActiveFootballPin(true);

        props.setActiveSwimmingPoolPin(false);
        props.setActiveKidsAreaPin(false);
        props.setActiveMedicalPin(false);
        props.setActiveRetailPin(false);
        props.setActiveMosquePin(false);
        props.setActiveTennisPin(false);
        props.setActiveClubPin(false);
        props.setActiveGYMPin(false);
    }

    function ActiveOnlyKidsAreaPin() {
        props.setActiveKidsAreaPin(true);

        props.setActiveSwimmingPoolPin(false);
        props.setActiveFootballPin(false);
        props.setActiveMedicalPin(false);
        props.setActiveRetailPin(false);
        props.setActiveMosquePin(false);
        props.setActiveTennisPin(false);
        props.setActiveClubPin(false);
        props.setActiveGYMPin(false);
    }

    function ActiveOnlyMedicalPin() {
        props.setActiveMedicalPin(true);

        props.setActiveSwimmingPoolPin(false);
        props.setActiveKidsAreaPin(false);
        props.setActiveFootballPin(false);
        props.setActiveRetailPin(false);
        props.setActiveMosquePin(false);
        props.setActiveTennisPin(false);
        props.setActiveClubPin(false);
        props.setActiveGYMPin(false);
    }

    function ActiveOnlyRetailPin() {
        props.setActiveRetailPin(true);

        props.setActiveSwimmingPoolPin(false);
        props.setActiveFootballPin(false);
        props.setActiveKidsAreaPin(false);
        props.setActiveMedicalPin(false);
        props.setActiveMosquePin(false);
        props.setActiveTennisPin(false);
        props.setActiveClubPin(false);
        props.setActiveGYMPin(false);
    }

    function ActiveOnlyMosquePin() {
        props.setActiveMosquePin(true);

        props.setActiveSwimmingPoolPin(false);
        props.setActiveKidsAreaPin(false);
        props.setActiveRetailPin(false);
        props.setActiveFootballPin(false);
        props.setActiveMedicalPin(false);
        props.setActiveTennisPin(false);
        props.setActiveClubPin(false);
        props.setActiveGYMPin(false);
    }

    function ActiveOnlyTennisPin() {
        props.setActiveTennisPin(true);

        props.setActiveSwimmingPoolPin(false);
        props.setActiveKidsAreaPin(false);
        props.setActiveFootballPin(false);
        props.setActiveMedicalPin(false);
        props.setActiveRetailPin(false);
        props.setActiveMosquePin(false);
        props.setActiveClubPin(false);
        props.setActiveGYMPin(false);
    }

    function ActiveOnlyClubPin() {
        props.setActiveClubPin(true);

        props.setActiveSwimmingPoolPin(false);
        props.setActiveKidsAreaPin(false);
        props.setActiveFootballPin(false);
        props.setActiveMedicalPin(false);
        props.setActiveRetailPin(false);
        props.setActiveMosquePin(false);
        props.setActiveTennisPin(false);
        props.setActiveGYMPin(false);
    }

    function ActiveOnlyGYMPin() {
        props.setActiveKidsAreaPin(false);

        props.setActiveSwimmingPoolPin(false);
        props.setActiveFootballPin(false);
        props.setActiveMedicalPin(false);
        props.setActiveRetailPin(false);
        props.setActiveMosquePin(false);
        props.setActiveTennisPin(false);
        props.setActiveClubPin(false);
        props.setActiveGYMPin(true);
    }

    const containerStyles = {
        spacing: {base: "0.3rem", miniMobile10: "0.5rem", mobile13: "0.7rem", tablet16: "3rem"},
    };

    return (
        <Container>
            <HStack w="100%" h="30%" spacing={containerStyles.spacing}>
                <OptionBtn btnName="Club House" active={ActiveOnlyClubPin}/>
                <OptionBtn btnName="Tennis Court" active={ActiveOnlyTennisPin}/>
                <OptionBtn btnName="Football Court" active={ActiveOnlyFootballPin}/>
            </HStack>
            <HStack w="100%" h="30%" spacing={containerStyles.spacing}>
                <OptionBtn
                    btnName="Swimming Pools"
                    active={ActiveOnlySwimmingPoolPin}
                />
                <OptionBtn btnName="Medical Center" active={ActiveOnlyMedicalPin}/>
                <OptionBtn btnName="Mosque" active={ActiveOnlyMosquePin}/>
            </HStack>
            <HStack w="100%" h="30%" spacing={containerStyles.spacing}>
                <OptionBtn btnName="Kids Area" active={ActiveOnlyKidsAreaPin}/>
                <OptionBtn btnName="GYM & SPA" active={ActiveOnlyGYMPin}/>
                <OptionBtn btnName="Retail Area" active={ActiveOnlyRetailPin}/>
            </HStack>
        </Container>
    );
}
