import { Flex, Link } from "@chakra-ui/react";
import { useState } from "react";
import { Redirect } from "react-router-dom";

export type ElementPositionCoordinates = {
  top: number | string;
  left: number | string;
};

export type ElementDimensions = {
  width: number | string;
  height: number | string;
};

export type LinkInfo = {
  destinationUrl: string;
  isDisplayed: boolean;
};
export interface UnitInfoProps {
  linkInfo?: LinkInfo;
  dimensions?: ElementDimensions;
  position?: ElementPositionCoordinates;
}

export const UnitInfo = (props: UnitInfoProps) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const onClickLink = () => {
    setShouldRedirect(true);
  };

  return (
    <Flex
      as={props.linkInfo?.isDisplayed ? Link : undefined}
      to={props.linkInfo?.destinationUrl}
      pos="absolute"
      w={props.dimensions?.width || "5vw"}
      h={props.dimensions?.height || "10vw"}
      bg={props.linkInfo?.isDisplayed ? "purple.500" : "red.500"}
      top={props.position?.top || 0}
      left={props.position?.left || 0}
      opacity={0.4}
      onClick={onClickLink}
    >
      {shouldRedirect && props.linkInfo?.destinationUrl ? (
        <Redirect to={props.linkInfo?.destinationUrl} />
      ) : null}
    </Flex>
  );
};
