import { memo } from "react";

import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

import { useQueryContainsValue } from "../../utils/Containing";
import ProjectsContainer from "./projects-container/ProjectsContainer";
import { useChoseStyleOnLandscape } from "../../utils/Selecting";

function ProjectWithPropertyType() {
  const isVilla = useQueryContainsValue("title", "villa");
  const title = isVilla ? "Villa" : "Apartment";

  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
    overflow: {
      "1xsm": "hidden",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <PageContainerComponent {...styles}>
      <LocationIndicator text={title} />
      <ProjectsContainer />
    </PageContainerComponent>
  );
}

export default memo(ProjectWithPropertyType);
