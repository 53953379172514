import NewCairoHeroImage from "../NewCairoHeroImage/NewCairoHeroImage";
import ZayedHeroImage from "../ZayedHeroImage/ZayedHeroImage";

import { useChoseOnProjectName } from "../../../utils/Selecting";

export default function PageHeroImage() {
  return useChoseOnProjectName(
    "zayed",
    <ZayedHeroImage />,
    <NewCairoHeroImage />
  );
}
