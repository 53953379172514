import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const enum ActiveState {
  Active = 1,
  NotActive = 0,
}

export interface ActiveIcons {
  Residential: ActiveState;
  University: ActiveState;
  Shopping: ActiveState;
  Medical: ActiveState;
  School: ActiveState;
  Club: ActiveState;
}

const initialState: ActiveIcons = {
  Residential: ActiveState.NotActive,
  University: ActiveState.NotActive,
  Shopping: ActiveState.NotActive,
  School: ActiveState.NotActive,
  Medical: ActiveState.NotActive,
  Club: ActiveState.NotActive,
};

export const activeIconsSlice = createSlice({
  name: "activeIcons",
  initialState,
  reducers: {
    // Activate Logic
    activateIcon: (state: any, action: { type: string; payload: string }) => {
      state.Residential = ActiveState.NotActive;
      state.University = ActiveState.NotActive;
      state.Shopping = ActiveState.NotActive;
      state.School = ActiveState.NotActive;
      state.Medical = ActiveState.NotActive;
      state.Club = ActiveState.NotActive;
      state[action.payload] = ActiveState.Active;
    },
    // Deactivate Logic
    deactivateIcon: (state: any, action: { type: string; payload: string }) => {
      state.Residential = ActiveState.NotActive;
      state.University = ActiveState.NotActive;
      state.Shopping = ActiveState.NotActive;
      state.School = ActiveState.NotActive;
      state.Medical = ActiveState.NotActive;
      state.Club = ActiveState.NotActive;
      state[action.payload] = ActiveState.NotActive;
    },
    deactivateAllIcon: (state: any) => {
      state = initialState;
    },
  },
});

// Reducers
export const {
  activateIcon,
  deactivateIcon,
  deactivateAllIcon,
} = activeIconsSlice.actions;

export const selectActiveIcons = (state: RootState) => state.activeIcons;

export default activeIconsSlice.reducer;
