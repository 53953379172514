import { Center, Skeleton } from "@chakra-ui/react";

// Custom Components
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

import { useChoseStyleOnLandscape } from "../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";

// Image Sources
import CelltekZayedImg from "../../img/buildings/Zayed-Facade.png";
import GreenEnvBuildingsImg from "../../img/projects_page/new-cairo.jpg";

// Lazy logic
import { lazy, Suspense } from "react";

// Lazy components
const AvailableProjectOption = lazy(
  () => import("./available-project-option/AvailableProjectOption")
);

export default function DeliveryYearPage() {
  // Auth before accessing this page
  useVerifyAuth();

  // Landscape Switched Components.
  const StackType = useGetStackTypeElOnPageOrientation();

  // Landscape Styling.
  const containerStylesInLandscape = {
    h: {
      "1xsm": "90%",
    },
    overflow: {
      "1xsm": "unset",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  // Landscape Styling.
  const subContainerStylesInLandscape = {
    overflow: "hidden",
    spacing: {
      "1xsm": "1rem",
    },
    h: {
      "1xsm": "85%",
    },
    minH: {
      "1xsm": "unset",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  return (
    <PageContainerComponent
      spacing={{
        base: "0.5rem",
        tablet16: "2rem",
      }}
      overflow="scroll"
      {...styles}
    >
      <LocationIndicator text="YYYY" />
      <StackType
        w="full"
        minH="89%"
        overflow="scroll"
        spacing={{ base: "0.4rem", mobile8: "2rem", tablet16: "2rem" }}
        {...subContainerStyles}
      >
        <Suspense fallback={<LazyLoadingComp />}>
          <AvailableProjectOption
            image={GreenEnvBuildingsImg}
            projectName="New Cairo"
          />
        </Suspense>
        <Suspense fallback={<LazyLoadingComp />}>
          <AvailableProjectOption image={CelltekZayedImg} projectName="Zayed" />
        </Suspense>
        <Suspense fallback={<LazyLoadingComp />}>
          <AvailableProjectOption
            image={GreenEnvBuildingsImg}
            projectName="New Cairo"
          />
        </Suspense>
        <Suspense fallback={<LazyLoadingComp />}>
          <AvailableProjectOption image={CelltekZayedImg} projectName="Zayed" />
        </Suspense>
      </StackType>
    </PageContainerComponent>
  );
}

function LazyLoadingComp() {
  return (
    <Center w="full" h="50vh">
      <Skeleton
        startColor="red.100"
        endColor="gray.200"
        w="full"
        h="34vh"
        borderRadius={["3rem"]}
      />
    </Center>
  );
}
