import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../../app/store";

type ComponentAnimation = {
  menuAnimation: boolean;
};

const initialState: ComponentAnimation = {
  menuAnimation: false,
};

export const navigationMenuSlice = createSlice({
  name: "NavigationMenuSlice",
  initialState,
  reducers: {
    startMenuAnimation: (state: any) => {
      state.menuAnimation = true;
    },
    stopMenuAnimation: (state: any) => {
      state.menuAnimation = false;
    },
  },
});

export const {
  startMenuAnimation,
  stopMenuAnimation,
} = navigationMenuSlice.actions;

export const selectMenuAnimation = (state: RootState) => {
  return state.navigationMenuAnimation.menuAnimation;
};

export default navigationMenuSlice.reducer;
