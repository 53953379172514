import { useChoseStyleOnLandscape } from "../../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../../utils/Values";

type Props = {
  children: JSX.Element | JSX.Element[];
};

function Container(props: Props) {
  const containerStylesInLandscape = {
    h: {
      "1xsm": "82%",
    },
    overflow: {
      "1xsm": "auto",
    },

    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  const StackType = useGetStackTypeElOnPageOrientation();

  return (
    <StackType
      w="100%"
      h={["88%", "92%"]}
      spacing={{ base: "1rem", tablet16: "2rem" }}
      {...styles}
    >
      {props.children}
    </StackType>
  );
}

export default Container;
