import {VStack, Image, Box, Text, Center, Skeleton} from "@chakra-ui/react";
import {useState} from "react";

import {
    useChoseOnFlag,
    useChoseStyleOnLandscape,
} from "../../../utils/Selecting";
import {useGetQueryValue} from "../../../utils/Values";

type Props = {
    setDisplayState: () => any;
    disable1: () => any;
    disable2: () => any;
    disable3: () => any;

    images: {
        buildingImage: string;
        villaImage: string;
    };

    buildingNames: {
        apartments: string;
        villa: string;
    };
};

export default function BuildingOption(props: Props) {
    const projectZone = useGetQueryValue("z") || "1";

    const isZone1 = projectZone.toLowerCase() === "1";

    const projectType = useChoseOnFlag(
        isZone1,
        props.buildingNames.apartments,
        props.buildingNames.villa
    );

    const projectImage = useChoseOnFlag(
        isZone1,
        props.images.buildingImage,
        props.images.villaImage
    );

    function display() {
        props.setDisplayState();
        props.disable1();
        props.disable2();
        props.disable3();
    }

    // Loading logic
    const [isLoaded, setIsLoaded] = useState(false);

    // Landscape Styles.
    const txtStylesInLandscape = {
        fontSize: {
            "1xsm": "1rem",
        },
    };
    const txtStyles = useChoseStyleOnLandscape(txtStylesInLandscape);
    return (
        <Skeleton
            flex={1}
            h="100%"
            borderRadius={{base: "1rem", mobile8: "2rem", tablet16: "3rem"}}
            display={"flex"}
            isLoaded={isLoaded}
        >
            <VStack
                position="relative"
                w="100%"
                h="100%"
                flex={1}
                spacing={0}
                onClick={display}
            >
                <Image
                    onLoad={() => setIsLoaded(true)}
                    w="100%"
                    h="100%"
                    flex={1}
                    height={"100%"}
                    borderRadius={{base: "1rem", mobile8: "2rem", tablet16: "3rem"}}
                    src={projectImage}
                />
                <Box
                    w="100%"
                    h={{base: "25%", mobile8: "30%", tablet16: "25%"}}
                    position="absolute"
                    bottom="0"
                    left="0"
                    borderBottomRadius={{base: "1rem", mobile8: "2rem", tablet16: "3rem"}}
                    bg="#00000091"
                >
                    <Center w="100%" h="100%">
                        <Text
                            fontSize={{base: "1rem", mobile8: "1.5rem", tablet2: "2rem", tablet16: "5xl"}}
                            color="white"
                            {...txtStyles}
                        >
                            {projectType}
                        </Text>
                    </Center>
                </Box>
            </VStack>
        </Skeleton>
    );
}
