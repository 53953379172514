// 414 x 896
const mobileX = {
  "& > :not(style) ~ :not(style)": {
    marginInlineStart: "5px",
  },
};

const styles = {
  "@media (min-width:412px) and (max-height:915px)": mobileX,
};

export default styles;
