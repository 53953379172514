import ZoneBtn from "./ZoneBtn";

import { memo } from "react";

function Zones() {
  

  return (
    <>
      <ZoneBtn
        btnTxt="Offices"
        location={{
          top: "8%",
          left: "12%",
        }}
        isDisabled
      />
      <ZoneBtn
        btnTxt="Residential"
        location={{
          top: "20%",
          left: "60%",
        }}
        isDisabled={false}
      />
      <ZoneBtn
        btnTxt="Retail"
        location={{
          top: "70%",
          left: "15%",
        }}
        isDisabled
      />
    </>
  );
}

export default memo(Zones);
