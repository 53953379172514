import { HTMLChakraProps, chakra, Icon } from "@chakra-ui/react";
import { motion, HTMLMotionProps } from "framer-motion";
import { IconType } from "react-icons";
import Merge from "../IMerge";

type MotionDivProps = Merge<HTMLChakraProps<"div">, HTMLMotionProps<"div">>;
const MotionDiv: React.FC<MotionDivProps> = motion(chakra.div);

type MotionIconProps = MotionDivProps & {
  asIcon: IconType;
  iconSize: number | string | number[] | string[] | undefined | any;
  iconColor: string;
};

export default function MotionIcon(props: MotionIconProps) {
  const { asIcon, iconSize, iconColor, ...divProps } = props;

  return (
    <MotionDiv {...divProps}>
      <Icon
        as={asIcon}
        fontSize={iconSize}
        color={iconColor}
        h="full"
        w="full"
      />
    </MotionDiv>
  );
}
