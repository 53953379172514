import { SectionOneProps } from "./SectionOne";

import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

const SectionTwo = (props: SectionOneProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.4%"
        buttonHeight="3.4%"
        rotateDegree={190}
        topOffset="64.7%"
        leftOffset="51.4%"
        hasUnits={true}
        plotNumber="216"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.4%"
        buttonHeight="3.4%"
        rotateDegree={5}
        topOffset="64.9%"
        leftOffset="53.6%"
        hasUnits={true}
        plotNumber="217"
        page="/facade/townhouse"
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.8%"
        rotateDegree={340}
        topOffset="53.7%"
        leftOffset="69.2%"
        hasUnits={true}
        plotNumber="230"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.8%"
        rotateDegree={325}
        topOffset="52.5%"
        leftOffset="70.8%"
        hasUnits={true}
        plotNumber="231"
        page="/facade/townhouse"
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.8%"
        rotateDegree={303}
        topOffset="49.8%"
        leftOffset="71.9%"
        hasUnits={true}
        plotNumber="232"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.8%"
        rotateDegree={292}
        topOffset="46.2%"
        leftOffset="72.7%"
        hasUnits={true}
        plotNumber="233"
        page="/facade/townhouse"
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.8%"
        rotateDegree={236}
        topOffset="41.1%"
        leftOffset="72.4%"
        hasUnits={true}
        plotNumber="234"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.1%"
        rotateDegree={219}
        topOffset="37.9%"
        leftOffset="71.4%"
        hasUnits={true}
        plotNumber="235"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.1%"
        rotateDegree={199}
        topOffset="36.1%"
        leftOffset="69.9%"
        hasUnits={true}
        plotNumber="236"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.1%"
        rotateDegree={14}
        topOffset="35.4%"
        leftOffset="68.1%"
        hasUnits={true}
        plotNumber="237"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.9%"
        rotateDegree={338}
        topOffset="48.9%"
        leftOffset="52.1%"
        hasUnits={true}
        plotNumber="250"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.9%"
        rotateDegree={338}
        topOffset="50.2%"
        leftOffset="50.3%"
        hasUnits={true}
        plotNumber="251"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.9%"
        rotateDegree={354}
        topOffset="50.9%"
        leftOffset="48.5%"
        hasUnits={true}
        plotNumber="252"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="2.9%"
        rotateDegree={5}
        topOffset="50.9%"
        leftOffset="46.5%"
        hasUnits={true}
        plotNumber="253"
        page="/facade/townhouse"
      />
    </>
  );
};

export default SectionTwo;
