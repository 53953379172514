import { VStack } from "@chakra-ui/react";

// Custom Components
import TwoPropTypeRow from "./TwoPropTypeRow";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Images Sources
import PropertyTypeVillaImg from "../../img/buildings/property-types/villa.jpg";
import PropertyTypeDuplexImg from "../../img/buildings/property-types/duplex.jpg";
import PropertyTypeTownhouseImg from "../../img/buildings/property-types/townhouse.jpg";
import PropertyTypeApartmentImg from "../../img/buildings/property-types/apartment.jpg";
import PropertyTypePenthouseImg from "../../img/buildings/property-types/penthouse.jpg";
import PropertyTypeCommercialImg from "../../img/buildings/property-types/commercial.jpg";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

export default function PropertyTypesPage() {
  // Auth before accessing this page
  useVerifyAuth();

  return (
    <PageContainerComponent
      spacing={{
        base: "0rem",
        mobile1: "0.3rem",
        mobile10: "0.5rem",
        tablet4: "2.5rem",
        tablet10: "1.0rem",
        tablet13: "1.0rem",
        tablet16: "3rem",
        tablet19: "1rem",
        desktop5: "1rem",
      }}
    >
      <LocationIndicator text="Property Types" />
      <VStack
        w="100%"
        h="89%"
        spacing={{
          base: "1rem",
          miniMobile12: "2rem",
          mobile16: "1.5rem",
          tablet4: "2.5rem",
          tablet16: "3rem",
        }}
      >
        <TwoPropTypeRow
          type1={{
            image: PropertyTypeApartmentImg,
            name: "Apartment",
          }}
          type2={{
            image: PropertyTypePenthouseImg,
            name: "Penthouse",
          }}
        />
        <TwoPropTypeRow
          type1={{
            image: PropertyTypeDuplexImg,
            name: "Duplex",
          }}
          type2={{
            image: PropertyTypeTownhouseImg,
            name: "Townhouse",
          }}
        />
        <TwoPropTypeRow
          type1={{
            image: PropertyTypeVillaImg,
            name: "Villa",
          }}
          type2={{
            image: PropertyTypeCommercialImg,
            name: "Commercial",
          }}
        />
      </VStack>
    </PageContainerComponent>
  );
}
