import { useGetQueryValue } from "utils/Values";

import { PropertyTypes } from "interfaces/property";

export default function useQueryUnitType() {
  const unitType = useGetQueryValue("type");

  switch (unitType) {
    case PropertyTypes.APARTMENT:
      return PropertyTypes.APARTMENT;
    case PropertyTypes.DUPLEX:
      return PropertyTypes.DUPLEX;
    case PropertyTypes.PENTHOUSE:
      return PropertyTypes.PENTHOUSE;
    case PropertyTypes.TOWNHOUSE:
      return PropertyTypes.TOWNHOUSE;
    case PropertyTypes.VILLA:
      return PropertyTypes.VILLA;
    default:
      return PropertyTypes.APARTMENT;
  }
}
