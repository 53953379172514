import {memo} from "react";

import {VStack, Image} from "@chakra-ui/react";
import {LazyLoadImage} from "react-lazy-load-image-component";

import {useLandscapeValueToBool} from "../../../utils/Values";

import ZayedZoneInLandscape from "../../../img/maps/Zayed-Master-Plan.jpg";
import ZayedZone from "../../../img/maps/Zayed-Master-Plan.jpg";

import Zones from "./Zones";

import {useChoseStyleOnLandscape} from "../../../utils/Selecting";

function ZayedHeroImage() {
    const isInLandscape = useLandscapeValueToBool();
    const SelectedMapImg = isInLandscape ? ZayedZone : ZayedZoneInLandscape;

    // Landscape Styling.
    const containerStylesInLandscape = {
        borderRadius: {
            "1xsm": "1rem",
        },

        w: {
            "1xsm": "70%",
        },
    };
    const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

    return (
        <VStack
            w="100%"
            h={{base: "80%"}}
            spacing="2rem"
            position="relative"
        >
            <Image
                as={LazyLoadImage}
                w="full"
                h="full"
                borderRadius={{base: "1.5rem", mobile8: "2.5rem", mobile13: "3rem", tablet13: "4rem", tablet16: "3rem"}}
                src={SelectedMapImg}
                {...styles}
            />
            <Zones/>
        </VStack>
    );
}

export default memo(ZayedHeroImage);
