import { useEffect, useState } from "react";

import useHideAfterTimeIfOpen, {
  ReactSetState,
} from "./useHideAfterTimeIfOpen";

import axios, { AxiosResponse } from "axios";
import Property, { Status } from "../../../interfaces/property";

// import baseURL from "../../../constants/baseURL";

type OpenConfigs = {
  isOpen: boolean;
  closeElement: ReactSetState<boolean>;
  time?: number;
};

export default function useMakeUnitUnavailable(
  unitID: string,
  openConfig: OpenConfigs
) {
  const { isOpen, closeElement, time } = openConfig;

  useHideAfterTimeIfOpen(isOpen, closeElement, time);

  // const property = getUnit(unitID);
  // console.debug(property);

  // useEffect(() => {
  //   if (isOpen) {
  //     updateUnitToUnavailable(unitID);
  //     // console.debug("Calling After Property Updated To Not Available");
  //   }

  //   return () => {
  //     updateUnitToItsPrevStatus(unitID, property?.status || Status.AVAILABLE);
  //     // console.debug("Calling After Property Updated To Its Prev Status");
  //   };
  // }, [isOpen, property?.status, unitID]);
}

function getUrl(): string {
  return "https://celltek-real-estate.herokuapp.com";
}

// function getUnit(id: string): Property | null {
//   let property: Property | null = null;

//   axios
//     .get<Property>(`/properties/${id}`, {
//       withCredentials: true,
//       baseURL,
//     })
//     .then((response) => {
//       property = response.data;
//       // console.log(property);
//     })
//     .catch(() => {
//       console.error("Error fetching property");
//       property = null;
//     });

//   return property;
// }

export function useUpdateUnitToUnavailable(id: string | null) {
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    async function makeUnitUnavailable() {
      try {
        const result = await axios.put<
          Property,
          AxiosResponse<Property>,
          Partial<Property>
        >(
          `/properties/${id}`,
          {
            Status: Status.NOT_AVAILABLE,
          },
          {
            withCredentials: true,
            baseURL: getUrl(),
          }
        );

        console.debug(`The Property Status Got Updated ${result}`);

        setIsUpdated(true);
      } catch (e) {
        console.warn(`Couldn't update unit`);
        setIsUpdated(false);
      }
    }

    if (id) {
      makeUnitUnavailable();
    }
  });

  return isUpdated;
}

export function updateUnitToUnavailable(id: string | null) {
  if (id === null) {
    return;
  }

  axios
    .put<Property, AxiosResponse<Property>, Partial<Property>>(
      `/properties/${id}`,
      {
        Status: Status.NOT_AVAILABLE,
      },
      {
        withCredentials: true,
        baseURL: getUrl(),
      }
    )
    .then((response) => {
      // console.debug("Calling After Property Updated To Not Available 2");
    })
    .catch(() => {
      console.error("Error updating property");
    });
}

export function updateUnitToItsPrevStatus(id: string | null, status: Status) {
  if (id === null) {
    return;
  }

  console.debug(
    "%c Calling updateUnitToItsPrevStatus",
    "background-color: blue; color: white; font-weight: 800; letter-spacing: 0.25rem; "
  );

  axios
    .put<Property, AxiosResponse<Property>, Partial<Property>>(
      `/properties/${id}`,
      {
        Status: status,
      },
      {
        withCredentials: true,
        baseURL: getUrl(),
      }
    )
    .then(() => {
      // console.debug(`Unit status updated to ${status}`);
    })
    .catch((err) => {
      console.log(err);
    });
}
