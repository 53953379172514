import { PropertyTypes } from "interfaces/property";
import { useGetQueryValue } from "utils/Values";

import useQueryUnitType from "./useQueryUnitType";

export enum ZoneNames {
  ZONE_ONE = "Z1",
  ZONE_TWO = "Z2",
  RESIDENTIAL = "R",
}

export default function useQueryZoneName() {
  const unitType = useQueryUnitType();
  const zoneName = useGetQueryValue("z");

  console.warn("Don't forget to add the zayed project here in the condition");

  if (!zoneName) {
    switch (unitType) {
      case PropertyTypes.VILLA:
        return ZoneNames.ZONE_TWO;
      default:
        return ZoneNames.ZONE_ONE;
    }
  }

  return zoneName === "1" ? ZoneNames.ZONE_ONE : ZoneNames.ZONE_TWO;
}
