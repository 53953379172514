import { Route, useRouteMatch } from "react-router-dom";

// Pages.
import TownhouseFacadePage from "pages/TownhouseFacadePage";
import DuplexFacadePage from "pages/Facade-Pages/DuplexFacadePage";
import PenthouseFacadePage from "pages/Facade-Pages/PenthouseFacadePage";
import ApartmentFacadePage from "pages/Facade-Pages/ApartmentFacadePage";

const Facade = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route path={`${path}/penthouse`} exact>
        <PenthouseFacadePage />
      </Route>

      <Route path={`${path}/townhouse`} exact>
        <TownhouseFacadePage />
      </Route>

      <Route path={`${path}/apartment`} exact>
        <ApartmentFacadePage />
      </Route>

      <Route path={`${path}/duplex`} exact>
        <DuplexFacadePage />
      </Route>

      <Route path={`${path}`} exact>
        <PenthouseFacadePage />
      </Route>
    </>
  );
};

export default Facade;
