import { NavButton } from "../../NavButtons/NavButton";

import Property from "interfaces/property";

export interface SectionProps {
  display?: string;
  type: string;
  properties?: Array<Property>;
  propertyUrl: string;
  hasUnits: boolean;
}

const SectionOne = (props: SectionProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="77.0%"
        leftOffset="54.8%"
        plotNumber="100"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="76.1%"
        leftOffset="55.8%"
        plotNumber="101"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="75.1%"
        leftOffset="56.8%"
        plotNumber="102"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="74.3%"
        leftOffset="57.8%"
        plotNumber="103"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="73.3%"
        leftOffset="58.8%"
        plotNumber="104"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="72.3%"
        leftOffset="59.8%"
        plotNumber="105"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="70.8%"
        leftOffset="61.7%"
        plotNumber="106"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="70.2%"
        leftOffset="62.6%"
        plotNumber="107"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="69.2%"
        leftOffset="63.6%"
        plotNumber="108"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="68.2%"
        leftOffset="64.6%"
        plotNumber="109"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="67.4%"
        leftOffset="65.6%"
        plotNumber="110"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="66.4%"
        leftOffset="66.6%"
        plotNumber="111"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="64.5%"
        leftOffset="69.3%"
        plotNumber="112"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="63.8%"
        leftOffset="70.3%"
        plotNumber="113"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="62.9%"
        leftOffset="71.3%"
        plotNumber="114"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="62.0%"
        leftOffset="72.4%"
        plotNumber="115"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="61.2%"
        leftOffset="73.4%"
        plotNumber="116"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset="60.5%"
        leftOffset="74.5%"
        plotNumber="117"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
    </>
  );
};

export default SectionOne;
