import { memo, useEffect } from "react";

import { motion, useAnimation } from "framer-motion";

import { useDispatch, useSelector } from "react-redux";

import {
  selectMenuAnimation,
  startMenuAnimation,
  stopMenuAnimation,
} from "../NavigationMenu/NavigationMenuSlice";

// Styles
import styles from "./MenuToggleButton.module.css";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Center } from "@chakra-ui/react";

function MenuToggleButton() {
  const isMenuShown = useSelector(selectMenuAnimation);
  const dispatch = useDispatch();

  function toggleMenu() {
    if (isMenuShown) return dispatch(stopMenuAnimation());
    return dispatch(startMenuAnimation());
  }

  const controls = useAnimation();

  useEffect(() => {
    if (isMenuShown) {
      controls.start({
        rotate: [0, 180],
      });
    } else {
      controls.start({
        rotate: [180, 0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuShown]);

  return (
    <Center w="full" className={styles.menu_container_parent}>
      <motion.div
        animate={controls}
        onClick={toggleMenu}
        className={styles.menu_container}
      >
        <FontAwesomeIcon
          onClick={toggleMenu}
          icon={faChevronUp}
          size="2x"
          color="white"
        />
      </motion.div>
    </Center>
  );
}

export default memo(MenuToggleButton);
