import { HStack } from "@chakra-ui/react";
import { useChoseStyleOnLandscape } from "../../utils/Selecting";

import PropertyTypeComp from "./property-type-comp/PropertyTypeComp";

type Props = {
  type1: {
    name: string;
    image: string;
  };
  type2: {
    name: string;
    image: string;
  };
};

export default function TwoPropTypeRow(props: Props) {
  const containerStylesInLandscape = {
    h: {
      "1xsm": "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <HStack
      w="100%"
      h={{ base: "33.8%", mobile16: "50%", tablet12: "30%" }}
      spacing={{
        base: "1rem",
        miniMobile12: "1.5rem",
        mobile16: "2.5rem",
        tablet12: "2rem",
      }}
      {...styles}
    >
      <PropertyTypeComp
        image={props.type1.image}
        propertyName={props.type1.name}
      />
      <PropertyTypeComp
        image={props.type2.image}
        propertyName={props.type2.name}
      />
    </HStack>
  );
}
