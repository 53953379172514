import { HStack } from "@chakra-ui/react";

// Stylings
import externalStyles from "./ImagesRow.module.css";

// Custom Components
import ImageComponent from "./ImageComponent";

type ImagesRowProps = {
  first: {
    BedroomNumber: number;
    ImageSrc: string;
    display: (x: any) => any;
  };
  second: {
    BedroomNumber: number;
    ImageSrc: string;
    display: (x: any) => any;
  };
};

export default function ImagesRow(props: ImagesRowProps) {
  return (
    <HStack
      className={externalStyles.images_row}
      w="100%"
      h="49%"
      spacing={[
        "1rem",
        "1rem",
        "1rem",
        "1rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "1.5rem",
        "3rem",
      ]}
    >
      <ImageComponent
        bedroomNumber={props.first.BedroomNumber}
        imageSrc={props.first.ImageSrc}
        showImage={() => ShowImage(props.first.display)}
      />
      <ImageComponent
        bedroomNumber={props.second.BedroomNumber}
        imageSrc={props.second.ImageSrc}
        showImage={() => ShowImage(props.second.display)}
      />
    </HStack>
  );
}

function ShowImage(hide: (x: any) => any) {
  hide(undefined);
}
