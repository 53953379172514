import { useMemo, useState } from "react";

import BtnContainer from "./BtnContainer";
import BtnImg from "./BtnImg";
import Btn from "./Btn";

type Props = {
  image: string;
  imageWhite: string;
  isSelected?: boolean;
  name: string;
  urlPath: string;
};

export default function OptionButton(props: Props) {
  const isInfoBtn = useMemo(() => {
    return props.name.toLocaleLowerCase() === "info";
  }, [props.name]);

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  return (
    <BtnContainer
      isInfoBtn={isInfoBtn}
      isSelected={isButtonClicked || props.isSelected}
      activeBtn={setIsButtonClicked}
      urlPath={props.urlPath}
    >
      <BtnImg
        image={props.image}
        imageWhite={props.imageWhite}
        isSelected={isButtonClicked || props.isSelected}
        isInfoBtn={isInfoBtn}
      />
      <Btn
        name={props.name}
        isInfoBtn={isInfoBtn}
        isSelected={isButtonClicked || props.isSelected}
      />
    </BtnContainer>
  );
}
