import { useState } from "react";

import axios from "axios";

import {
  Button,
  Flex,
  HStack,
  Input,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { useForm, SubmitHandler } from "react-hook-form";

import {
  DisplayBookingInfo,
  DisplayBookingInfoProps,
} from "./DisplayBookingInfo/DisplayBookingInfo";

import FormInput from "../FormInput";
import { updateUnitToItsPrevStatus } from "../hooks/useMakeUnitUnavailable";
import { Status } from "../../../interfaces/property";

export interface BookCompProps extends DisplayBookingInfoProps {
  onClose: (x: boolean) => any;
  propertyId?: string;
}

export const BookComp = (props: BookCompProps) => {
  const toaster = useToast({
    description: "Booked Successfully",
    position: "top",
    duration: 3000,
    isClosable: true,
    title: "Success",
    status: "success",
  });

  const { register, handleSubmit } = useForm<Client>();

  const [isLoading, setIsLoading] = useState(false);

  const onCreateClient: SubmitHandler<Client> = async (data: Client) => {
    setIsLoading(true);

    const isClientCreated = await createClient(data);

    if (isClientCreated) {
      toaster();
      setIsLoading(false);
    } else {
      toaster({
        description: "Error creating client",
        title: "Error",
        status: "error",
      });
      setIsLoading(false);
    }
  };

  const onClose = () => {
    if (props.propertyId) {
      updateUnitToItsPrevStatus(props.propertyId, Status.AVAILABLE);
    }

    props.onClose(false);
  };

  return (
    <Flex bgColor="white" w="65%" h="35%" p="2rem" borderRadius="2rem">
      <form
        onSubmit={handleSubmit(onCreateClient)}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <VStack w="full" h="full">
          <DisplayBookingInfo {...props} />
          <HStack w="full" flex="1">
            <FormInput
              formRegisterFunc={register}
              placeholderText="First Name"
              registerText="firstName"
            />
            <FormInput
              formRegisterFunc={register}
              placeholderText="Last Name"
              registerText="lastName"
            />
          </HStack>
          <HStack w="full" flex="1">
            <FormInput
              formRegisterFunc={register}
              placeholderText="Phone Number"
              registerText="phone"
              inputType="tel"
            />
          </HStack>
          <Input
            flex="1"
            placeholder="Email Address"
            borderColor="red.500"
            borderWidth="3px"
            {...register("email")}
          />
          <Input
            flex="1"
            placeholder="Broker Name"
            borderColor="red.500"
            borderWidth="3px"
            {...register("brokerName")}
          />
          <HStack justifyContent="center" w="full" flex="1">
            <Button
              bgColor="red.500"
              h="full"
              type="submit"
              w="full"
              color="white"
              isLoading={isLoading}
            >
              Submit
            </Button>
            <Button
              bgColor="blue.900"
              h="full"
              onClick={onClose}
              w="full"
              color="white"
            >
              Cancel
            </Button>
          </HStack>
        </VStack>
      </form>
    </Flex>
  );
};

// interface Property {
//   // interface for the property
//   type: string;
//   plot: string;
//   owner: string;
//   sold: boolean;
//   cluster: string;
//   hidden: boolean;
//   bedrooms: number;
//   isActive: boolean;
//   isBlocked: boolean;
//   isAvailable: boolean;
// }

// // create function called bookProperty that post the data to my api using axios.
// async function bookProperty(data: Property): Promise<boolean> {
//   const isProduction = process.env.NODE_ENV === "production";

//   const url = "https://celltek-real-estate.herokuapp.com/";

//   try {
//     const response = await axios.post(url, data);
//     console.log(response);
//     return true;
//   } catch (error) {
//     console.log(error);
//     return false;
//   }
// }

// Client Interface

export interface Client {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  brokerName: string;
  blocked: boolean;
}

// function to create a client with axios
async function createClient(clientData: Client): Promise<boolean | string> {
  
  const url = "https://celltek-real-estate.herokuapp.com/clients";

  try {
    const response: any = await axios.post(url, {
      ...clientData,
    });
    return response.data?._id ?? false;
  } catch (error) {
    console.log(error);
    return false;
  }
}
