import { capitalizeFirstLetter, useGetQueryValue, useQuery } from "./Values";

export function navToPage(url: string | undefined) {
  if (typeof url === "string") {
    window.open(`/${url}`, "_self");
  }
}

export function useGetQueryData() {
  // Fetching data from url's query
  const query = useQuery();
  const zoneNumber = query.get("z") || "1";
  const projectName = query.get("p") || null;
  const bedRoomsNumber: number = +(query.get("bd") || 3);
  const apartmentType: string =
    capitalizeFirstLetter(query.get("type")) || "Apartment";
  const unit: string = capitalizeFirstLetter(query.get("unit")) || "1";
  const floorNumber: number = +(capitalizeFirstLetter(query.get("fn")) || "1");
  const isVillaType: boolean = apartmentType.toLowerCase().includes("villa");
  const isVillaAType =
    isVillaType && apartmentType.toLowerCase().includes("villa a");

  const id = useGetQueryValue("id");
  const urlQuery = `id=${id}&type=${apartmentType}&unit=${unit}&fn=${floorNumber}&z=${zoneNumber}&p=${projectName}`;
  const pageHeader = `${bedRoomsNumber} Bedroom ${apartmentType} Floor Plan`;

  return {
    isVillaAType,
    isVillaType,
    bedRoomsNumber,
    pageHeader,
    urlQuery,
  };
}
