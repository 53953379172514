import { useState } from "react";
import { VStack } from "@chakra-ui/react";

// Custom Components
import HeroImage from "./hero-image/HeroImage";
import NavigationMenu from "../common/NavigationMenu/NavigationMenu";
import MenuToggleButton from "../common/MenuToggleButton/MenuToggleButton";
import OptionsButtons from "./options-buttons/OptionsButtons/OptionsButtons";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

export default function ProjectFacilitiesPage() {
  // Auth before accessing this page
  useVerifyAuth();

  //? Activating pins
  const [activeSwimmingPoolPin, setActiveSwimmingPoolPin] = useState(false);
  const [activeFootballPin, setActiveFootballPin] = useState(false);
  const [activeMedicalPin, setActiveMedicalPin] = useState(false);
  const [activeKidsPin, setActiveKidsAreaPin] = useState(false);
  const [activeMosquePin, setActiveMosquePin] = useState(false);
  const [activeTennisPin, setActiveTennisPin] = useState(false);
  const [activeRetailPin, setActiveRetailPin] = useState(false);
  const [activeClubPin, setActiveClubPin] = useState(false);
  const [activeGYMPin, setActiveGYMPin] = useState(false);

  // Render Menu Only when the component gets Rendered.
  const [isElementReady, setIsElementReady] = useState(false);

  const projectName = useQueryProjectName();

  return (
    <PageContainerComponent
      spacing={{
        base: "0rem",
        sm: "0.5rem",
        "2xl": "3rem",
      }}
      overflow="hidden"
    >
      <LocationIndicator text={`Celltek ${projectName} Facilities`} />
      <VStack
        w="full"
        h="90%"
        spacing={{
          base: "1rem",
          mobile8: "1.5rem",
          ipadPro: "3rem",
        }}
        onClick={() => setIsElementReady(true)}
      >
        <HeroImage
          activePins={{
            activeSwimmingPoolPin,
            activeFootballPin,
            activeMosquePin,
            activeMedicalPin,
            activeKidsPin,
            activeRetailPin,
            activeTennisPin,
            activeClubPin,
            activeGYMPin,
          }}
        />
        <OptionsButtons
          setActiveSwimmingPoolPin={setActiveSwimmingPoolPin}
          setActiveFootballPin={setActiveFootballPin}
          setActiveKidsAreaPin={setActiveKidsAreaPin}
          setActiveMedicalPin={setActiveMedicalPin}
          setActiveMosquePin={setActiveMosquePin}
          setActiveRetailPin={setActiveRetailPin}
          setActiveTennisPin={setActiveTennisPin}
          setActiveClubPin={setActiveClubPin}
          setActiveGYMPin={setActiveGYMPin}
        />
        <MenuToggleButton />
        {isElementReady && <NavigationMenu />}
      </VStack>
    </PageContainerComponent>
  );
}
