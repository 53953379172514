import { VStack } from "@chakra-ui/react";

import { Link } from "react-router-dom";

type Props = {
  urlQuery: string;
  children: JSX.Element | JSX.Element[];
};

export default function Container(props: Props) {
  const query = props.urlQuery || "";

  return (
    <VStack
      as={Link}
      to={`project-presentation-page?${query}`}
      position="relative"
      w="30rem"
      h="20rem"
      spacing={["0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.5rem", "1.5rem"]}
    >
      {props.children}
    </VStack>
  );
}
