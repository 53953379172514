import { Link } from "react-router-dom";

import { Box, BoxProps } from "@chakra-ui/react";
// import { motion, HTMLMotionProps } from "framer-motion";

// type Merge<P, T> = Omit<P, keyof T> & T;
// type BoxMotionProps = Merge<HTMLChakraProps<"div">, HTMLMotionProps<"div">> &
//   LinkProps;

type NavBoxProps = BoxProps & { to: string };

export default function NavBox(props: NavBoxProps) {
  const { to, ...otherProps } = props;
  return (
    <Box as={Link} to={to} {...otherProps}>
      {props.children}
    </Box>
  );
}
