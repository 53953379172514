import { Center, Spinner } from "@chakra-ui/react";

// Custom Components
import OptionsButtons from "../project-presentation-page/OptionsButtons";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

import { useChoseOnProjectName } from "../../utils/Selecting";
import { useChoseStyleOnLandscape } from "../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";

// Utils

// Lazy
import { lazy, memo, Suspense } from "react";

// Lazy Component
const ZayedGallery = lazy(() => import("./ProjectGallery/ZayedGallery"));
const ProjectGallery = lazy(() => import("./ProjectGallery/ProjectGallery"));

function ProjectGalleryPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const projectName = useQueryProjectName();

  // Landscape Switched Components.
  const StackType = useGetStackTypeElOnPageOrientation();

  // Landscape Styling.
  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  // Landscape Styling.
  const subContainerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
    h: {
      "1xsm": "80%",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  return (
    <PageContainerComponent overflow="hidden" {...styles}>
      <LocationIndicator text={`Celltek ${projectName} Gallery`} />
      <StackType
        w="full"
        h="90%"
        spacing={{ base: "0.5rem", miniMobile10: "0.7rem", tablet16: "2rem" }}
        {...subContainerStyles}
      >
        <Suspense fallback={<LazyLoadingComp />}>
          {useChoseOnProjectName("zayed", <ZayedGallery />, <ProjectGallery />)}
        </Suspense>
        <OptionsButtons isGalleryPage />
      </StackType>
    </PageContainerComponent>
  );
}

export default memo(ProjectGalleryPage);

function LazyLoadingComp() {
  return (
    <Center w="full" h="73vh">
      <Spinner
        sx={{
          "--spinner-size": "15rem",
        }}
        thickness="7px"
        color="red.500"
        emptyColor="gray.200"
        speed="0.85s"
        size="xl"
      />
    </Center>
  );
}
