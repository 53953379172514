import { Image } from "@chakra-ui/react";

// Stylings
import externalStyles from "./CompanyLogo.module.css";

// Utils
import {
  useChoseOnLightMode,
  useChoseStyleOnLandscape,
} from "../../../utils/Selecting";
import NavBox from "../../../utils/NavigationComps/NavBox";

// Images
import CompanyLogoImg from "../../../img/common/company_logos/logo.svg";
import CompanyLogoDarkImg from "../../../img/common/company_logos/logo_dark.svg";

export default function CompanyLogo() {
  const logoImg = useChoseOnLightMode(CompanyLogoImg, CompanyLogoDarkImg);

  // Landscape Styling.
  const containerStylesInLandscape = {
    w: { mobile5: "17%", tablet1: "15%" },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <NavBox pos="relative" to="/" w="100%" h="100%">
      <Image
        className={externalStyles.logo}
        loading="lazy"
        src={logoImg}
        h="full"
        w={{
          base: "50%",
          "1xsm": "40%",
          mobile1: "47%",
          mobile7: "40%",
          mobile8: "45%",
          mobile13: "40%",
          mobile10: "19%",
          mobile14: "16%",
          mobile15: "16%",
          mobile16: "40%",
          tablet2: "40%",
          tablet4: "15%",
          tablet7: "40%",
          tablet10: "18%",
          tablet13: "37%",
          tablet16: "40%",
          tablet19: "17%",
          desktop5: "21%",
        }}
        {...styles}
      />
    </NavBox>
  );
}
