import { lazy, Suspense } from "react";

import { Center, Spinner } from "@chakra-ui/react";

// Custom Components
import Header from "../common/header/Header";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import FloorPlateNavOptions from "../common/floor-plate-nav-options/FloorPlateNavOptions";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Utils
import { capitalizeFirstLetter } from "../../utils/Values";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

import { useQuery } from "../../utils/Values";
import { useGetQueryValue } from "../../utils/Values";
import { useChoseStyleOnLandscape } from "../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";

// Interfaces
// import Property from "../../interfaces/property";
import useFetchProperty from "hooks/fetching_values/useFetchProperty";
import useQueryProjectName, { ProjectNames } from "hooks/query_values/useQueryProjectName";

// Custom Lazy Loading Components
const PageGallery = lazy(() => import("./page-gallery/PageGallery"));

export default function FloorPlateUnitGalleryPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const bedRoomsNumber: string = useGetQueryValue("bd") || "#";
  const apartmentType: string =
    capitalizeFirstLetter(useGetQueryValue("type")) || "#";

  // const projectName = useGetQueryValue("p");
  // const type = useGetQueryValue("type") || "";
  // const unitNumber = useGetQueryValue("unitNumber") || "1";
  // const unitName = `${bedRoomsNumber}-BR-${type[0].toUpperCase()}-${unitNumber}`;
  // const unitTextInfo = `${bedRoomsNumber} Bedrooms ${type} Unit`;

  const indicatorText = `${apartmentType} ${bedRoomsNumber} bedroom`;

  // Landscape Styles.
  const containerStylesInLandscape = {
    overflow: "auto",
    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);
  // Landscape Switched Components.
  const StackType = useGetStackTypeElOnPageOrientation();

  const url = useGetUrlForProject();
  const zayedUrl = `property-display-page?p=zayed&type=Residential`;

  const projectName = useQueryProjectName();

  const propertyInfo = useFetchProperty();
  const pluralString = propertyInfo && +propertyInfo?.Bedroom > 1 ? "s" : "";
  const headerTxt = `${propertyInfo?.Zone} ${propertyInfo?.["Unit Type"]} ${propertyInfo?.Bedroom} Bedroom${pluralString} Gallery`;

  return (
    <PageContainerComponent
      spacing={{ base: "0rem" }}
      overflow="hidden"
      h={["100%", "100%", "100%", "100%"]}
      {...styles}
    >
      <Header
        formData={{
          Price: propertyInfo?.Price,
          projectName: projectName,
          plotArea: propertyInfo?.Area,
          unitName: propertyInfo?.["Unit Type"],
          unitTextInfo: propertyInfo?.Description,
          id: propertyInfo?._id,
        }}
      />
      <LocationIndicator
        pageUrl={projectName === ProjectNames.NEW_CAIRO ? url : zayedUrl}
        text={propertyInfo ? headerTxt : indicatorText}
      />
      <StackType
        data-testid="container"
        w="full"
        h="89%"
        spacing={{ base: "2rem", tablet16: "3.2rem" }}
        {...styles}
      >
        <Suspense fallback={<LazyLoadingComp />}>
          <PageGallery />
        </Suspense>
        <FloorPlateNavOptions id={propertyInfo?._id} isGalleryPage={true} />
      </StackType>
    </PageContainerComponent>
  );
}

function useGetUrlForProject() {
  // Url Navigation.
  const query = useQuery();
  const zoneNumber = query.get("z") || "1";
  const projectName = query.get("p") || null;
  const url = `project-zone-page?z=${zoneNumber}&p=${projectName}`;
  const zayedUrl = `property-display-page?p=zayed&type=Residential`;
  return projectName ? url : zayedUrl;
}

function LazyLoadingComp() {
  return (
    <Center w="full" h="73vh">
      <Spinner
        sx={{
          "--spinner-size": "15rem",
        }}
        thickness="7px"
        color="red.500"
        emptyColor="gray.200"
        speed="0.85s"
        size="xl"
      />
    </Center>
  );
}
