import { Flex } from "@chakra-ui/react";

import UnitInfo from "./UnitInfo";

import useQueryFloorNumber from "hooks/query_values/useQueryFloorNumber";
import useQueryBedroomNumbers from "hooks/query_values/useQueryBedroomNumbers";

export const FloorInfo = () => {
  const floorNumber = useQueryFloorNumber();
  const bedRoomsNumber = useQueryBedroomNumbers();

  const destinationUrl = `/floor-plate-unit-page?title=${floorNumber} Floor Plate&fn=${floorNumber}&p=zayed&bd=${bedRoomsNumber}`;

  const plates = floorPlates(bedRoomsNumber, destinationUrl);

  return (
    <Flex pos="absolute" top="0" left="0">
      {plates}
    </Flex>
  );
};

function floorPlates(bedRoomsNumber: number, url: string) {
  switch (bedRoomsNumber) {
    case 1:
      return <OneBedroomUnit url={url} />;

    case 2:
      return <TwoBedroomUnit url={url} />;

    case 3:
      return <ThreeBedroomUnit url={url} />;

    case 4:
      return <FourBedroomUnit url={url} />;

    default:
      return <OneBedroomUnit url={url} />;
  }
}

function OneBedroomUnit(props: { url: string }) {
  return (
    <>
      <UnitInfo
        position={{
          top: "10.6rem",
          left: "13.6rem",
        }}
        dimensions={{
          width: "27vw",
          height: "43.6vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=1`,
          isDisplayed: true,
        }}
      />
      <UnitInfo
        position={{
          top: "10.6rem",
          left: "31.7rem",
        }}
        dimensions={{
          width: "27vw",
          height: "43.6vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=1`,
          isDisplayed: false,
        }}
      />
      <UnitInfo
        position={{
          top: "50.9rem",
          left: "13.6rem",
        }}
        dimensions={{
          width: "27vw",
          height: "43.6vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=2`,
          isDisplayed: false,
        }}
      />
      <UnitInfo
        position={{
          top: "50.9rem",
          left: "31.7rem",
        }}
        dimensions={{
          width: "27vw",
          height: "43.6vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=2`,
          isDisplayed: true,
        }}
      />
    </>
  );
}

function TwoBedroomUnit(props: { url: string }) {
  return (
    <>
      <UnitInfo
        position={{
          top: "14.7rem",
          left: "14.6rem",
        }}
        dimensions={{
          width: "47vw",
          height: "38vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=3`,
          isDisplayed: true,
        }}
      />

      <UnitInfo
        position={{
          top: "48rem",
          left: "14.6rem",
        }}
        dimensions={{
          width: "47vw",
          height: "38vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=3`,
          isDisplayed: false,
        }}
      />
    </>
  );
}

function ThreeBedroomUnit(props: { url: string }) {
  return (
    <>
      <UnitInfo
        position={{
          top: "9.9rem",
          left: "12.6rem",
        }}
        dimensions={{
          width: "55vw",
          height: "45vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=4`,
          isDisplayed: true,
        }}
      />
      <UnitInfo
        position={{
          top: "51rem",
          left: "12.6rem",
        }}
        dimensions={{
          width: "55vw",
          height: "45vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=4`,
          isDisplayed: false,
        }}
      />
    </>
  );
}

function FourBedroomUnit(props: { url: string }) {
  return (
    <>
      <UnitInfo
        position={{
          top: "9.9rem",
          left: "13.5rem",
        }}
        dimensions={{
          width: "54.5vw",
          height: "44.6vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=5`,
          isDisplayed: true,
        }}
      />

      <UnitInfo
        position={{
          top: "52.6rem",
          left: "13.5rem",
        }}
        dimensions={{
          width: "54.5vw",
          height: "44.6vw",
        }}
        linkInfo={{
          destinationUrl: `${props.url}&unit=5`,
          isDisplayed: false,
        }}
      />
    </>
  );
}
