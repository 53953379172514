import { memo } from "react";

import { IconType } from "react-icons/lib";

// Custom Components
import InfoTxt from "./InfoTxt";
import InfoIcon from "./InfoIcon/InfoIcon";
import InfoBoxContainer from "./InfoBoxContainer";

type Props = {
  icon?: IconType;
  boxTxt?: string;
  fontSize?: any;
};

function InfoBox(props: Props) {
  return (
    <InfoBoxContainer>
      <InfoIcon icon={props.icon} />
      <InfoTxt boxTxt={props.boxTxt} fontSize={props.fontSize} />
    </InfoBoxContainer>
  );
}

export default memo(InfoBox);
