import { VStack, Image } from "@chakra-ui/react";

// Custom Components
import TwoOptionsInRow from "./TwoOptionsInRow";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Images Sources
import CoupleMovingNewHouseImg from "../../img/buildings/couple-moving-new-house.jpg";


// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

import { useChoseStyleOnLandscape } from "../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";

export default function DeliveryDatePage() {
  // Auth before accessing this page
  useVerifyAuth();
  
  // Landscape Styling.
  const subContainerStylesInLandscape = {
    h: {
      "1xsm": "85%",
    },
    spacing: {
      mobile7: "1rem",
      mobile10: "1.5rem",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  // Landscape Styling.
  const imageStylesInLandscape = {
    h: {
      "1xsm": "100%",
    },
    w: {
      "1xsm": "65%",
    },
    borderRadius: {
      "1xsm": "1rem",
    },
    flex: {
      "1xsm": 1,
    },
  };
  const imageStyles = useChoseStyleOnLandscape(imageStylesInLandscape);

  // Landscape Styling.
  const datesContainerStylesInLandscape = {
    flex: {
      "1xsm": 1,
    },
    h: {
      "1xsm": "full",
    },
    spacing: {
      "1xsm": "0.6rem",
    },
  };
  const datesStyles = useChoseStyleOnLandscape(datesContainerStylesInLandscape);

  // Landscape Switched Components.
  const StackType = useGetStackTypeElOnPageOrientation();

  return (
    <PageContainerComponent
      overflow="hidden"
      spacing={{
        base: "0rem",
        mobile7: "0.5rem",
        mobile10: "0.5rem",
        tablet16: "3rem",
        tablet19: "1.0rem",
        desktop5: "1rem",
      }}
    >
      <LocationIndicator text="Celltek New Cairo" />
      {/* Hero Image */}
      <StackType
        w="full"
        h="full"
        spacing={{ mobile10: "2rem", desktop5: "2.5rem" }}
        {...subContainerStyles}
      >
        <Image
          w="100%"
          h="60%"
          borderRadius={{
            base: "1.5rem",
            mobile8: "2rem",
            tablet16: "3rem",
            desktop5: "4rem",
          }}
          src={CoupleMovingNewHouseImg}
          {...imageStyles}
        />
        <VStack
          w="100%"
          h="40%"
          spacing={{ base: "0rem", tablet16: "2rem" }}
          {...datesStyles}
        >
          <TwoOptionsInRow
            firstYearOption="Ready To Move"
            secondYearOption="2022/Q3"
          />
          <TwoOptionsInRow firstYearOption="2023" secondYearOption="2024" />
          <TwoOptionsInRow firstYearOption="2025" secondYearOption="2026" />
        </VStack>
      </StackType>
    </PageContainerComponent>
  );
}
