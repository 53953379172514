import { Box } from "@chakra-ui/react";

import { Link } from "react-router-dom";

// Custom Components
import MotionButton from "../../animated-components/MotionButton";

// Utils
import { useQuery } from "../../../utils/Values";

// Interfaces and Enums
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

type NavButtonProps = {
  isDisplayed: string | undefined;
  topOffset: string | number | string[] | number[];
  leftOffset: string | number | string[] | number[];
  buildingType: string;
  rotateDegree: number;
  buttonWidth: number | string | null;
  buttonHeight: number | string | null;
  /** Property Plot Number */
  plotNumber: string;
  /** Has units in the plot */
  hasUnits: boolean;
  /** The page in the URL to which you send the user when clicked on it. */
  page?: string;
};

export function NavButton(props: NavButtonProps) {
  const rotate = `rotate(${props.rotateDegree}deg)`;

  const query = useQuery();
  const projectName = useQueryProjectName();
  const projectZone = query.get("z") || "1";

  const destinationPage = props.page || "property-display-page";

  const destinationUrl = `${destinationPage}?p=${projectName}&type=${props.buildingType}&z=${projectZone}&plotNumber=${props.plotNumber}`;

  return (
    <MotionButton
      w={props.buttonWidth || "0.7%"}
      h={props.buttonHeight || "2.8%"}
      borderRadius="8%"
      bg={props.hasUnits ? "green.400" : "red"}
      position="absolute"
      top={props.topOffset}
      left={props.leftOffset}
      display={props.isDisplayed}
      opacity="75%"
      initial={{
        opacity: "0%",
      }}
      animate={{
        opacity: ["60%", "90%"],
      }}
      transition={{
        duration: 1,
        repeatType: "reverse",
      }}
      style={{
        transform: rotate,
      }}
      title={props.plotNumber}
    >
      <Box
        as={props.hasUnits ? Link : undefined}
        w="full"
        h="full"
        display="inline-block"
        title={props.plotNumber}
        to={destinationUrl}
      ></Box>
    </MotionButton>
  );
}

type NavButtonVillasProps = NavButtonProps & {
  bedroomNumber: number;
};

export function NavButtonVillas(props: NavButtonVillasProps) {
  const rotate = `rotate(${props.rotateDegree}deg)`;

  const query = useQuery();
  const projectName = query.get("p") || null;
  const projectZone = query.get("z") || "1";
  const urlQuery = `p=${projectName}&type=${props.buildingType}&plotNumber=${props.plotNumber}&z=${projectZone}&bd=${props.bedroomNumber}`;

  const destinationPage = props.page || "floor-plate-unit-page";
  const destinationUrl = `${destinationPage}?${urlQuery}`;

  return (
    <MotionButton
      as={props.hasUnits ? Link : undefined}
      w={props.buttonWidth || "6%"}
      h={props.buttonHeight || "3%"}
      borderRadius="20%"
      bg={props.hasUnits ? "green.400" : "red"}
      position="absolute"
      top={props.topOffset}
      left={props.leftOffset}
      display={props.isDisplayed}
      style={{
        transform: rotate,
      }}
      initial={{
        opacity: "0%",
      }}
      animate={{
        opacity: ["60%", "80%"],
      }}
      transition={{
        duration: 1,
        repeatType: "reverse",
      }}
    >
      <Box
        w="full"
        h="full"
        display="inline-block"
        as={props.hasUnits ? Link : undefined}
        to={destinationUrl}
      ></Box>
    </MotionButton>
  );
}

// function getBgColorOnStatus(status: Status | undefined) {
//   if (status === undefined) {
//     return "red.500";
//   }

//   switch (status) {
//     case Status.AVAILABLE:
//       return "green.400";
//     case Status.BOOKED:
//     case Status.SOLD:
//       return "red";
//     case Status.NOT_AVAILABLE:
//       return "gray";
//     default:
//       return "red";
//   }
// }

// function shouldPrecede(status: Status | undefined) {
//   if (status === undefined) {
//     return false;
//   }

//   switch (status) {
//     case Status.AVAILABLE:
//       return true;
//     case Status.SOLD:
//     case Status.BOOKED:
//     case Status.NOT_AVAILABLE:
//       return false;
//     default:
//       return false;
//   }
// }
