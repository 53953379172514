import SectionOne, { SectionProps } from "./SectionOne";

import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
import SectionFour from "./SectionFour";
import SectionFive from "./SectionFive";
import SectionSix from "./SectionSix";
import SectionSeven from "./SectionSeven";
import SectionEight from "./SectionEight";
import SectionNine from "./SectionNine";

const OptionButtonsContainer = (props: SectionProps) => {
  return (
    <>
      <SectionOne
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />
      <SectionTwo
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />
      <SectionThree
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />
      <SectionFour
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />
      <SectionFive
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />
      <SectionSix
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />
      <SectionSeven
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />
      <SectionEight
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />

      <SectionNine
        hasUnits={props.hasUnits}
        type={props.type}
        display={props.display}
        properties={props.properties}
        propertyUrl={props.propertyUrl}
      />
    </>
  );
};

export default OptionButtonsContainer;
