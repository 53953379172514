import React, { Suspense } from "react";

import { Box, Center, Spinner } from "@chakra-ui/react";

import {
  useChoseOnLightMode,
  useChoseStyleOnLandscape,
} from "./utils/Selecting";

import { BrowserRouter as Router, Switch } from "react-router-dom";

// Lazy Components
const BackgroundImage = React.lazy(
  () => import("./components/BackgroundImage")
);

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function AppContainer(props: Props) {
  const selectedMode = useChoseOnLightMode("white", "blue.900");

  const containerStylesInLandscape = {
    py: {
      "1xsm": "0.7rem",
      xl: "1.5rem",
    },
    px: {
      "1xsm": "0.9rem",
      xl: "2rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Box
      bg={selectedMode}
      px={{
        base: "4%",
        tablet12: "2.5rem",
      }}
      py={{
        base: "4%",
        tablet4: "3%",
        tablet12: "1.5rem",
      }}
      w="100%"
      h="100%"
      position="relative"
      zIndex="0"
      {...styles}
    >
      <Router>
        <Suspense fallback={<LazyLoadingComp />}>
          <Switch>{props.children}</Switch>
        </Suspense>
      </Router>
      <Suspense fallback={<LazyLoadingComp />}>
        <BackgroundImage />
      </Suspense>
    </Box>
  );
}

function LazyLoadingComp() {
  return (
    <Center w="full" h="full">
      <Spinner
        sx={{
          "--spinner-size": "15rem",
        }}
        thickness="7px"
        color="red.500"
        emptyColor="gray.200"
        speed="0.85s"
        size="xl"
      />
    </Center>
  );
}
