import { Flex, HStack, VStack, Text } from "@chakra-ui/react";
import { ProjectNames } from "hooks/query_values/useQueryProjectName";

export interface DisplayBookingInfoProps {
  unitName?: string;
  unitTextInfo?: string;
  projectName?: string;

  plotArea?: string;
  Price?: number;
}

export const DisplayBookingInfo = (props: DisplayBookingInfoProps) => {
  return (
    <VStack w="full">
      <HStack w="full" spacing="1rem">
        <BriefInfo label={props.projectName || ProjectNames.NEW_CAIRO} />
        <BriefInfo label={props.unitName || "4"} />
        <BriefInfo
          label={props.unitTextInfo || "Thank you for buying from Us."}
        />
      </HStack>
      <VStack
        w="full"
        spacing="1rem"
        px="2rem"
        py="1rem"
        bgColor="#6d6e72"
        alignItems="flex-start"
      >
        <Text color="white">Unit: {props.unitName || "4"}</Text>
        <Text color="white">PlotArea: {props.plotArea || "60"} sqm</Text>
        <Text color="white">Price: {props.Price || "50000"} EGP</Text>
      </VStack>
    </VStack>
  );
};

export interface BriefInfoProps {
  label: string;
}

function BriefInfo(props: BriefInfoProps) {
  return (
    <Flex
      h="full"
      flex="1"
      alignItems="center"
      justifyContent="flex-start"
      bgColor="#6d6e72"
      px="2rem"
      py="1rem"
    >
      <Text color="white">{props.label}</Text>
    </Flex>
  );
}
