import { useFetcherSingle } from "hooks/useFetcher";

import Property from "interfaces/property";

import useQueryZoneName from "hooks/query_values/useQueryZoneName";
import useQueryUnitType from "hooks/query_values/useQueryUnitType";
import useQueryPlotNumber from "hooks/query_values/useQueryPlotNumber";
import useQueryProjectName, {
  ProjectNames,
} from "hooks/query_values/useQueryProjectName";
import useQueryBedroomNumbers from "hooks/query_values/useQueryBedroomNumbers";
import useQueryFloorNumber from "hooks/query_values/useQueryFloorNumber";
import useQueryUnitNumber from "hooks/query_values/useQueryUnitNumber";

export default function useFetchProperty() {
  const projectName = useQueryProjectName();
  const zoneName = useQueryZoneName();
  const plotNumber = useQueryPlotNumber();
  const unitType = useQueryUnitType();
  const bedroomNumber = useQueryBedroomNumbers().toString();

  const floorNumber = useQueryFloorNumber();
  const unitNumber = useQueryUnitNumber();

  const zayedUrl = `/properties/ZA/R/3/Apartment/${bedroomNumber}/${floorNumber}/${unitNumber}`;
  const newCairoUrl = `/properties/NC/${zoneName}/${plotNumber}/${unitType}/${bedroomNumber}`;
  const fetchUrl =
    projectName === ProjectNames.NEW_CAIRO ? newCairoUrl : zayedUrl;

  console.group(`Fetching Properties from ${useFetchProperty.name} Hook`);
  console.log({
    projectName,
    zoneName,
    plotNumber,
    unitType,
    bedroomNumber,
  });
  console.groupEnd();

  const properties = useFetcherSingle<Property>(fetchUrl, 5000);

  return properties;
}
