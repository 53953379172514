import { lazy, memo, Suspense } from "react";
import { Center, Spinner } from "@chakra-ui/react";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";
// import useRegisterProperty from "../../hooks/updating_state/useRegisterProperty";

import { useChoseStyleOnLandscape } from "../../utils/Selecting";

// Custom Components
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

// Lazy Loading Custom Components
const HeroImage = lazy(() => import("./ProjectHeroImage/ProjectHeroImage"));

function PropertyDisplayPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const pageHeader = useQueryProjectName();

  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "0.9rem",
    },
  };

  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <PageContainerComponent {...styles}>
      <LocationIndicator text={`Celltek ${pageHeader}`} />
      <Suspense fallback={<LazyLoading />}>
        <HeroImage />
      </Suspense>
    </PageContainerComponent>
  );
}

export default memo(PropertyDisplayPage);

export function LazyLoading() {
  return (
    <Center w="full" h="full">
      <Spinner
        size="xl"
        emptyColor="gray.200"
        thickness="2px"
        speed="0.85s"
        color="red.500"
      />
    </Center>
  );
}
