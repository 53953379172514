import { NavButtonVillas } from "../NavButtons/NavButton";

import Property, { PropertyTypes } from "../../../interfaces/property";
import useFetcher from "../../../hooks/useFetcher";

import OptionButtonsContainer from "./FirstOptionButtons/OptionButtonsContainer";

export function DuplexOption(props: { displayed: boolean }) {
  const display = props.displayed ? undefined : "none";
  const type = PropertyTypes.DUPLEX;

  const properties = useFetcher<Property>("/properties/Duplex/35");

  return (
    <>
      <OptionButtonsContainer
        hasUnits
        propertyUrl="/facade/duplex"
        display={display}
        type={type}
        properties={properties}
      />
    </>
  );
}

export function FourBedroomVillaOption(props: { displayed: boolean }) {
  const display = props.displayed ? undefined : "none";
  const bedroomNumber = 4;
  const type = PropertyTypes.VILLA;

  // const properties = useFetcher<Property>("/properties/4 Bedroom Villa/31");

  return (
    <>
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="38%"
        leftOffset="39%"
        buttonWidth="4.5%"
        buttonHeight="6%"
        rotateDegree={5}
        hasUnits
        plotNumber="320"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="45%"
        leftOffset="40%"
        buttonWidth="4.5%"
        buttonHeight="6%"
        rotateDegree={5}
        hasUnits
        plotNumber="321"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="52%"
        leftOffset="40%"
        buttonWidth="4.5%"
        buttonHeight="6%"
        rotateDegree={5}
        hasUnits
        plotNumber="322"
      />

      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="60.2%"
        leftOffset="40%"
        buttonWidth="4.5%"
        buttonHeight="6%"
        rotateDegree={5}
        hasUnits
        plotNumber="323"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="65.1%"
        leftOffset="46.2%"
        buttonWidth="4.5%"
        buttonHeight="6%"
        rotateDegree={0}
        hasUnits
        plotNumber="324"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="59.3%"
        leftOffset="45.8%"
        buttonWidth="4.8%"
        buttonHeight="4.7%"
        rotateDegree={354}
        hasUnits
        plotNumber="325"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="53%"
        leftOffset="45.3%"
        buttonWidth="4.8%"
        buttonHeight="6%"
        rotateDegree={347}
        hasUnits
        plotNumber="326"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="46%"
        leftOffset="45%"
        buttonWidth="4.8%"
        buttonHeight="6%"
        rotateDegree={347}
        hasUnits
        plotNumber="327"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        topOffset="38%"
        leftOffset="44%"
        buttonWidth="5%"
        buttonHeight="6%"
        rotateDegree={347}
        hasUnits
        plotNumber="328"
      />

      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={342}
        buttonWidth="4%"
        buttonHeight="6%"
        topOffset="35%"
        leftOffset="51%"
        hasUnits
        plotNumber="329"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={346}
        buttonWidth="4%"
        buttonHeight="5%"
        topOffset="41%"
        leftOffset="52%"
        hasUnits
        plotNumber="330"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={346}
        buttonWidth="4%"
        buttonHeight="5%"
        topOffset="47%"
        leftOffset="53%"
        hasUnits
        plotNumber="331"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={346}
        buttonWidth="4%"
        buttonHeight="5%"
        topOffset="53%"
        leftOffset="53.5%"
        hasUnits
        plotNumber="332"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={356}
        buttonWidth="4%"
        buttonHeight="5%"
        topOffset="59%"
        leftOffset="53.5%"
        hasUnits
        plotNumber="333"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={356}
        buttonWidth="4%"
        buttonHeight="5%"
        topOffset="65%"
        leftOffset="53.5%"
        hasUnits
        plotNumber="334"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={356}
        buttonWidth="4%"
        buttonHeight="5%"
        topOffset="71%"
        leftOffset="53.5%"
        hasUnits
        plotNumber="335"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={356}
        buttonWidth="5%"
        buttonHeight="6.7%"
        topOffset="70%"
        leftOffset="58.2%"
        hasUnits
        plotNumber="336"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={356}
        buttonWidth="4.5%"
        buttonHeight="5.4%"
        topOffset="64%"
        leftOffset="58.2%"
        hasUnits
        plotNumber="337"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={356}
        buttonWidth="4.5%"
        buttonHeight="5.4%"
        topOffset="58%"
        leftOffset="58.2%"
        hasUnits
        plotNumber="338"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={353}
        buttonWidth="4.5%"
        buttonHeight="5%"
        topOffset="52.6%"
        leftOffset="58.1%"
        hasUnits
        plotNumber="339"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={348}
        buttonWidth="4.5%"
        buttonHeight="3.9%"
        topOffset="48.3%"
        leftOffset="57.7%"
        hasUnits
        plotNumber="340"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={348}
        buttonWidth="4.5%"
        buttonHeight="4.7%"
        topOffset="42.7%"
        leftOffset="57%"
        hasUnits
        plotNumber="341"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={348}
        buttonWidth="4.1%"
        buttonHeight="4.7%"
        topOffset="37.3%"
        leftOffset="56.5%"
        hasUnits
        plotNumber="342"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={340}
        buttonWidth="4.1%"
        buttonHeight="4.7%"
        topOffset="32%"
        leftOffset="55.5%"
        hasUnits
        plotNumber="343"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={340}
        buttonWidth="4.1%"
        buttonHeight="4.7%"
        topOffset="27%"
        leftOffset="62.2%"
        hasUnits
        plotNumber="344"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={340}
        buttonWidth="4.1%"
        buttonHeight="4.7%"
        topOffset="32.8%"
        leftOffset="63.4%"
        hasUnits
        plotNumber="345"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={340}
        buttonWidth="4.1%"
        buttonHeight="3.8%"
        topOffset="37.8%"
        leftOffset="64.3%"
        hasUnits
        plotNumber="346"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={347}
        buttonWidth="4.1%"
        buttonHeight="4.6%"
        topOffset="42.5%"
        leftOffset="64.9%"
        hasUnits
        plotNumber="347"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={347}
        buttonWidth="4.1%"
        buttonHeight="4.2%"
        topOffset="47.9%"
        leftOffset="65.3%"
        hasUnits
        plotNumber="348"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={355}
        buttonWidth="4.1%"
        buttonHeight="4.2%"
        topOffset="52.9%"
        leftOffset="65.4%"
        hasUnits
        plotNumber="349"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={355}
        buttonWidth="4.1%"
        buttonHeight="4.8%"
        topOffset="58.7%"
        leftOffset="65.4%"
        hasUnits
        plotNumber="350"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={355}
        buttonWidth="4.1%"
        buttonHeight="7.2%"
        topOffset="65%"
        leftOffset="65.4%"
        hasUnits
        plotNumber="351"
      />


      {/* Fourth Column */}
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={355}
        buttonWidth="4.1%"
        buttonHeight="5.4%"
        topOffset="62.8%"
        leftOffset="70.8%"
        hasUnits
        plotNumber="352"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={355}
        buttonWidth="4.1%"
        buttonHeight="4.9%"
        topOffset="56.5%"
        leftOffset="70.4%"
        hasUnits
        plotNumber="353"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={355}
        buttonWidth="3.7%"
        buttonHeight="4.6%"
        topOffset="51.2%"
        leftOffset="70.4%"
        hasUnits
        plotNumber="354"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={355}
        buttonWidth="3.7%"
        buttonHeight="4.3%"
        topOffset="45.8%"
        leftOffset="69.9%"
        hasUnits
        plotNumber="355"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={344}
        buttonWidth="3.3%"
        buttonHeight="4%"
        topOffset="40.8%"
        leftOffset="69.6%"
        hasUnits
        plotNumber="356"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={344}
        buttonWidth="4%"
        buttonHeight="4%"
        topOffset="35.9%"
        leftOffset="68.7%"
        hasUnits
        plotNumber="357"
      />

      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={344}
        buttonWidth="4%"
        buttonHeight="4%"
        topOffset="30.8%"
        leftOffset="68%"
        hasUnits
        plotNumber="358"
      />
      <NavButtonVillas
        bedroomNumber={bedroomNumber}
        buildingType={type}
        isDisplayed={display}
        rotateDegree={344}
        buttonWidth="4%"
        buttonHeight="6%"
        topOffset="24%"
        leftOffset="67%"
        hasUnits
        plotNumber="359"
      />
    </>
  );
}
