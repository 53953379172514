import { HStack, useMediaQuery, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { RefObject, useState } from "react";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectTheme } from "../features/theme/themeSlice";
import { useChoseValueOnLandscape } from "./Selecting";

export function useThemeValueBool() {
  const theme = useSelector(selectTheme);
  const isLightTheme = theme === "light";

  return isLightTheme;
}

export function useLandscapeValueToBool() {
  let [isPageOrientationLandscape, setIsPageOrientationLandscape] = useState(
    // eslint-disable-next-line no-restricted-globals
    screen.orientation.angle !== 0
  );

  window.addEventListener(
    "orientationchange",
    () => {
      // eslint-disable-next-line no-restricted-globals
      setIsPageOrientationLandscape(screen.orientation.angle !== 0);
    },
    false
  );

  return isPageOrientationLandscape;
}

export function useDetectElementPosition(element: RefObject<any> | null) {
  const rawPosition = element?.current?.getBoundingClientRect();
  return rawPosition;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function capitalizeFirstLetter(text: string | null) {
  if (text) return _.startCase(_.camelCase(text));
  else return null;
}

export function capitalizeText(txt: string) {
  return txt.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export function useGetQueryValue(query: string) {
  return useQuery().get(query) || null;
}

export function useGetValueBasedOnHeight() {}

export function useIsDeviceIsInLandscape() {
  const [isLandscape] = useMediaQuery(["(orientation: landscape)"]);

  return isLandscape;
}

export function useGetStackTypeElOnPageOrientation() {
  return useChoseValueOnLandscape(HStack, VStack);
}
