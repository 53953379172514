// Custom Hooks
import useQueryPropertyType, {
  PropertyTypes,
} from "../query_values/useQueryPropertyType";
import useQueryBedroomNumbers from "../query_values/useQueryBedroomNumbers";

// Links to the 360 View
const links = {
  villa: {
    br3: "https://villa-3-br-360-view.vercel.app/",
    br4: "https://villa-4-br-360-view.vercel.app/",
  },
  apartment: {
    br1: "https://apartment-1-br-360-view.vercel.app/",
    br2: "https://apartment-2-br-360-view.vercel.app/",
    br3: "https://apartment-3-br-360-view.vercel.app/",
    studio: "https://apartment-studio-360-view.vercel.app/",
  },
};

export default function useSelect360PropertyView(bedroomsNumber?: number) {
  const propertyType = useQueryPropertyType();
  const queryBedroomNumbers = useQueryBedroomNumbers();

  switch (propertyType) {
    case PropertyTypes.DUPLEX:
    case PropertyTypes.APARTMENT:
    case PropertyTypes.PENTHOUSE:
    case PropertyTypes.TOWNHOUSE:
      return getApartmentLink(bedroomsNumber || queryBedroomNumbers);

    default:
      return getVillaLink(bedroomsNumber || queryBedroomNumbers);
  }
}

function getApartmentLink(bedroomNumbers: number) {
  switch (bedroomNumbers) {
    case 1:
      return links.apartment.br1;
    case 2:
      return links.apartment.br2;
    case 3:
      return links.apartment.br3;
    case 4:
      return links.apartment.studio;
    default:
      return links.apartment.studio;
  }
}

function getVillaLink(bedroomNumbers: number) {
  switch (bedroomNumbers) {
    case 3:
      return links.villa.br3;
    case 4:
      return links.villa.br4;
    default:
      return links.villa.br3;
  }
}
