import { Text } from "@chakra-ui/react";

type Props = {
  txt: string;
};

function BtnTxt(props: Props) {
  return (
    <Text
      fontSize={{
        base: "0.5rem",
        miniMobile6: "0.7rem",
        miniMobile10: "0.8rem",
        mobile1: "0.85rem",
        mobile5: "0.9rem",
        mobile8: "1.2rem",
        mobile10: "1.4rem",
        tablet2: "1.7rem",
        tablet9: "2.0rem",
        desktop3: "2.7rem",
        desktop15: "3.0rem",
      }}
      color="white"
    >
      {props.txt}
    </Text>
  );
}

export default BtnTxt;
