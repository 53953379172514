import { useGetQueryValue } from "utils/Values";

export default function useQueryPlotNumber() {
  const plotNumberTypeQueryValue = useGetQueryValue("plotNumber");

  if (!plotNumberTypeQueryValue) {
    return "";
  }

  return plotNumberTypeQueryValue;
}
