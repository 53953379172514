import { Box, Image } from "@chakra-ui/react";

// Custom Components
import MotionBox from "../../animated-components/MotionBox/MotionBox";

// Utils
import { useChoseOnFlag } from "../../../utils/Selecting";

export default function ProjectImage({ image = "", isAvailable = false }) {
  const cover = useChoseOnFlag(isAvailable, null, ImageCover) && <ImageCover />;

  return (
    <Box w="full" h="full" pos="relative">
      {cover}
      <Image
        w="100%"
        h="100%"
        borderRadius={["0.8rem", "0.8rem", "0.8rem", "2rem"]}
        src={image}
      />
    </Box>
  );
}

function ImageCover() {
  return (
    <Box
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 0.5,
      }}
      exit={{
        opacity: 0,
      }}
      as={MotionBox}
      w="full"
      h="full"
      pos="absolute"
      top="0"
      left="0"
      bg="white"
      borderRadius={["0.8rem", "0.8rem", "0.8rem", "1rem"]}
    ></Box>
  );
}
