import { FormControl, Input } from "@chakra-ui/react";

export interface FormInputProps {
  placeholderText: string;
  registerText: string;
  registerOptions?: any;
  inputType?: string;
  formRegisterFunc: (txt: string | any, opt?: any) => any;
}

export const FormInput = (props: FormInputProps) => {
  return (
    <FormControl h="full" w="full" flex="1">
      <Input
        required
        h="full"
        px="1rem"
        py="0.5rem"
        type={props.inputType}
        borderColor="red.500"
        borderWidth="3px"
        placeholder={props.placeholderText}
        {...props.formRegisterFunc(
          props.registerText,
          props.registerOptions
        )}
      />
    </FormControl>
  );
};
