import { Image, VStack } from "@chakra-ui/react";

// Custom Components
import UnitPlateInformatics from "./UnitPlateInformations";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

import useQueryUnitType from "hooks/query_values/useQueryUnitType";
import useQueryBedroomNumbers from "../../hooks/query_values/useQueryBedroomNumbers";
import useQueryPlotNumber from "hooks/query_values/useQueryPlotNumber";

// Images Sources
import image_1BR from "../../img/floor_plate_unit_info_page/1-BED.jpg";
import image_2BR from "../../img/floor_plate_unit_info_page/floor_33.jpg";
import image_3BR from "../../img/floor_plate_unit_info_page/3-BED.jpg";
import image_4BR from "../../img/floor_plate_unit_info_page/4-BED.jpg";

export const FloorUnitPlateInfo = () => {
  // Auth before accessing this page
  useVerifyAuth();

  const image = useSelectBedroomImage();

  const unitType = useQueryUnitType();
  const plotNumber = useQueryPlotNumber();

  const header = `${unitType}s Plot-${plotNumber} Plate`;

  return (
    <PageContainerComponent
      overflow="hidden"
      spacing={{
        base: "0rem",
        mobile7: "0.5rem",
        mobile10: "0.5rem",
        tablet16: "3rem",
        tablet19: "1.0rem",
        desktop5: "1rem",
      }}
    >
      <LocationIndicator text={header} />
      <VStack w="full" h="86%" pos="relative">
        <Image
          w="full"
          h="full"
          src={image}
          borderRadius={["1.5rem", "3rem"]}
        />
        <UnitPlateInformatics />
      </VStack>
    </PageContainerComponent>
  );
};

function useSelectBedroomImage(bedroomNumber?: number) {
  const queryBedrooms = useQueryBedroomNumbers();

  const realBedroomsNumber = bedroomNumber || queryBedrooms;

  switch (realBedroomsNumber) {
    case 1:
      return image_1BR;
    case 2:
      return image_2BR;
    case 3:
      return image_3BR;
    case 4:
      return image_4BR;
    default:
      return image_1BR;
  }
}
