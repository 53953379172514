import { memo } from "react";
import { VStack } from "@chakra-ui/react";

// Styling
import styles from "./InfoBoxContainer.module.css";

// Utils
import { useChoseOnLightMode } from "../../../../../utils/Selecting";

type Props = {
  children: JSX.Element | JSX.Element[];
};

function InfoBoxContainer(props: Props) {
  const modeBgColor = useChoseOnLightMode("white", "blue.800");

  return (
    <VStack
    className={styles.info_box_container}
      overflow="hidden"
      h="full"
      flex="1"
      borderRadius={{
        base: "1rem",
        lg: "2rem",
      }}
      borderWidth={{
        base: "0.1rem",
        lg: "0.3rem",
      }}
      borderColor="#bf1723"
      p={{
        base: "0.5rem",
        sm: "1rem",
        lg: "1.5rem",
      }}
      spacing={{
        base: "0.3rem",
        lg: "1.2rem",
      }}
      bgColor={modeBgColor}
    >
      {props.children}
    </VStack>
  );
}

export default memo(InfoBoxContainer);
