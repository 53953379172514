import Icon from "@chakra-ui/icon";
import { IconsNames, pickIcon } from "./utils";

import { useSelector } from "react-redux";
import { selectActiveIcons } from "../../../app/map/activeCompSlice";

export interface PinIconProps {
  iconName?: IconsNames;
  idleColor?: string;
  isResidential?: boolean;
}

export default function PinIcon(props: PinIconProps) {
  const IconName = props.iconName || IconsNames.Club;
  const IdleColor = props.idleColor || "blue.900";

  const icon = pickIcon(IconName);

  const activeIcons = useSelector(selectActiveIcons);

  const activateColor = activeIcons[IconName] ? "#E53E3E" : IdleColor;

  const stylingObj = {
    "& path": {
      fill: `${activateColor} !important`,
    },
  };

  const stylingObjForRes = {
    "& path": {
      color: `${activateColor} !important`,
    },
  };

  return (
    <Icon
      sx={props.isResidential ? stylingObjForRes : stylingObj}
      color={activateColor}
      fill={activateColor}
      w="full"
      h="full"
      as={icon}
      fontSize={30}
    />
  );
}
