import { SectionProps } from "./SectionOne";

import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

const SectionFive = (props: SectionProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1%"
        buttonHeight="3%"
        rotateDegree={40}
        topOffset={["91.7%", "91.7%", "91.7%", "84.0%", "85.0%", "88.0%"]}
        leftOffset={["32.9%", "32.9%", "32.9%", "32.9%", "32.9%", "32.9%"]}
        plotNumber="203"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1%"
        buttonHeight="3%"
        rotateDegree={15}
        topOffset={["90.0%", "90.0%", "94.0%", "86%", "86.5%", "90.0%"]}
        leftOffset="34.7%"
        plotNumber="204"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1%"
        buttonHeight="3%"
        rotateDegree={354}
        topOffset={["90.5%", "90.5%", "94%", "87%", "87%", "90.5%"]}
        leftOffset="36.7%"
        plotNumber="205"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["94.5%", "94.5%", "94.5%", "87.5%", "88.0%", "90.5%"]}
        leftOffset="38.8%"
        plotNumber="206"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["89.7%", "89.7%", "92.7%", "86.2%", "88.0%", "89.7%"]}
        leftOffset="39.8%"
        plotNumber="207"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["88.7%", "88.7%", "91.7%", "85.4%", "86.0%", "88.7%"]}
        leftOffset="40.7%"
        plotNumber="208"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["88.1%", "88.1%", "91.1%", "84.6%", "85.0%", "88.1%"]}
        leftOffset="41.8%"
        plotNumber="209"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["87.3%", "87.3%", "90.3%", "83.8%", "84.2%", "87.3%"]}
        leftOffset="42.9%"
        plotNumber="210"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["86.3%", "86.3%", "89.3%", "82.9%", "83.8%", "86.3%"]}
        leftOffset="43.9%"
        plotNumber="211"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["84.7%", "87.7%", "87.7%", "81.3%", "82.2%", "84.7%"]}
        leftOffset="45.5%"
        plotNumber="212"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["83.6%", "83.6%", "86.6%", "80.6%", "81.1%", "83.6%"]}
        leftOffset="46.5%"
        plotNumber="213"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["82.7%", "82.7%", "85.7%", "80.2%", "80.2%", "82.7%"]}
        leftOffset="47.5%"
        plotNumber="214"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={334}
        topOffset={["81.8%", "81.8%", "84.8%", "79.2%", "79.3%", "81.8%"]}
        leftOffset="48.5%"
        plotNumber="215"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
    </>
  );
};

export default SectionFive;
