// HTTP-Requests
import axios from "axios";

// Navigation
import { useHistory } from "react-router-dom";

// Redux Functions.
import { useSelector } from "react-redux";
import { selectAdmin } from "../../app/auth/auth.slice";

// Interfaces.
import Admin from "../../interfaces/Admin";

// Constants.
import baseURL from "../../constants/baseURL";

export default function useVerifyAuth() {
  const history = useHistory();

  const admin = useSelector(selectAdmin);

  const adminFromToken = verifyFromToken();

  if (hasUsernameAndPassword(admin)) {
    return admin;
  } else if (adminFromToken) {
    return adminFromToken;
  } else {
  }
}

function hasUsernameAndPassword(admin: Admin) {
  return admin.username && admin.password;
}

function verifyFromToken() {
  const url = `${baseURL}/auth/login/real-estate/profile`;

  let admin: Admin | null = null;

  axios
    .get<Admin>(url, {
      withCredentials: true,
    })
    .then((response) => {
      admin = response.data;
    })
    .catch((error) => {
      admin = null;
    });

  return admin;
}
