import { Box, VStack } from "@chakra-ui/react";

// Stylings
import externalStyes from "./NewCairoHeroImage.module.css";

// Custom Components
import NewCairoMap from "../map/Map";

// Utils
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

export default function NewCairoHeroImage() {
  // Landscape Styling.
  const containerStylesInLandscape = {
    h: {
      "1xsm": "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  // Landscape Styling.
  const subContainerStylesInLandscape = {
    borderRadius: {
      "1xsm": "1rem",
    },
    borderWidth: {
      "1xsm": "0.15rem",
    },
    p: {
      "1xsm": "0rem",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  return (
    <VStack
      className={externalStyes.new_cairo_hero_image}
      w="100%"
      h={{ base: "75%", mobile8: "79%" }}
      flex={{ base: 20 }}
      spacing="2rem"
      {...styles}
    >
      <Box w="100%" h="100%" p="0.1rem" overflow="hidden">
        <Box
          w="100%"
          h="100%"
          borderRadius={{
            base: "1rem",
            miniMobile10: "1rem",
            mobile8: "2rem",
            tablet16: "4rem",
          }}
          borderWidth={{ base: "2px", mobile8: "3px", tablet16: "5px" }}
          borderColor="red.500"
          p="0.1rem"
          overflow="hidden"
          position="relative"
          {...subContainerStyles}
        >
          <NewCairoMap />
        </Box>
      </Box>
    </VStack>
  );
}
