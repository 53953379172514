import React, { Suspense, useEffect } from "react";
import { VStack } from "@chakra-ui/react";

import { ActivePins } from "./project-pins/ProjectPins";
import PinsLocation from "./project-pins/utils";

import CtxImage from "./CtxImage";
import LoadingComp from "../../common/loading-comp/LoadingComp";
import {
  selectMenuAnimation,
  stopMenuAnimation,
} from "../../common/NavigationMenu/NavigationMenuSlice";
import { useDispatch, useSelector } from "react-redux";

// const DetailsTxt = React.lazy(() => import("./DetailsTxt"));
const ProjectPins = React.lazy(() => import("./project-pins/ProjectPins"));

export default function NewCairoHeroImage(props: {
  activePins: ActivePins;
  pinsLocations?: PinsLocation;
}) {
  const { activePins, pinsLocations } = props;

  const isMenuShown = useSelector(selectMenuAnimation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMenuShown) dispatch(stopMenuAnimation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack w="100%" h={{base:"70%", tablet16:"70%"}} position="relative">
      <CtxImage />
      <Suspense fallback={<LoadingComp />}>
        {/* <DetailsTxt /> */}
        <ProjectPins {...activePins} {...pinsLocations} />
      </Suspense>
    </VStack>
  );
}
