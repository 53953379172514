import { Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";
export interface OptionIconProps {
  icon: IconType | any;
  iconStyle?: any;
}

export default function OptionIcon(props: OptionIconProps) {
  return (
    <Icon
      as={props.icon}
      fontSize={{ base: 20, tablet13: 15, tablet16: 35 }}
      h="100%"
      color="red.500"
      _groupHover={{
        color: "white",
      }}
      _groupActive={{
        color: "white",
      }}
      _groupFocus={{
        color: "white",
      }}
      {...props.iconStyle}
    />
  );
}
