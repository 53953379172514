import { useChoseOnFlag } from "../../../utils/Selecting";
import { useGetQueryValue } from "../../../utils/Values";

//? Utils Comps
import {
  ApartmentOption,
  TwoBedRoomVillaOption,
} from "../BuildingsOptions/FirstOption";
import {
  PenthouseOption,
  ThreeBedroomVillaOption,
} from "../BuildingsOptions/SecondOption";
import {
  DuplexOption,
  FourBedroomVillaOption,
} from "../BuildingsOptions/ThirdOption";
import {
  TownhouseOption,
  FiveBedroomVillaOption,
} from "../BuildingsOptions/FourthOption";

type Props = {
  firstBuildingVisibility: boolean;
  secondBuildingVisibility: boolean;
  thirdBuildingVisibility: boolean;
  forthBuildingVisibility: boolean;
};

export default function ButtonsOnTheMap(props: Props) {
  const zoneNumber = useGetQueryValue("z") || "1";
  const isZoneNumber2 = zoneNumber === "2";


  //Buildings Components
  const FirstBuilding = useChoseOnFlag(
    isZoneNumber2,
    <TwoBedRoomVillaOption displayed={props.firstBuildingVisibility} />,
    <ApartmentOption displayed={props.firstBuildingVisibility} />
  );
  const SecondBuilding = useChoseOnFlag(
    isZoneNumber2,
    <ThreeBedroomVillaOption displayed={props.secondBuildingVisibility} />,
    <PenthouseOption displayed={props.secondBuildingVisibility} />
  );
  const ThirdBuilding = useChoseOnFlag(
    isZoneNumber2,
    <FourBedroomVillaOption displayed={props.thirdBuildingVisibility} />,
    <DuplexOption displayed={props.thirdBuildingVisibility} />
  );
  const FourthBuilding = useChoseOnFlag(
    isZoneNumber2,
    <FiveBedroomVillaOption displayed={props.forthBuildingVisibility} />,
    <TownhouseOption displayed={props.forthBuildingVisibility} />
  );

  return (
    <>
      {FirstBuilding}
      {SecondBuilding}
      {ThirdBuilding}
      {FourthBuilding}
    </>
  );
}
