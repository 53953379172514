import { memo } from "react";

import { Box, Center, Text } from "@chakra-ui/react";

import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

function ProjectTitle({ projectName = "Apartment" }) {
  const containerStylesInLandscape = {
    fontSize: {
      "1xsm": "1.2rem",
      desktop5: "2.5rem"
    },
  };
  const txtStyles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Box
      w="100%"
      h={["15%", "18%"]}
      position="absolute"
      bottom="0"
      left="0"
      borderBottomRadius={["0.8rem", "0.8rem", "0.8rem", "2rem"]}
      bg="#000000ca"
    >
      <Center w="100%" h="100%">
        <Text
          fontSize={{ base: "xl", tablet16: "5xl" }}
          color="white"
          {...txtStyles}
        >
          {projectName}
        </Text>
      </Center>
    </Box>
  );
}

export default memo(ProjectTitle);
