import { memo, lazy, Suspense } from "react";

// Custom Components
import LazyLoadingComp from "../common/LazyLoadingComp";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

const Map = lazy(() => import("./map/Map"));

function LocationPage() {
  return (
    <PageContainerComponent>
      <LocationIndicator text="Location" />
      <Suspense fallback={<LazyLoadingComp />}>
        <Map />
      </Suspense>
    </PageContainerComponent>
  );
}

export default memo(LocationPage);
