// Styling
import externalStyles from "./ProjectMasterPlanePage.module.css";

// Custom Components
import HeroImage from "./PageHeroImage/PageHeroImage";
import OptionsButtons from "../project-presentation-page/OptionsButtons";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";
import useGetAllQueries from "hooks/query_values/useGetAllQueries";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

import { useChoseStyleOnLandscape } from "../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";

export default function ProjectMasterPlanePage() {
  // Auth before accessing this page
  useVerifyAuth();

  const projectName = useQueryProjectName()

  const query = useGetAllQueries();

  const StackType = useGetStackTypeElOnPageOrientation();

  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
    h: {
      "1xsm": "94%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <PageContainerComponent overflow="hidden">
      <LocationIndicator
      pageUrl={`/project-presentation-page?${query}`}
        fontSize={{
          base: "1rem",
          miniMobile9: "1.3rem",
          miniMobile12: "1.3rem",
          mobile1: "1.4rem",
          mobile8: "2.0rem",
          mobile13: "2.5rem",
          tablet16: "3.5rem",
        }}
        text={`Celltek ${projectName} Master Plan`}
      />
      <StackType
        className={externalStyles.main_content_container}
        w="full"
        h="90%"
        spacing={{ base: "0.7rem", tablet16: "2rem" }}
        {...styles}
      >
        <HeroImage />
        <OptionsButtons isMasterPlanPage />
      </StackType>
    </PageContainerComponent>
  );
}
