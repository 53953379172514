import { useState } from "react";
import { Redirect } from "react-router-dom";

import { Flex, Link } from "@chakra-ui/react";

// Interfaces
import { UnitInfoProps } from "../../../property-floor-plate/FloorInfo/UnitInfo/UnitInfo";
export interface SingleFloorProps extends UnitInfoProps {
  isTransparent?: boolean;
}

export const SingleInfo = (props: SingleFloorProps) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const onClickLink = () => {
    setShouldRedirect(true);
  };

  return (
    <Flex
      as={props.linkInfo?.isDisplayed ? Link : undefined}
      to={props.linkInfo?.destinationUrl}
      pos="absolute"
      w={props.dimensions?.width || "5vw"}
      h={props.dimensions?.height || "10vw"}
      bg={props.linkInfo?.isDisplayed ? "purple.500" : "red.500"}
      top={props.position?.top || 0}
      left={props.position?.left || 0}
      opacity={props.isTransparent ? 0 : 0.4}
      onClick={onClickLink}
    >
      {shouldRedirect && props.linkInfo?.destinationUrl ? (
        <Redirect to={props.linkInfo?.destinationUrl} />
      ) : null}
    </Flex>
  );
};