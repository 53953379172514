import {memo} from "react";

import {Button} from "@chakra-ui/react";

import {
    useChoseOnLightMode,
    useChoseStyleOnLandscape,
} from "../../../utils/Selecting";

type Props = {
    isSelected?: boolean;
    name: string;
    isInfoBtn?: boolean;
};

function Btn(props: Props) {
    const selectedElTxtColor = useChoseOnLightMode("red.500", "white");

    const TxtStylesInLandscape = {
        fontSize: {
            "1xsm": "0.9rem",
            lg: "1.3rem",
            "2xl": "1.5rem",
            tablet13: "1.3rem",
        },
    };
    const txtStyles = useChoseStyleOnLandscape(TxtStylesInLandscape);

    return (
        <Button
            _groupActive={{
                color: "white",
            }}
            h="full"
            w="full"
            color={props.isSelected ? "white" : selectedElTxtColor}
            fontSize={{
                base: "0.7rem",
                xsm: "1.2rem",
                lg: "4xl",
                mobile8: "1.1rem",
                tablet2: "1.4rem",
            }}
            bg="transparent"
            _active={{
                bg: "transparent",
            }}
            _hover={{
                bg: "transparent",
            }}
            _focus={{
                outline: "none",
            }}
            {...txtStyles}
        >
            {props.name}
        </Button>
    );
}

export default memo(Btn);
