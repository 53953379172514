import _ from "lodash";
import { useQuery } from "./Values";

export function useQueryContainsValue(query: string, value: string): boolean {
  const queryValue = useQuery().get(query)?.toLocaleLowerCase();
  const haveValue: boolean = !!queryValue?.match(value.toLowerCase());

  return haveValue;
}
export function useQueryHasValue(query: string): boolean {
  const queryValue = useQuery().get(query)?.toLocaleLowerCase();
  const hasValue: boolean = _.isNull(queryValue);
  return hasValue;
}
