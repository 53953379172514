import { useAnimation } from "framer-motion";
import { memo, useEffect } from "react";
import { useSelector } from "react-redux";

import { useChoseOnLightMode } from "../../../utils/Selecting";

import MotionBox from "../../animated-components/MotionBox/MotionBox";
import { selectMenuAnimation } from "./NavigationMenuSlice";

type Props = {
  children: JSX.Element | JSX.Element[];
};

function Container(props: Props) {
  const bgColor = useChoseOnLightMode("white", "blue.900");

  const showMenuAppear = useSelector(selectMenuAnimation);
  const controls = useAnimation();

  useEffect(() => {
    if (showMenuAppear) {
      controls.start({
        height: ["0%", "22%"],
      });
    }

    if (!showMenuAppear) {
      controls.start({
        height: ["22%", "0%"],
        padding: "0rem",
      });
    }
  }, [showMenuAppear, controls]);

  return (
    <MotionBox
      animate={controls}
      transition={{
        duration: 0.7,
      }}
      pos="absolute"
      bottom="0"
      left="0"
      zIndex="50"
      p={{
        tablet16: "1rem",
      }}
      pt={{
        tablet16: "0rem",
      }}
      w="full"
      bg={bgColor}
    >
      {props.children}
    </MotionBox>
  );
}

export default memo(Container);
