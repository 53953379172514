import { HStack } from "@chakra-ui/react";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

export default function OptionsRow(props: any) {
  const containerStylesInLandscape = {
    h: {
      "1xsm": "46%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <HStack
      w="100%"
      h="45%"
      flex="1 1 0%"
      spacing={{
        base: "2vw",
        tablet16: "2vw"
      }}
      {...styles}
    >
      {props.children}
    </HStack>
  );
}
