export enum Status {
  SOLD = "Sold",
  HIDDEN = "Hidden",
  BOOKED = "Booked",
  PENDING = "Pending",
  AVAILABLE = "Available",
  CANCELLED = "Cancelled",
  IN_PROGRESS = "In Progress",
  NOT_AVAILABLE = "Not Available",
}

export enum ActiveState {
  ACTIVE = "Active",
  BLOCKED = "Blocked",
}

export enum PropertyTypes {
  VILLA = "Villa",
  DUPLEX = "Duplex",
  APARTMENT = "Apartment",
  PENTHOUSE = "Penthouse",
  TOWNHOUSE = "Townhouse",
}

export default interface Property {
  id: number;
  _id: string;
  Zone: string;
  Unit: string;
  Area: string;
  Floor: string;
  Price: number;
  Status: Status;
  Project: string;
  Bedroom: string;
  "Unit #": string;
  Description: string;
  "Unit Type": string;
  "Building/Plot": string;
}


export interface PropertyFloors {
  floorNumber: string;
}