import { Box, VStack, Image, Center, Text } from "@chakra-ui/react";

// Stylings
import externalStyling from "./ImageComponent.module.css";

// Utils
import { useChoseStyleOnLandscape } from "../../../../utils/Selecting";

type ImageComponentProps = {
  showImage: () => any;
  imageSrc: string;
  bedroomNumber: number;
};

export default function ImageComponent(props: ImageComponentProps) {
  // Landscape Styling.
  const containerStylesInLandscape = {
    borderRadius: {
      "1xsm": "1rem",
    },
    borderWidth: {
      "1xsm": "0.1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  // Landscape Styling.
  const imageStylesInLandscape = {};
  const imageStyles = useChoseStyleOnLandscape(imageStylesInLandscape);

  // Landscape Styling.
  const txtContainerStylesInLandscape = {
    h: {
      "1xsm": "30%",
    },
  };
  const txtContainerStyles = useChoseStyleOnLandscape(
    txtContainerStylesInLandscape
  );

  // Landscape Styling.
  const txtStylesInLandscape = {
    fontSize: {
      "1xsm": "1rem",
    },
  };
  const txtStyles = useChoseStyleOnLandscape(txtStylesInLandscape);

  return (
    <VStack
      className={externalStyling.image_container}
      borderRadius={[
        "1rem",
        "1rem",
        "1rem",
        "1rem",
        "1rem",
        "1rem",
        "1rem",
        "1rem",
        "1rem",
        "3rem",
      ]}
      borderWidth={["1px", "1px", "1px", "1px", "1px", "4px"]}
      borderColor="red.500"
      position="relative"
      w="49%"
      h="100%"
      onClick={props.showImage}
      {...styles}
    >
      <Image
      className={externalStyling.image}
        w="100%"
        h="100%"
        borderRadius={[
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "0.8rem",
          "3rem",
        ]}
        src={props.imageSrc}
        onClick={props.showImage}
        {...imageStyles}
      />
      <Box
      className={externalStyling.image_title_bg}
        w="100%"
        h={["15%", null, null, null, null, null, null, null, "25%", "15%"]}
        position="absolute"
        bottom="0"
        left="0"
        borderBottomRadius={[
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "1rem",
          "3rem",
        ]}
        bg="#000000c0"
        onClick={props.showImage}
        {...txtContainerStyles}
      >
        <Center w="100%" h="100%">
          <Text
            fontSize={["1rem", "1rem", "1rem", "1rem", "1.2rem", "5xl"]}
            color="white"
            {...txtStyles}
          >
            {props.bedroomNumber} Bedroom
          </Text>
        </Center>
      </Box>
    </VStack>
  );
}
