// Styles
import styles from "./Styles";

import { HStack } from "@chakra-ui/react";

import { Link } from "react-router-dom";

import { useGetQueryData } from "../../../../../utils/Navigation";

import {
  useChoseOnFlag,
  useChoseStyleOnLandscape,
} from "../../../../../utils/Selecting";

import { useChoseOnLightMode } from "../../../../../utils/Selecting";
import useQueryBedroomNumbers from "hooks/query_values/useQueryBedroomNumbers";
import useQueryPlotNumber from "hooks/query_values/useQueryPlotNumber";

type Props = {
  isActive?: boolean;
  clickAction?: (x?: any) => any;
  isLinkComp: boolean;
  destinationUrl: string;
  children: JSX.Element | JSX.Element[];
  bedroomsNumber?: string | number;
};

export default function OptionContainer(props: Props) {
  const { urlQuery } = useGetQueryData();
  const bedrooms = useQueryBedroomNumbers();
  const plotNumber = useQueryPlotNumber();

  const url = `${props.destinationUrl}?${urlQuery}&bd=${bedrooms}&plotNumber=${plotNumber}`;

  const bgColor = useChoseOnLightMode("transparent", "gray.300");
  const borderColor = useChoseOnLightMode("red.500", "transparent");

  const activateBgColor = useChoseOnFlag(props.isActive, "red.500", bgColor);

  // Landscape styles.
  const containerStylesInLandscape = {
    h: {
      "1xsm": "22%",
    },
    w: {
      "1xsm": "100%",
    },

    alignSelf: "flex-start",
  };
  const containerStyles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <HStack
      sx={styles}
      as={props.isLinkComp ? Link : undefined}
      to={url}
      w={{ base: "23.2%", tablet16: "22.5%" }}
      h="full"
      spacing={{ base: "0.2rem", tablet16: "1.5rem" }}
      bg={activateBgColor}
      borderColor={borderColor}
      borderWidth="thin"
      borderRadius={{ base: "0.5rem", tablet16: "0.7rem" }}
      py="2px"
      px={{ base: "0.5rem", tablet16: "1rem" }}
      boxShadow={
        props.isActive
          ? undefined
          : {
              tablet16: "2px 3px 2px 0px red",
            }
      }
      onClick={props.clickAction || undefined}
      {...containerStyles}
    >
      {props.children}
    </HStack>
  );
}
