import { Text, Box } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";
import { selectSharingVisibility } from "./sharingSlice";

export default function ErrorMsg() {
  const errorMsgVisibility = useSelector(selectSharingVisibility)
    .isErrorMsgVisible;
  const isVisible = errorMsgVisibility ? undefined : "none";

  // Landscape Styling.
  const containerStylesInLandscape = {
    fontSize: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Box pb="1rem" h="40%">
      <Text
        display={isVisible}
        h="full"
        fontSize={["1rem", null, null, null, null, null, null, "2rem"]}
        color="red.500"
        {...styles}
      >
        Please enter a valid email.
      </Text>
    </Box>
  );
}
