import { Flex } from "@chakra-ui/react";

// Custom Components
import SingleInfo from "./SingleInfo";

// Utils
import { useGetQueryValue } from "../../../utils/Values";
import useQueryPlotNumber from "hooks/query_values/useQueryPlotNumber";
import useQueryBedroomNumbers from "../../../hooks/query_values/useQueryBedroomNumbers";

export const UnitPlateInformatics = () => {
  const project = useGetQueryValue("p");
  const plotNumber = useQueryPlotNumber();
  const zone_number = useGetQueryValue("z");
  const property_id = useGetQueryValue("id");
  const property_type = useGetQueryValue("type");
  const bedrooms_number = useQueryBedroomNumbers();

  const destinationUrl = `/floor-plate-unit-page?p=${project}&z=${zone_number}&id=${property_id}&type=${property_type}&bd=${bedrooms_number}&plotNumber=${plotNumber}`;

  return (
    <Flex pos="absolute" top="0" left="0">
      {choseElements(bedrooms_number, destinationUrl)}
    </Flex>
  );
};

function choseElements(bedrooms: number, url: string) {
  switch (bedrooms) {
    case 1:
      return get1BRSelectionComponents(url);
    case 2:
      return get2BRSelectionComponents(url);
    case 3:
      return get3BRSelectionComponents(url);
    case 4:
      return get4BRSelectionComponents(url);
    default:
      return get1BRSelectionComponents(url);
  }
}

function get1BRSelectionComponents(url: string) {
  return (
    <>
      <SingleInfo
        dimensions={{
          width: "27vw",
          height: "43vw",
        }}
        position={{
          top: "17vw",
          left: "20vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: false,
        }}
      />

      <SingleInfo
        dimensions={{
          width: "27vw",
          height: "43vw",
        }}
        position={{
          top: "17vw",
          left: "47vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: true,
        }}
      />
      <SingleInfo
        dimensions={{
          width: "27vw",
          height: "43vw",
        }}
        position={{
          top: "75vw",
          left: "20vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: true,
        }}
      />
      <SingleInfo
        dimensions={{
          width: "27vw",
          height: "43vw",
        }}
        position={{
          top: "75vw",
          left: "47vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: false,
        }}
      />
    </>
  );
}

function get2BRSelectionComponents(url: string) {
  return (
    <>
      <SingleInfo
        dimensions={{
          width: "47vw",
          height: "37.2vw",
        }}
        position={{
          top: "23vw",
          left: "22vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: true,
        }}
      />
      <SingleInfo
        dimensions={{
          width: "47vw",
          height: "37.2vw",
        }}
        position={{
          top: "71.5vw",
          left: "22vw",
        }}
      />
    </>
  );
}

function get3BRSelectionComponents(url: string) {
  return (
    <>
      <SingleInfo
        dimensions={{
          width: "55vw",
          height: "44vw",
        }}
        position={{
          top: "16vw",
          left: "19vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: false,
        }}
      />
      <SingleInfo
        dimensions={{
          width: "55vw",
          height: "44vw",
        }}
        position={{
          top: "76vw",
          left: "19vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: true,
        }}
      />
    </>
  );
}

function get4BRSelectionComponents(url: string) {
  return (
    <>
      <SingleInfo
        dimensions={{
          width: "55vw",
          height: "44vw",
        }}
        position={{
          top: "16vw",
          left: "20vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: true,
        }}
      />
      <SingleInfo
        dimensions={{
          width: "55vw",
          height: "44vw",
        }}
        position={{
          top: "78vw",
          left: "20vw",
        }}
        linkInfo={{
          destinationUrl: url,
          isDisplayed: false,
        }}
      />
    </>
  );
}
