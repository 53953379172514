import { VStack } from "@chakra-ui/react";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

export default function OptionsContainer(props: any) {
  const containerStylesInLandscape = {
    overflow: "hidden",
  };

  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);
  return (
    <VStack
      w="100%"
      h="80%"
      flex="8 8 0%"
      spacing={{
        base: "1vh",
          tablet16: "1vh",
      }}
      {...styles}
    >
      {props.children}
    </VStack>
  );
}
