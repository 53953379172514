import { HStack, VStack } from "@chakra-ui/react";

// Custom Components
import NavButtonOption from "./nav-button-option/NavButtonOption";

//? Icons
import { FaMoneyBillWave } from "react-icons/fa";
import { GrGallery } from "react-icons/gr";
import { BiShare } from "react-icons/bi";
import { ImMap } from "react-icons/im";

import { useDispatch } from "react-redux";
import { showSharing } from "../sharing/sharingSlice";

// Utils
import {
  useChoseStyleOnLandscape,
  useChoseValueOnLandscape,
} from "../../../utils/Selecting";

type Props = {
  isPaymentPage?: boolean;
  isGalleryPage?: boolean;
  isPlanPage?: boolean;
  id?: string | null;
  bedroomsNumber?: string | number;
};

export interface FloorPlateNavOptionsProps extends Props {}

export default function FloorPlateNavOptions(props: FloorPlateNavOptionsProps) {
  const dispatch = useDispatch();

  // Landscape Styles.
  const containerStylesInLandscape = {
    w: {
      "1xsm": "40%",
    },
    h: {
      "1xsm": "80%",
    },
    spacing: {
      "1xsm": "0.5rem",
    },
    alignSelf: {
      "1xsm": "flex-start",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  // Landscape Switched Components
  const StackType = useChoseValueOnLandscape(VStack, HStack);

  return (
    <StackType
      maxW="full"
      w="full"
      h="5%"
      spacing={{ base: "1.7vw", tablet16: "2.7vw" }}
      {...styles}
    >
      <NavButtonOption
        isLinkComp={true}
        buttonName="Plan"
        destinationUrl="floor-plate-unit-page"
        imageIcon={ImMap}
        isActive={props.isPlanPage}
        id={props.id}
        bedroomsNumber={props.bedroomsNumber}
      />
      <NavButtonOption
        isLinkComp={true}
        buttonName="Gallery"
        destinationUrl="floor-plate-unit-gallery-page"
        imageIcon={GrGallery}
        isActive={props.isGalleryPage}
        id={props.id}
        bedroomsNumber={props.bedroomsNumber}
      />
      <NavButtonOption
        isLinkComp={true}
        buttonName="Payment"
        destinationUrl="payment-page"
        imageIcon={FaMoneyBillWave}
        isActive={props.isPaymentPage}
        id={props.id}
        bedroomsNumber={props.bedroomsNumber}
      />
      <NavButtonOption
        isLinkComp={false}
        destinationUrl="payment-page"
        buttonName="Share"
        imageIcon={BiShare}
        clickAction={() => dispatch(showSharing())}
        id={props.id}
        bedroomsNumber={props.bedroomsNumber}
      />
    </StackType>
  );
}
