import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

export interface SectionOneProps {
  display?: string;
  type: string;
}

 const SectionOne = (props: SectionOneProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={0}
        topOffset="22.2%"
        leftOffset="57.4%"
        hasUnits={true}
        plotNumber="144"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={330}
        topOffset="24.2%"
        leftOffset="54.5%"
        hasUnits={true}
        plotNumber="145"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={300}
        topOffset="27.7%"
        leftOffset="52.5%"
        hasUnits={true}
        plotNumber="146"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={112}
        topOffset="32.9%"
        leftOffset="51.5%"
        hasUnits={true}
        plotNumber="147"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={139}
        topOffset="37.8%"
        leftOffset="50.5%"
        hasUnits={true}
        plotNumber="148"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={0}
        topOffset="40.2%"
        leftOffset="48%"
        hasUnits={true}
        plotNumber="149"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={30}
        topOffset="38.1%"
        leftOffset="43.6%"
        hasUnits={true}
        plotNumber="150"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={100}
        topOffset="33.4%"
        leftOffset="42.6%"
        hasUnits={true}
        plotNumber="151"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={100}
        topOffset="28.3%"
        leftOffset="43.1%"
        hasUnits={true}
        plotNumber="152"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={100}
        topOffset="22.7%"
        leftOffset="43.6%"
        hasUnits={true}
        plotNumber="153"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={64}
        topOffset="17.6%"
        leftOffset="43.2%"
        hasUnits={true}
        plotNumber="154"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="2%"
        buttonHeight="4%"
        rotateDegree={40}
        topOffset="13.3%"
        leftOffset="41.4%"
        hasUnits={true}
        plotNumber="155"
        page="/facade/townhouse"
      />

      
    </>
  );
};


export default SectionOne;