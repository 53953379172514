import React, { memo, Suspense } from "react";

import { Center, Spinner } from "@chakra-ui/react";

import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

const OptionsContainer = React.lazy(() => import("./Options/OptionsContainer"));

function HomePage() {
  return (
    <PageContainerComponent>
      <Suspense fallback={<LazyLoadingComp />}>
        <OptionsContainer />
      </Suspense>
    </PageContainerComponent>
  );
}

export default memo(HomePage);

function LazyLoadingComp() {
  return (
    <Center w="full" h="full">
      <Spinner
        sx={{
          "--spinner-size": "15rem",
        }}
        thickness="7px"
        color="red.500"
        emptyColor="gray.200"
        speed="0.85s"
        size="xl"
      />
    </Center>
  );
}
