// Custom Components
import OptionImages from "./OptionImages/OptionImages";
import OptionsButtons from "../project-presentation-page/OptionsButtons";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

import { useChoseStyleOnLandscape } from "../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

export default function ProjectFloorsPlanPage() {
  // Auth before accessing this page
  useVerifyAuth();

  // Landscape Switched Components.
  const StackType = useGetStackTypeElOnPageOrientation();

  const pageHeader = useQueryProjectName();

  // Landscape Styling.
  const subContainerStylesInLandscape = {
    h: {
      "1xsm": "82%",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  // Landscape Styling.
  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <PageContainerComponent
      spacing={{ base: "0", tablet16: "2.5rem" }}
      overflow="hidden"
      {...styles}
    >
      <LocationIndicator text={`Celltek ${pageHeader} Floors Plans`} />
      <StackType
        w="full"
        h="full"
        spacing={{ base: "0.4rem", tablet16: "3rem" }}
        {...subContainerStyles}
      >
        <OptionImages />
        <OptionsButtons isFloorsPlanPage />
      </StackType>
    </PageContainerComponent>
  );
}
