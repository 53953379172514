import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  Button,
} from "@chakra-ui/react";

import { useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { selectClientEmail } from "./sharingSlice";
import { selectDisclaimerVisibility } from "./sharingSlice";
import { hideDisclaimer } from "./sharingSlice";

import axios from "axios";
import useFetchProperty from "hooks/fetching_values/useFetchProperty";
import baseURL from "constants/baseURL";

function Disclaimer() {
  // Client Email
  const clientEmail = useSelector(selectClientEmail);
  // unit id.
  const propertyInfo = useFetchProperty();

  // Element Display
  const isOpen = useSelector(selectDisclaimerVisibility);

  const dispatch = useDispatch();

  const hideElement = () => {
    if (isOpen) return dispatch(hideDisclaimer());
  };

  const cancelRef = useRef(null);

  // Sending Email With Attachments
  const sendTestEmail = async () => {
    if (!isOpen) return;
    try {
      const result = await axios.post(
        `properties/property-inquiry?receiver-email=${clientEmail}&unit-id=${propertyInfo?._id}`
      , {} , {
        baseURL,
      });

      console.debug(result.data);
    } catch (e: any) {
      console.error("Can't Send Email");
    }

    return dispatch(hideDisclaimer());
  };

  return (
    <AlertDialog
      isOpen={!!isOpen}
      leastDestructiveRef={cancelRef}
      onClose={hideElement}
      isCentered={true}
    >
      <AlertDialogContent>
        <AlertDialogHeader>Send Email</AlertDialogHeader>
        <AlertDialogBody>Do you want to send email ?</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={hideElement}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={sendTestEmail} ml={3}>
            Send
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default Disclaimer;
