import { Box } from "@chakra-ui/layout";

import PinAnimatedContainer, {
  PinAnimatedContainerProps,
} from "./PinAnimatedContainer";
import { IconsNames } from "./utils";

export interface PinProps extends PinAnimatedContainerProps {
  lat: number;
  lng: number;
  iconName?: IconsNames;
}

export default function Pin(props: PinProps) {
  return (
    <Box
      w={[
        "20px",
        "20px",
        "12px",
        "20px",
        "20px",
        null,
        null,
        null,
        null,
        "30px",
      ]}
      h={[
        "20px",
        "20px",
        "12px",
        "20px",
        "20px",
        null,
        null,
        null,
        null,
        "30px",
      ]}
      lat={props.lat}
      lng={props.lng}
    >
      <PinAnimatedContainer
        iconName={props.iconName}
        isResidential={props.isResidential}
      />
    </Box>
  );
}
