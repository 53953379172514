import { useMediaQuery } from "@chakra-ui/react";
import { useGetQueryValue, useQuery, useThemeValueBool } from "./Values";

export function useChoseOnProjectName(
  projectName: string,
  chosenValue: any,
  defaultValue: any
) {
  const project = useQuery().get("p");
  const isProjectExist = project && project.toLowerCase().match(projectName);
  return isProjectExist ? chosenValue : defaultValue;
}

export function useChoseOnQueryValue(
  query: string,
  value: string,
  whenTrue: any,
  whenFalse: any
) {
  const query_val = useGetQueryValue(query);
  return query_val === value ? whenTrue : whenFalse;
}

export function useChoseOnLightMode(chosenValue: any, defaultValue: any) {
  const isLightTheme = useThemeValueBool();

  return isLightTheme ? chosenValue : defaultValue;
}

export function useChoseOnFlag(
  flag: boolean | undefined,
  whenTrue: any,
  whenFalse: any
) {
  return flag ? whenTrue : whenFalse;
}

export function useChoseStyleOnLandscape(styleWhenInLandscape: any): any {
  const [isLandscape] = useMediaQuery(["(orientation: landscape)"]);

  return isLandscape ? styleWhenInLandscape : {};
}

export function useChoseValueOnLandscape(valueInLandscape: any, otherValue:any): any {
  const [isLandscape] = useMediaQuery(["(orientation: landscape)"]);

  return isLandscape ? valueInLandscape : otherValue;
}