import { HStack, VStack } from "@chakra-ui/react";
import {
  useChoseStyleOnLandscape,
  useChoseValueOnLandscape,
} from "../../utils/Selecting";

import DeliveryYearOption from "./delivery-year-option/DeliveryYearOption";

export default function TwoOptionsInRow({
  firstYearOption = "Ready to move",
  secondYearOption = "Ready to move",
}) {
  // Landscape Styling.
  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "0.6rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  const VStackOnLandscape = useChoseValueOnLandscape(VStack, HStack);

  return (
    <VStackOnLandscape
      w="100%"
      h="40%"
      spacing={["0.8rem", "0.8rem", "0.8rem", "0.8rem", "0.8rem", "2rem"]}
      {...styles}
    >
      <DeliveryYearOption availableYear={firstYearOption} />
      <DeliveryYearOption availableYear={secondYearOption} />
    </VStackOnLandscape>
  );
}
