import { memo } from "react";

// Custom Components
import HeroImg from "./hero-img/HeroImg";
import Header from "../common/header/Header";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import FloorPlateNavOptions from "../common/floor-plate-nav-options/FloorPlateNavOptions";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
// import useSingleFetcher from "../../hooks/useSingleFetcher";
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";
import useFetchProperty from "hooks/fetching_values/useFetchProperty";
// import useQueryPlotNumber from "hooks/query_values/useQueryPlotNumber";

import { useQuery } from "../../utils/Values";
import { useGetQueryData } from "../../utils/Navigation";
import { useChoseStyleOnLandscape } from "../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";
import useQueryProjectName, { ProjectNames } from "hooks/query_values/useQueryProjectName";

// Utils

// Interfaces
// import Property from "../../interfaces/property";

// Enums

function FloorPlateUnitPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const { pageHeader } = useGetQueryData();

  // Landscape Styles.
  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  const subContainerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
    minH: {
      "1xsm": "75%",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  // Landscape Switched Components
  const StackType = useGetStackTypeElOnPageOrientation();

  // Url Navigation.
  const query = useQuery();
  const zoneNumber = query.get("z") || "1";
  const projectName = useQueryProjectName();
  const url = `project-zone-page?z=${zoneNumber}&p=${projectName}`;
  const zayedUrl = `property-display-page?p=zayed&type=Residential`;
  

  const propertyInfo = useFetchProperty();

  return (
    <PageContainerComponent
      spacing={{ base: "0rem", tablet16: "2rem" }}
      overflow="hidden"
      h={["100%", "100%", "100%", "100%"]}
      {...styles}
    >
      <Header
        formData={{
          Price: propertyInfo?.Price,
          projectName: projectName,
          plotArea: propertyInfo?.Area,
          unitName: propertyInfo?.["Unit Type"],
          unitTextInfo: propertyInfo?.Description,
          id: propertyInfo?._id,
        }}
      />
      <LocationIndicator
        pageUrl={projectName === ProjectNames.ZAYED ? zayedUrl : url}
        text={propertyInfo ? propertyInfo.Description : pageHeader}
      />
      <StackType
        w="100%"
        minH="75%"
        spacing={{ base: "2vh", tablet16: "3rem" }}
        {...subContainerStyles}
      >
        <HeroImg
          bedroomsNumber={propertyInfo ? +propertyInfo.Bedroom : undefined}
        />
        <FloorPlateNavOptions
          bedroomsNumber={propertyInfo?.Bedroom}
          id={propertyInfo?._id}
          isPlanPage={true}
        />
      </StackType>
    </PageContainerComponent>
  );
}

export default memo(FloorPlateUnitPage);
