import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";
import { useQuery } from "../../../utils/Values";

import { Location } from "../../project-facilities-page/hero-image/project-pins/utils";

type Props = {
  location: Location;
  zoneName: string;
  disable: boolean;
};

export default function Zone(props: Props) {
  const projectName = useQuery().get("p") || "new cairo";
  const destinationUrl = `project-zone-page?z=${props.zoneName}&p=${projectName}`;

  // Landscape Styles
  const containerStylesInLandscape = {
    w: {
      "1xsm": "20%",
    },
    h: {
      "1xsm": "14%",
    },
    fontSize: {
      "1xsm": "1rem",
    },
    borderRadius: {
      "1xsm": "0.5rem",
    },
    borderWidth: {
      "1xsm": "thin",
    },
    p: {
      "1xsm": "0px",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Button
      as={props.disable ? undefined : Link}
      to={destinationUrl}
      position="absolute"
      top={props.location.top}
      left={props.location.left}
      fontSize={{
        base: "1rem",
        mobile8: "1.5rem",
        tablet13: "2.5rem",
        tablet16: "3rem",
      }}
      p={["0.8rem", "0.8rem", "0.8rem", "0.8rem", "0.8rem", "1rem"]}
      w="30%"
      h={["10%", null, null, null, null, null, null, null, "14%", "10%"]}
      bg="green.100"
      borderColor="red.500"
      borderWidth={{ base: "thin", mobile13: "2px", tablet16: "medium" }}
      color="red.500"
      borderRadius={{ base: "7px", mobile13: "0.5rem", tablet16: "1rem" }}
      disabled={props.disable}
      _disabled={{
        bg: "gray.300",
        opacity: 0.5,
      }}
      {...styles}
    >
      Zone {props.zoneName}
    </Button>
  );
}
