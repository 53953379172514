import { VStack, Box } from "@chakra-ui/react";

// Custom Components
import Img from "./Img";
import FloorInfo from "./FloorInfo";
import PageContentContainer from "./PageContentContainer";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

import { useGetQueryValue } from "../../utils/Values";
import { useChoseStyleOnLandscape } from "../../utils/Selecting";

import useQueryProjectName, {
  ProjectNames,
} from "hooks/query_values/useQueryProjectName";
import useQueryFloorNumber from "hooks/query_values/useQueryFloorNumber";

export default function PropertyFloorPlate() {
  // Auth before accessing this page
  useVerifyAuth();

  const projectName = useQueryProjectName();
  const floorNumber = useQueryFloorNumber();

  const displayedProjectName =
    projectName === ProjectNames.ZAYED ? "Floor "+floorNumber +"R": "";


  const bedRoomsNumber = useGetQueryValue("bd") || "3";

  const pageHeader = `${displayedProjectName} ${bedRoomsNumber} BedRooms`;

  // Landscape Styles.
  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Box w="100%" h="90%">
      <VStack h="100%" spacing={{ base: "0", tablet16: "0.5vh" }} {...styles}>
        <LocationIndicator text={pageHeader} />
        {/* Hero Image */}
        <PageContentContainer units={null}>
          <Img />
          <FloorInfo />
        </PageContentContainer>
      </VStack>
    </Box>
  );
}
