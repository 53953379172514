import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useChoseOnProjectName } from "../../utils/Selecting";

import {
  selectMenuAnimation,
  stopMenuAnimation,
} from "../common/NavigationMenu/NavigationMenuSlice";

import NewCairoHeroImage from "./new-cairo-hero-image/NewCairoHeroImage";
import ZayedHeroImage from "./zayed-hero-image/ZayedHeroImage";

export default function HeroImage() {
  const isMenuShown = useSelector(selectMenuAnimation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMenuShown) dispatch(stopMenuAnimation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useChoseOnProjectName(
    "zayed",
    <ZayedHeroImage />,
    <NewCairoHeroImage />
  );
}
