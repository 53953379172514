import { SectionOneProps } from "./SectionOne";

import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

const SectionFive = (props: SectionOneProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={67}
        topOffset="51.8%"
        leftOffset="33.6%"
        hasUnits={true}
        plotNumber="279"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={92}
        topOffset="55.2%"
        leftOffset="33.9%"
        hasUnits={true}
        plotNumber="280"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={98}
        topOffset="59.0%"
        leftOffset="33.9%"
        hasUnits={true}
        plotNumber="281"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={98}
        topOffset="62.5%"
        leftOffset="33.5%"
        hasUnits={true}
        plotNumber="282"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={68}
        topOffset="66.3%"
        leftOffset="33.7%"
        hasUnits={true}
        plotNumber="283"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={68}
        topOffset="69.6%"
        leftOffset="34.3%"
        hasUnits={true}
        plotNumber="284"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={59}
        topOffset="73.6%"
        leftOffset="35.3%"
        hasUnits={true}
        plotNumber="285"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.4%"
        buttonHeight="3.4%"
        rotateDegree={15}
        topOffset="76.3%"
        leftOffset="37.2%"
        hasUnits={true}
        plotNumber="286"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.4%"
        buttonHeight="3.4%"
        rotateDegree={15}
        topOffset="77.1%"
        leftOffset="39.7%"
        hasUnits={true}
        plotNumber="287"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.4%"
        buttonHeight="3.4%"
        rotateDegree={322}
        topOffset="75.7%"
        leftOffset="42.3%"
        hasUnits={true}
        plotNumber="288"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.4%"
        buttonHeight="3.4%"
        rotateDegree={303}
        topOffset="72.7%"
        leftOffset="43.9%"
        hasUnits={true}
        plotNumber="289"
        page="/facade/townhouse"
      />
    </>
  );
};

export default SectionFive;
