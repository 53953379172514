import Txt from "./Txt";
import Container from "./Container";
import AllBuildings from "../buildings-option/AllBuildings";

type Props = {
  unitOne: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  unitTwo: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  unitThree: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  unitFour: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
};

export default function OptionsContainer(props: Props) {
  return (
    <Container>
      <Txt />
      <AllBuildings {...props} />
    </Container>
  );
}
