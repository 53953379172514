import { useState } from "react";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

import { useChoseStyleOnLandscape } from "../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";

// Custom Components
import SelectedHeroImage from "./SelectedHeroImage";
import OptionsButtons from "./options-buttons/OptionsButtons";
import NavigationMenu from "../common/NavigationMenu/NavigationMenu";
import MenuToggleButton from "../common/MenuToggleButton/MenuToggleButton";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

export default function ProjectLocationPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const projectName = useQueryProjectName()
  
  // Landscape Switched Components.
  const StackType = useGetStackTypeElOnPageOrientation();

  // Landscape Styling.
  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  // Landscape Styling.
  const subContainerStylesInLandscape = {
    h: {
      "1xsm": "80%",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  // Render Menu Only when the component gets Rendered.
  const [isElementReady, setIsElementReady] = useState(false);

  return (
    <PageContainerComponent
      spacing={{
        base: "0rem",
        lg: "1rem",
        xl: "3rem",
      }}
      overflow={"hidden"}
      {...styles}
    >
      <LocationIndicator text={`Celltek ${projectName} Location`} />
      <StackType
        w="full"
        h="89%"
        spacing={{
          base: "0.5rem",
          xsm: "1.5rem",
          ipadPro: "3rem",
        }}
        onClick={() => setIsElementReady(true)}
        {...subContainerStyles}
      >
        <SelectedHeroImage />
        <OptionsButtons />
        <MenuToggleButton />
        {isElementReady && <NavigationMenu />}
      </StackType>
    </PageContainerComponent>
  );
}
