import { AspectRatio, Center } from "@chakra-ui/react";

// Custom Hooks.
import {
  useChoseStyleOnLandscape,
  // useChoseOnProjectName,
} from "../../../utils/Selecting";

// Video Sources.
// import zayedVideoSource from "../../../video/Video_3D animation/Tower.mp4";
// import newCairoVideoSource from "../../../video/Video_3D animation/New_Cairo-Optional.mp4";

export default function VideoComp() {
  const containerStylesInLandscape = {
    h: {
      "1xsm": "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  // const videoSource = useChoseOnProjectName(
  //   "zayed",
  //   zayedVideoSource,
  //   newCairoVideoSource
  // );

  return (
    <AspectRatio w="full" h="50%" {...styles}>
      <Center w="full" h="full" borderRadius="2rem">
        <iframe 
        src="https://player.vimeo.com/video/724039389?h=c0badaf208&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
        frameBorder="0" 
        allow="autoplay; fullscreen; picture-in-picture" 
        allowFullScreen
        width="100%" height="100%" 
        title="New_Cairo-Optional.mp4" />
        <script src="https://player.vimeo.com/api/player.js"></script>
      </Center>
    </AspectRatio>
  );
}
