import React, { Suspense } from "react";

import { Route } from "react-router-dom";

import { Center, Spinner } from "@chakra-ui/react";

//? Pages
import TestingComp from "./components/testing-comp/TestingComp";
import FloorPlateUnitGalleryPage from "./components/floor-plate-unit-gallery-page/FloorPlateUnitGalleryPage";
import FacilityTypeGalleryPage from "./components/facility-type-gallery-page/FacilityTypeGalleryPage";
import ProjectWithPropertyType from "./components/project-with-property-type/ProjectWithPropertyType";
import ProjectPresentationPage from "./components/project-presentation-page/ProjectPresentationPage";
import ProjectMasterPlanePage from "./components/project-master-plane-page/ProjectMasterPlanePage";
import ProjectFloorsPlanPage from "./components/project-floors-plan-page/ProjectFloorsPlanPage";
import ProjectFacilitiesPage from "./components/project-facilities-page/ProjectFacilitiesPage";
import ProjectLocationPage from "./components/project-location-page/ProjectLocationPage";
import PropertyDisplayPage from "./components/property-display-page/PropertyDisplayPage";
import PropertyFloorPlate from "./components/property-floor-plate/PropertyFloorPlate";
import ProjectGalleryPage from "./components/project-gallery-page/ProjectGalleryPage";
import FloorPlateUnitPage from "./components/floor-plate-unit-page/FloorPlateUnitPage";
import PropertyTypesPage from "./components/property-types-page/PropertyTypesPage";
import DeliveryDatePage from "./components/delivery-date-page/DeliveryDatePage";
import DeliveryYearPage from "./components/delivery-year-page/DeliveryYearPage";
import ProjectZonePage from "./components/project-zone-page/ProjectZonePage";
import TownhousePlatePage from "pages/PlatesPages/TownhousePlatePage";
import ProjectsPage from "./components/projects-page/ProjectsPage";
import LocationPage from "./components/location-page/LocationPage";
import SharingComp from "./components/common/sharing/SharingComp";
import FloorUnitPlateInfo from "./components/FloorUnitPlateInfo";
import HomePage from "./components/home-page/HomePage";
import AppContainer from "./AppContainer";

// Separated Routes.
import Facade from "./routes/facade";

// Lazy-loading components

const Header = React.lazy(() => import("./components/common/header/Header"));

const VillaPage = React.lazy(() => import("./components/villa-page/VillaPage"));

const PaymentPage = React.lazy(
  () => import("./components/payment-page/PaymentPage")
);

function App() {
  return (
    <AppContainer>
      <Route path="/floor-plate-unit-gallery-page">
        <FloorPlateUnitGalleryPage />
        <SharingComp />
      </Route>
      <Route path="/project-with-property-type">
        <Header />
        <ProjectWithPropertyType />
      </Route>
      <Route path="/facility-type-gallery-page">
        <Header />
        <FacilityTypeGalleryPage />
      </Route>
      <Route path="/project-presentation-page">
        <Header />
        <ProjectPresentationPage />
      </Route>
      <Route path="/project-master-plane-page">
        <Header />
        <ProjectMasterPlanePage />
      </Route>
      <Route path="/project-floors-plan-page">
        <Header />
        <ProjectFloorsPlanPage />
        <SharingComp />
      </Route>
      <Route path="/project-facilities-page">
        <Header />
        <ProjectFacilitiesPage />
      </Route>
      <Route path="/property-display-page">
        <Header />
        <PropertyDisplayPage />
      </Route>
      <Route path="/floor-plate-unit-page">
        <FloorPlateUnitPage />
        <SharingComp />
      </Route>
      <Route path="/project-location-page">
        <Header />
        <ProjectLocationPage />
      </Route>
      <Route path="/property-floor-plate">
        <Header />
        <PropertyFloorPlate />
        <SharingComp />
      </Route>
      <Route path="/project-gallery-page">
        <Header />
        <ProjectGalleryPage />
      </Route>
      <Route path="/property-types-page">
        <Header />
        <PropertyTypesPage />
      </Route>
      <Route path="/delivery-date-page">
        <Header />
        <DeliveryDatePage />
      </Route>
      <Route path="/delivery-year-page">
        <Header />
        <DeliveryYearPage />
      </Route>
      <Route path="/townhouse-plate-page">
        <TownhousePlatePage />
      </Route>
      <Route path="/project-zone-page">
        <Header />
        <ProjectZonePage />
      </Route>
      <Route path="/projects-page">
        <Header />
        <ProjectsPage />
      </Route>
      <Route path="/floor-unit-plate-info">
        <Header />
        <FloorUnitPlateInfo />
      </Route>
      <Route path="/location-page">
        <Header />
        <LocationPage />
      </Route>
      <Route path="/villa-page">
        <Header />
        <Suspense fallback={<LazyLoadingComp />}>
          <VillaPage />
        </Suspense>
      </Route>
      <Route path="/testing-comp">
        <Header />
        <TestingComp />
      </Route>
      <Route path="/facade">
        <Header />
        <Facade />
      </Route>
      <Route path="/payment-page">
        <Suspense fallback={<LazyLoadingComp />}>
          <PaymentPage />
        </Suspense>
        <SharingComp />
      </Route>
      <Route path="/">
        <Header />
        <HomePage />
      </Route>
    </AppContainer>
  );
}

export default App;

function LazyLoadingComp() {
  return (
    <Center w="full" h="full">
      <Spinner
        sx={{
          "--spinner-size": "15rem",
        }}
        thickness="7px"
        color="red.500"
        emptyColor="gray.200"
        speed="0.85s"
        size="xl"
      />
    </Center>
  );
}
