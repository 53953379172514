import {VStack} from "@chakra-ui/react";

import {memo} from "react";

import {useChoseStyleOnLandscape} from "../../utils/Selecting";

type Props = {
    children: JSX.Element | JSX.Element[];
    units: null | (JSX.Element | null);
};

function PageContentContainer(props: Props) {
    const containerStylesInLandscape = {
        h: {
            "1xsm": "85%",
        },
        spacing: "0rem",
    };
    const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

    return (
        <VStack
            position="relative"
            w="100%"
            h="88%"
            spacing={{base: "1vh", miniMobile10: "1.5vh", mobile8: "1.5vh", tablet16: "1.5vh"}}
            {...styles}
        >
            {props.children}
            {props.units}
        </VStack>
    );
}

export default memo(PageContentContainer);
