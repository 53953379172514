import {Box, VStack} from "@chakra-ui/react";

// Stylings
import externalStyles from "./ZayedHeroImage.module.css";

import Map from "../map/Map";

export default function ZayedHeroImage() {
    return (
        <VStack className={externalStyles.zayed_hero_image} w="100%" h={{base: "75%", mobile8: "79%"}} flex={{base: 20}} spacing="2rem">
            <Box w="100%" h="100%" p="0.1rem" overflow="hidden">
                <Box
                    w="100%"
                    h="100%"
                    borderRadius={{base: "1rem", miniMobile10: "1rem", mobile8: "2rem", tablet16: "4rem"}}
                    borderWidth={{base: "2px", mobile8: "3px", tablet16: "5px"}}
                    borderColor="red.500"
                    p="0.1rem"
                    overflow="hidden"
                    position="relative"
                >
                    <Map/>
                </Box>
            </Box>
        </VStack>
    );
}
