// Custom Components
import OptionsRow from "./OptionsRow";
import BuildingOption from "./BuildingOption";
import OptionsContainer from "./OptionsContainer";

//? Image Sources
// Zone 1
import PropertyTypeDuplexImg from "../../../img/buildings/Property-Type-Duplex.jpg";
import PropertyTypeApartmentImg from "../../../img/buildings/Property-Type-Apartment.jpg";
import PropertyTypeTownhouseImg from "../../../img/buildings/Property-Type-Twonhouse.jpg";
import PropertyTypePenthouseImg from "../../../img/buildings/Property-Type-Penthouse.jpg";

// Zone 2
import Villa5Bedrooms from "../../../img/buildings/new_cairo/5BR_Villa/Main_Image/SB65_Ext_5B V_A.jpg";
import Villa4Bedrooms from "../../../img/buildings/new_cairo/4BR_Villa/Main_Image/4BR_Villa_STREET VIEW.jpg";
import Villa2Bedrooms from "../../../img/buildings/new_cairo/3BR_Villa_Type_A/Main_Image/3BR_VILLA_Type_A_STREET VIEW.jpg";
import Villa3Bedrooms from "../../../img/buildings/new_cairo/3BR_Villa_Type_B/Main_Image/3BR_VILLA_Type_B_STREET VIEW.jpg";

type Props = {
  unitOne: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  unitTwo: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  unitThree: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  unitFour: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
};

export default function AllBuildings(props: Props) {
  return (
    <OptionsContainer>
      <OptionsRow>
        <BuildingOption
          setDisplayState={props.unitOne.toggle}
          disable1={props.unitTwo.off}
          disable2={props.unitThree.off}
          disable3={props.unitFour.off}
          buildingNames={{
            apartments: "Apartment",
            villa: "3 Bedroom A",
          }}
          images={{
            buildingImage: PropertyTypeApartmentImg,
            villaImage: Villa2Bedrooms,
          }}
        />
        <BuildingOption
          setDisplayState={props.unitTwo.toggle}
          disable1={props.unitOne.off}
          disable2={props.unitThree.off}
          disable3={props.unitFour.off}
          buildingNames={{
            apartments: "Penthouse",
            villa: "3 Bedroom B",
          }}
          images={{
            buildingImage: PropertyTypePenthouseImg,
            villaImage: Villa3Bedrooms,
          }}
        />
      </OptionsRow>

      <OptionsRow>
        <BuildingOption
          setDisplayState={props.unitThree.toggle}
          disable1={props.unitOne.off}
          disable2={props.unitTwo.off}
          disable3={props.unitFour.off}
          buildingNames={{
            apartments: "Duplex",
            villa: "4 Bedroom",
          }}
          images={{
            buildingImage: PropertyTypeDuplexImg,
            villaImage: Villa4Bedrooms,
          }}
        />

        <BuildingOption
          setDisplayState={props.unitFour.toggle}
          disable1={props.unitOne.off}
          disable2={props.unitTwo.off}
          disable3={props.unitThree.off}
          buildingNames={{
            apartments: "Townhouse",
            villa: "5 Bedroom",
          }}
          images={{
            buildingImage: PropertyTypeTownhouseImg,
            villaImage: Villa5Bedrooms,
          }}
        />
      </OptionsRow>
    </OptionsContainer>
  );
}
