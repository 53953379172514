// Custom Components
import ProjectsContainer from "./ProjectsContainer";
import ProjectsDisplayRow from "./ProjectsDisplayRow";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import ProjectComponent from "./project-component/ProjectComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";
import { useChoseStyleOnLandscape } from "../../utils/Selecting";

// Images
import CelltekNewCairo from "../../img/projects_page/new-cairo.jpg";
import CelltekSharmBuildings from "../../img/projects_page/sharm.jpg";
import CelltekZayedBuildings from "../../img/projects_page/zayed.png";
import CelltekGounaBuildings from "../../img/projects_page/gouna.jpg";
import CelltekShroukBuildings from "../../img/projects_page/shrouk.jpg";
import CelltekOctoberBuildings from "../../img/projects_page/october.jpg";
import CelltekKatameyaBuildings from "../../img/projects_page/katameya.png";
import CelltekDowntownBuildings from "../../img/projects_page/downtown.jpg";
import CelltekMokattemBuildings from "../../img/projects_page/mokattam.jpg";
import CelltekObourBuildings from "../../img/projects_page/cairo-new-capital.jpg";

export default function ProjectsPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const containerStylesInLandscape = {
    overflow: "hidden",
    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <PageContainerComponent {...styles}>
      <LocationIndicator text="Projects" />
      <ProjectsContainer>
        <ProjectsDisplayRow>
          <ProjectComponent
            projectName="Celltek New Cairo"
            image={CelltekNewCairo}
          />
          <ProjectComponent
            projectName="Celltek Zayed"
            image={CelltekZayedBuildings}
            urlQuery="p=zayed&title=Celltek Zayed Project"
          />
        </ProjectsDisplayRow>
        <ProjectsDisplayRow>
          <ProjectComponent
            projectName="Celltek Downtown"
            image={CelltekDowntownBuildings}
          />

          <ProjectComponent
            projectName="Celltek October"
            image={CelltekOctoberBuildings}
          />
        </ProjectsDisplayRow>
        <ProjectsDisplayRow>
          <ProjectComponent
            projectName="Celltek Katamya"
            image={CelltekKatameyaBuildings}
          />
          <ProjectComponent
            projectName="Celltek Mokattam"
            image={CelltekMokattemBuildings}
          />
        </ProjectsDisplayRow>
        <ProjectsDisplayRow>
          <ProjectComponent
            projectName="Celltek Shrouk"
            image={CelltekShroukBuildings}
          />
          <ProjectComponent
            projectName="Celltek New Capital"
            image={CelltekObourBuildings}
          />
        </ProjectsDisplayRow>
        <ProjectsDisplayRow>
          <ProjectComponent
            projectName="Celltek Sharm"
            image={CelltekSharmBuildings}
          />
          <ProjectComponent
            projectName="Celltek Gouna"
            image={CelltekGounaBuildings}
          />
        </ProjectsDisplayRow>
      </ProjectsContainer>
    </PageContainerComponent>
  );
}
