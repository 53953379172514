import Property, { PropertyTypes } from "../../../interfaces/property";
import useFetcher from "../../../hooks/useFetcher";
import { NavButtonVillas } from "../NavButtons/NavButton";
import OptionButtonsContainer from "./FirstOptionButtons/OptionButtonsContainer";

export function PenthouseOption(props: { displayed: boolean }) {
  const display = props.displayed ? undefined : "none";
  const type = PropertyTypes.PENTHOUSE;

  const properties = useFetcher<Property>("/properties/penthouse/30");

  return (
    <>
      <OptionButtonsContainer
        hasUnits
        propertyUrl="/facade/penthouse"
        display={display}
        type={type}
        properties={properties}
      />
    </>
  );
}

export function ThreeBedroomVillaOption(props: { displayed: boolean }) {
  const display = props.displayed ? undefined : "none";
  const type = PropertyTypes.VILLA;
  const bedroom = 3;

  return (
    <>
      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="53%"
        leftOffset="33.9%"
        buttonWidth="3%"
        buttonHeight="6%"
        rotateDegree={19}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="63.5%"
        leftOffset="30.2%"
        buttonWidth="3%"
        buttonHeight="7%"
        rotateDegree={22}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="68.9%"
        leftOffset="36.9%"
        buttonWidth="3%"
        buttonHeight="7%"
        rotateDegree={22}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="74.4%"
        leftOffset="42.9%"
        buttonWidth="3%"
        buttonHeight="2.5rem"
        rotateDegree={22}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="79.2%"
        leftOffset="49.4%"
        buttonWidth="3%"
        buttonHeight="2.5rem"
        rotateDegree={22}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="80%"
        leftOffset="56.3%"
        buttonWidth="3%"
        buttonHeight="2.5rem"
        rotateDegree={0}
        hasUnits
        plotNumber="360"
      />
    </>
  );
}
