import { Image } from "@chakra-ui/react";

import { memo } from "react";

// Hooks.
import useQueryBedroomNumbers from "hooks/query_values/useQueryBedroomNumbers";

// Images.
import BR1Img from "img/zayed/residential/floors/Z_R_1_Bedroom.jpg";
import BR2Img from "img/zayed/residential/floors/Z_R_2_Bedroom.jpg";
import BR3Img from "img/zayed/residential/floors/Z_R_3_Bedroom.jpg";
import BR4Img from "img/zayed/residential/floors/Z_R_4_Bedroom.jpg";

function Img() {
  const bedroomsNumber = useQueryBedroomNumbers();

  const unitZoneImage = choseImageBasedOnBedrooms(bedroomsNumber);

  return (
    <Image
      w="100%"
      h="100%"
      borderRadius={["1rem", "1rem", "2rem", "2rem"]}
      borderWidth="medium"
      borderColor="red.500"
      src={unitZoneImage}
    />
  );
}

export default memo(Img);

function choseImageBasedOnBedrooms(bedroomsNumber: number) {
  switch (bedroomsNumber) {
    case 1:
      return BR1Img;
    case 2:
      return BR2Img;

    case 3:
      return BR3Img;

    case 4:
      return BR4Img;

    default:
      return BR1Img;
  }
}
