import { VStack } from "@chakra-ui/react";

import Img from "./Img";
import Txt from "./Txt";
import ButtonsOnTheMap from "./ButtonsOnTheMap";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

type Props = {
  firstBuildingVisibility: boolean;
  secondBuildingVisibility: boolean;
  thirdBuildingVisibility: boolean;
  forthBuildingVisibility: boolean;
};

export default function HeroImage(props: Props) {
  const containerStylesInLandscape = {
    h: {
      "1xsm": "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);
  return (
    <VStack
      w="100%"
      h="45%"
      flex={1}
      spacing={0}
      {...styles}
    >
      <Txt />
      <Img>
        <ButtonsOnTheMap {...props} />
      </Img>
    </VStack>
  );
}
