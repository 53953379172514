import { useRef } from "react";

import { Select } from "@chakra-ui/react";

import InputEl from "./InputEl";

export default function EmailInput() {
  const emailTypeSelectElement = useRef<HTMLSelectElement>(null);

  return (
    <>
      <InputEl selectorRef={emailTypeSelectElement} />
      <Select
        ref={emailTypeSelectElement}
        w="30%"
        h="5vh"
        fontSize="1rem"
        defaultValue="@gmail.com"
      >
        <option value="@gmail.com">@gmail.com</option>
        <option value="@hotmail.com">@hotmail.com</option>
        <option value="@yahoo.com">@yahoo.com</option>
        <option value="">custom</option>
      </Select>
    </>
  );
}
