import { useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { stopIconAnimation } from "./PageInfo/page-info-in-boxs/InfoBox/InfoBoxSlice";
import { selectIconAnimationState } from "./PageInfo/page-info-in-boxs/InfoBox/InfoBoxSlice";

// Custom Components
import PageInfo from "./PageInfo/PageInfo";
import OptionsButtons from "./OptionsButtons";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";

export default function ProjectPresentationPage() {
  // Auth before accessing this page
  useVerifyAuth();

  const StackType = useGetStackTypeElOnPageOrientation();

  const pageHeader = useQueryProjectName();

  const isBoxesAnimation = useSelector(selectIconAnimationState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isBoxesAnimation) dispatch(stopIconAnimation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainerComponent
      overflow="hidden"
      spacing={{
        base: "unset",
        sm: "1.2rem",
        lg: "unset",
      }}
      h={{
        base: "90%",
      }}
    >
      <LocationIndicator
        pageUrl="/projects-page"
        text={`Celltek ${pageHeader}`}
      />
      <StackType overflow="hidden" w="full" h={{ base: "90%" }}>
        <PageInfo />
        <OptionsButtons />
      </StackType>
    </PageContainerComponent>
  );
}
