import BtnContainer from "./BtnContainer";

import Container from "./Container";
import Img from "./Img";

type ProjectCompProps = {
  projectName: string;
  urlQuery?: string;
  image: string;
};

export default function ProjectComp(props: ProjectCompProps) {
  const query = props.urlQuery || "";

  return (
    <Container urlQuery={query}>
      <Img image={props.image} />
      <BtnContainer txt={props.projectName} />
    </Container>
  );
}
