import { Box } from "@chakra-ui/react";

import {
  useChoseOnLightMode,
  useChoseStyleOnLandscape,
} from "../../../utils/Selecting";

import HeaderIcon from "./HeaderIcon";

export default function IconContainer() {
  const buttonBorderStyle = useChoseOnLightMode("blue.700", "red.500");
  const buttonDarkModeStyle = useChoseOnLightMode("blue.700", "white");

  // Landscape Styling.
  const containerStylesInLandscape = {
    h: {
      mobile14: "100%",
      mobile15: "100%",
      tablet1: "100%",
      tablet4: "5vw",
      tablet6: "100%",
      desktop8: "80%",
    },
    w: {
      mobile14: "6%",
      mobile15: "6%",
      tablet1: "6%",
      tablet4: "6vw",
      tablet6: "6%",
    },
    borderRadius: {
      base: "0.5rem",
      mobile12: "0.7rem",
      mobile16: "0.9rem",
      tablet1: "0.5rem",
      tablet6: "0.5rem",
      tablet16: "1rem",
    },
    p: {
      base: "0.3rem",
      xsMobile2: "1vh",
      miniMobile6: "0.5rem",
      miniMobile8: "0.4rem",
      miniMobile9: "0.5rem",
      miniMobile10: "0.5rem",
      miniMobile11: "0.5rem",
      miniMobile12: "0.7rem",
      mobile1: "0.6rem",
      mobile4: "0.4rem",
      mobile5: "0.5rem",
      mobile6: "0.5rem",
      mobile7: "0.4rem",
      mobile8: "0.8rem",
      mobile9: "0.7rem",
      mobile10: "0.5rem",
      mobile11: "0.4rem",
      mobile12: "0.7rem",
      mobile14: "0.7rem",
      mobile15: "0.5rem",
      mobile16: "0.8rem",
      tablet1: "0.5rem",
      tablet2: "0.4rem",
      tablet3: "0.5rem",
      tablet4: "0.5rem",
      tablet7: "0.6rem",
      tablet9: "1rem",
      tablet10: "0.6rem",
      tablet13: "0.9rem",
      tablet16: "1rem",
      tablet19: "0.8rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Box
      h={{
        base: "10vw",
        xsMobile2: "1vh",
        miniMobile1: "15vw",
        miniMobile6: "60%",
        miniMobile11: "6vh",
        miniMobile12: "60%",
        mobile1: "10vw",
        mobile4: "100%",
        mobile5: "60%",
        mobile6: "100%",
        mobile7: "5vw",
        mobile8: "68%",
        mobile9: "10vw",
        mobile10: "5vw",
        mobile11: "100%",
        mobile12: "10vw",
        mobile14: "5vw",
        mobile15: "5vw",
        mobile16: "75%",
        tablet1: "10vh",
        tablet2: "60%",
        tablet3: "70%",
        tablet4: "4vw",
        tablet7: "80%",
        tablet9: "70%",
        tablet10: "5vw",
        tablet13: "6vw",
        tablet16: "9vw",
        tablet19: "5vw",
        desktop5: "90%",
      }}
      w={{
        base: "15vw",
        xsMobile2: "1vw",
        miniMobile6: "16%",
        miniMobile8: "13vw",
        miniMobile10: "13vw",
        miniMobile11: "10vw",
        mobile1: "11vw",
        mobile4: "8%",
        mobile5: "12%",
        mobile6: "7%",
        mobile7: "6vw",
        mobile8: "12%",
        mobile9: "10vw",
        mobile10: "7vw",
        mobile11: "8%",
        mobile12: "10vw",
        mobile14: "6vw",
        mobile15: "6vw",
        mobile16: "13%",
        tablet1: "6vw",
        tablet2: "12%",
        tablet3: "10%",
        tablet4: "5vw",
        tablet7: "8%",
        tablet9: "12%",
        tablet10: "6vw",
        tablet16: "11vw",
        tablet19: "6vw",
        desktop5: "8%",
      }}
      p={{
        base: "0.3rem",
        xsMobile2: "1vh",
        miniMobile6: "0.5rem",
        miniMobile8: "0.4rem",
        miniMobile9: "0.5rem",
        miniMobile10: "0.5rem",
        miniMobile11: "0.5rem",
        miniMobile12: "0.7rem",
        mobile1: "0.6rem",
        mobile4: "0.4rem",
        mobile5: "0.5rem",
        mobile6: "0.5rem",
        mobile7: "0.4rem",
        mobile8: "0.8rem",
        mobile9: "0.7rem",
        mobile10: "0.5rem",
        mobile11: "0.4rem",
        mobile12: "0.7rem",
        mobile14: "0.7rem",
        mobile15: "0.5rem",
        mobile16: "0.8rem",
        tablet1: "0.5rem",
        tablet2: "1rem",
        tablet3: "0.9rem",
        tablet4: "0.5rem",
        tablet7: "0.6rem",
        tablet9: "1rem",
        tablet10: "0.6rem",
        tablet13: "0.9rem",
        tablet16: "1rem",
        tablet19: "0.8rem",
      }}
      bg={buttonDarkModeStyle}
      borderColor={buttonBorderStyle}
      borderRadius={{
        base: "0.5rem",
        mobile12: "0.7rem",
        mobile16: "0.9rem",
        lg: "1rem",
      }}
      borderWidth="1px"
      {...styles}
    >
      <HeaderIcon />
    </Box>
  );
}
