// Custom Hooks.
import useQueryProjectName, {
  ProjectNames,
} from "../query_values/useQueryProjectName";
import useQueryPropertyType, {
  PropertyTypes,
} from "../query_values/useQueryPropertyType";
import useQueryBedroomNumbers from "../query_values/useQueryBedroomNumbers";

// Images.
// New Cairo.
// Zone 1.
import apartment1BRImage from "../../img/project_gallery/Floor_Plans/1-BR-App-New Cairo.jpg";
import apartment2BRImage from "../../img/project_gallery/Floor_Plans/2-BR-App-New Cairo.jpg";
import apartment3BRImage from "../../img/project_gallery/Floor_Plans/3-BR-App-New Cairo.jpg";
import penthouse3BRImage from "../../img/project_gallery/Floor_Plans/3 BR_Penthouse_New_Cairo.jpg";
import penthouse4BRImage from "../../img/project_gallery/Floor_Plans/4_BR_Penthouse_New_Cairo.jpg";
import duplex3BRImage from "../../img/project_gallery/Floor_Plans/3-BR-Duplx New Cairo.jpg";
import duplex4BRImage from "../../img/project_gallery/Floor_Plans/4BR-Duplx New Cairo.jpg";
import townhouseImage from "../../img/project_gallery/Floor_Plans/Town_House_New_Cairo.jpg";
// Zone 2.
import BR3VillaA from "../../img/project_gallery/Floor_Plans/3_BR_Villa_Type_A_New_Cairo.jpg";
import BR3VillaB from "../../img/project_gallery/Floor_Plans/3_BR_Villa_Type_A_New_Cairo.jpg";
import BR4Villa from "../../img/project_gallery/Floor_Plans/4BR-Villa-New-Cairo.jpg";
import BR5Villa from "../../img/project_gallery/Floor_Plans/5BR-Villa-New-Cairo.jpg";

// Zayed.
import apartment1BRImageZayed from "../../img/project_gallery/Floor_Plans/1_BR_Zayed.jpg";
import apartment2BRImageZayed from "../../img/project_gallery/Floor_Plans/2_BR_Zayed.jpg";
import apartment3BRImageZayed from "../../img/project_gallery/Floor_Plans/3_BR_Zayed.jpg";
import apartment4BRImageZayed from "../../img/project_gallery/Floor_Plans/4BR_Zayed.jpg";

export default function useSelectPropertyPlanImage(bedroomsNumber?: number) {
  const projectName = useQueryProjectName();
  const propertyType = useQueryPropertyType();

  const bedroomsNumberQueryValue = useQueryBedroomNumbers();
  const bdNumber = bedroomsNumber || bedroomsNumberQueryValue;

  if (projectName === ProjectNames.NEW_CAIRO) {
    switch (propertyType) {
      case PropertyTypes.APARTMENT:
        return getApartmentImage(bdNumber);
      case PropertyTypes.PENTHOUSE:
        return getPenthouseImage(bdNumber);
      case PropertyTypes.DUPLEX:
        return getDuplexImage(bdNumber);
      case PropertyTypes.TOWNHOUSE:
        return getTownhouseImage(bdNumber);
      case PropertyTypes.BR3_VILLA_A:
        return getVillaImage(bdNumber, true);
      case PropertyTypes.BR3_VILLA_B:
        return getVillaImage(bdNumber, false);
      case PropertyTypes.BR4_VILLA:
        return getVillaImage(bdNumber, false);
      case PropertyTypes.BR5_VILLA:
        return getVillaImage(bdNumber, false);
      default:
        return apartment1BRImage;
    }
  } else {
    return getZayedApartmentImage(bdNumber);
  }
}

function getApartmentImage(bedroomsNumber: number) {
  switch (bedroomsNumber) {
    case 1:
      return apartment1BRImage;
    case 2:
      return apartment2BRImage;
    case 3:
      return apartment3BRImage;
    default:
      return apartment1BRImage;
  }
}

function getPenthouseImage(bedroomsNumber: number) {
  switch (bedroomsNumber) {
    case 3:
      return penthouse3BRImage;
    case 4:
      return penthouse4BRImage;
    default:
      return apartment1BRImage;
  }
}

function getDuplexImage(bedroomsNumber: number) {
  switch (bedroomsNumber) {
    case 3:
      return duplex3BRImage;
    case 4:
      return duplex4BRImage;
    default:
      return duplex3BRImage;
  }
}

function getTownhouseImage(bedroomsNumber: number) {
  switch (bedroomsNumber) {
    case 3:
      return townhouseImage;
    default:
      return townhouseImage;
  }
}

function getVillaImage(bedroomsNumber: number, isVillaA: boolean) {
  switch (bedroomsNumber) {
    case 3:
      if (isVillaA) return BR3VillaA;
      else return BR3VillaB;
    case 4:
      return BR4Villa;
    case 5:
      return BR5Villa;
    default:
      return BR3VillaA;
  }
}

function getZayedApartmentImage(bedroomsNumber: number) {
  switch (bedroomsNumber) {
    case 1:
      return apartment1BRImageZayed;
    case 2:
      return apartment2BRImageZayed;
    case 3:
      return apartment3BRImageZayed;
    case 4:
      return apartment4BRImageZayed;
    default:
      return apartment1BRImageZayed;
  }
}
