import { useGetQueryValue } from "../../utils/Values";

export enum ProjectNames {
  NEW_CAIRO = "New Cairo",
  ZAYED = "Zayed",
}

export default function useQueryProjectName() {
  const projectNameQueryValue = useGetQueryValue("p");

  if (!projectNameQueryValue) {
    return ProjectNames.NEW_CAIRO;
  }

  switch (projectNameQueryValue) {
    case ProjectNames.NEW_CAIRO:
      return ProjectNames.NEW_CAIRO;

    case ProjectNames.ZAYED:
      return ProjectNames.ZAYED;

    case "zayed":
      return ProjectNames.ZAYED;

    default:
      return ProjectNames.NEW_CAIRO;
  }
}
