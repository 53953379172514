import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

// Enums
// import ActiveState from "../../enums/active-state.enum";

// Interfaces
import Admin from "../../interfaces/Admin";

type AdminState = Admin | null;

const initialState: AdminState = {};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    // Activate Logic
    login: (state: AdminState, action: { type: string; payload: Admin }) => {
      if (!state) {
        state = action.payload;
      } else {
        state.email = action.payload.email;
        state.active = action.payload.active;
        state.fullName = action.payload.fullName;
        state.password = action.payload.password;
        state.username = action.payload.username;
        state.adminType = action.payload.adminType;
      }
    },
    // Deactivate Logic
    logout: (state: AdminState, action: { type: string; payload: string }) => {
      state = null;
    },
  },
});

// Reducers
export const { login, logout } = authenticationSlice.actions;

export const selectAdmin = (store: RootState) => store.authentication;

export default authenticationSlice.reducer;
