import { useState } from "react";
import { useMediaQuery, VStack } from "@chakra-ui/react";
import { useChoseStyleOnLandscape } from "../../../../utils/Selecting";

// Styling
import externalStyles from "../OptionImages.module.css";

//? Component's Child Components
import ImagesRow from "../ImagesRow/ImagesRow";
import ImagePopoverBrief from "../ImagePopoverBrief/ImagePopoverBrief";

//? Images
import OneBRCelltekZayed from "img/buildings/new_cairo/Floors-Plans/1-BR-Celltek-Zayed.png";
import TwoBRCelltekZayed from "img/buildings/new_cairo/Floors-Plans/2-BR-Celltek-Zayed.png";
import ThreeBRCelltekZayed from "img/buildings/new_cairo/Floors-Plans/3-BR-Celltek-Zayed.png";
import FourBRCelltekZayed from "img/buildings/new_cairo/Floors-Plans/4-BR-Celltek-Zayed.png";

// Images.
import apartment_1BR from "img/project_gallery/Floor_Plans/1-BR-App-New Cairo.jpg";
import apartment_2BR from "img/project_gallery/Floor_Plans/2-BR-App-New Cairo.jpg";
import apartment_3BR from "img/project_gallery/Floor_Plans/1_BR_Zayed.jpg";
import apartment_4BR from "img/project_gallery/Floor_Plans/2_BR_Zayed.jpg";
import penthouse_3BR from "img/project_gallery/Floor_Plans/3 BR_Penthouse_New_Cairo.jpg";
import penthouse_4BR from "img/project_gallery/Floor_Plans/4_BR_Penthouse_New_Cairo.jpg";
import duplex_3BR from "img/project_gallery/Floor_Plans/3-BR-Duplx New Cairo.jpg";
import duplex_4BR from "img/project_gallery/Floor_Plans/4BR-Duplx New Cairo.jpg";
import townhouse_3BR from "img/project_gallery/Floor_Plans/4BR_Zayed.jpg";
import townhouse_4BR from "img/project_gallery/Floor_Plans/Town_House_New_Cairo.jpg";
import villa_a_3BR from "img/project_gallery/Floor_Plans/3_BR_Villa_Type_A_New_Cairo.jpg";
import villa_b_3BR from "img/project_gallery/Floor_Plans/3_BR_Villa_Type_A_New_Cairo.jpg";
import villa_4BR from "img/project_gallery/Floor_Plans/4BR-Villa-New-Cairo.jpg";
import villa_5BR from "img/project_gallery/Floor_Plans/5BR-Villa-New-Cairo.jpg";

type DisplayState = undefined | "none";

export const ZayedFloorsPlans = () => {
  //? Change the visibility state
  const [isDisplayed, setIsDisplayed] = useState<DisplayState>("none");

  const [isMinH896, isMinH812] = useMediaQuery([
    "(min-height:896px)",
    "(min-height:812px)",
  ]);

  const hOf375 = isMinH812 ? "85%" : "77%";
  const hOf414 = isMinH896 ? "85%" : "77%";

  // Landscape Styling.
  const containerStylesInLandscape = {
    h: {
      "1xsm": "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <VStack
      className={externalStyles.option_images}
      w="100%"
      h={[
        "85%",
        "85%",
        "77%",
        hOf375,
        hOf414,
        "77%",
        null,
        null,
        "72%",
        "77%",
        "65vh",
      ]}
      spacing={[
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "1rem",
        "2rem",
      ]}
      {...styles}
    >
      <ImagesRow
        first={{
          BedroomNumber: 1,
          ImageSrc: OneBRCelltekZayed,
          display: setIsDisplayed,
        }}
        second={{
          BedroomNumber: 2,
          ImageSrc: TwoBRCelltekZayed,
          display: setIsDisplayed,
        }}
      />
      <ImagesRow
        first={{
          BedroomNumber: 3,
          ImageSrc: ThreeBRCelltekZayed,
          display: setIsDisplayed,
        }}
        second={{
          BedroomNumber: 4,
          ImageSrc: FourBRCelltekZayed,
          display: setIsDisplayed,
        }}
      />

      <ImagePopoverBrief
        display={isDisplayed}
        setDisplay={setIsDisplayed}
        zIndex="20"
        images={{
          apartment_1BR,
          apartment_2BR,
          apartment_3BR,
          apartment_4BR,
          duplex_3BR,
          duplex_4BR,
          penthouse_3BR,
          penthouse_4BR,
          townhouse_3BR,
          townhouse_4BR,
          villa_4BR,
          villa_5BR,
          villa_a_3BR,
          villa_b_3BR,
        }}
      />
    </VStack>
  );
};
