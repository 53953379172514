import { memo } from "react";

import { AnimatePresence, LazyMotion, domAnimation } from "framer-motion";

import { HStack, VStack } from "@chakra-ui/layout";

import MotionBox from "../../animated-components/MotionBox/MotionBox";

type Props = {
  overflow?: any;
  children: any;
  spacing?: Array<string | number | null> | any;
  isInLandscape?: boolean;
  w?: any;
  h?: any;
};

function PageContainerComponent(props: Props) {
  const StackType = props.isInLandscape ? HStack : VStack;

  return (
    <LazyMotion features={domAnimation}>
      <AnimatePresence>
        <MotionBox
          w={props.w || "100%"}
          h={props.h || "90%"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <StackType
            h="100%"
            w="100%"
            spacing={props.spacing || "0rem"}
            overflowY={props.overflow || "auto"}
            pos={"relative"}
          >
            {props.children}
          </StackType>
        </MotionBox>
      </AnimatePresence>
    </LazyMotion>
  );
}

export default memo(PageContainerComponent);
