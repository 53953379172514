import { memo } from "react";

import Zone from "./Zone";

import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

function Zones() {
  const zoneOneLandscapeDesign = {
    location: {
      top: ["3%", "3%", "12%", "12%", "12%", "12%", null, "22%", null, null],
      left: ["20%", null, null, null, null, null, null, "67%"],
    },
  };
  const zoneOneStyles = useChoseStyleOnLandscape(zoneOneLandscapeDesign);

  const zoneTwoLandscapeDesign = {
    location: {
      top: ["30%", "30%", "40%", "40%", "42%", "40%", null, "55%"],
      left: ["50%", null, null, null, null, null, null, "42%"],
    },
  };
  const zoneTwoStyles = useChoseStyleOnLandscape(zoneTwoLandscapeDesign);

  const zoneThreeLandscapeDesign = {
    location: {
      top: ["60%", "60%", "68%", "68%", "68%", "70%", null, "20%"],
      left: ["15%", null, null, null, null, null, null, "20%"],
    },
  };
  const zoneThreeStyles = useChoseStyleOnLandscape(zoneThreeLandscapeDesign);

  return (
    <>
      <Zone
        location={{
          top: ["3%", "3%", "12%", "12%", "12%", "20%"],
          left: "65%",
        }}
        zoneName="1"
        disable={false}
        {...zoneOneStyles}
      />
      <Zone
        location={{
          top: ["30%", "30%", "40%", "40%", "42%", "40%"],
          left: "35%",
        }}
        zoneName="2"
        disable={false}
        {...zoneTwoStyles}
      />
      <Zone
        location={{
          top: ["60%", "60%", "68%", "68%", "68%", "70%"],
          left: "15%",
        }}
        zoneName="3"
        disable={true}
        {...zoneThreeStyles}
      />

      <Zone
        location={{
          top: ["3%", "3%", "12%", "12%", "12%", "12%"],
          left: "15%",
        }}
        zoneName="4"
        disable={true}
        {...zoneThreeStyles}
      />
      <Zone
        location={{
          top: ["3%", "3%", "12%", "12%", "12%", "70%"],
          left: "65%",
        }}
        zoneName="5"
        disable={true}
        {...zoneOneStyles}
      />
    </>
  );
}

export default memo(Zones);
