import { Box, HStack, VStack, Image } from "@chakra-ui/react";

// Custom Components
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

// Images Sources
import Tennis1Img from "../../img/buildings/Tennis-1.jpg";
import Tennis2Img from "../../img/buildings/Tennis-2.jpg";
import Tennis3Img from "../../img/buildings/Tennis-3.jpg";
import Tennis4Img from "../../img/buildings/Tennis-4.jpg";
import Tennis5Img from "../../img/buildings/Tennis-5.jpg";
import Tennis6Img from "../../img/buildings/Tennis-6.jpg";


export default function FacilityTypeGalleryPage() {
  // Auth before accessing this page
  useVerifyAuth();
  
  return (
    <Box w="100%" h="90%">
      <VStack h="100%" spacing="1.5rem">
        <LocationIndicator text="Tennis Courts" />
        {/* Gallery Code */}
        <VStack w="100%" h="85%" spacing="2rem">
          <HStack w="100%" h="33%" spacing="2rem">
            <Image w="49%" h="100%" borderRadius="3rem" src={Tennis1Img} />
            <Image w="49%" h="100%" borderRadius="3rem" src={Tennis2Img} />
          </HStack>
          <HStack w="100%" h="33%" spacing="2rem">
            <Image w="49%" h="100%" borderRadius="3rem" src={Tennis3Img} />
            <Image w="49%" h="100%" borderRadius="3rem" src={Tennis4Img} />
          </HStack>
          <HStack w="100%" h="33%" spacing="2rem">
            <Image w="49%" h="100%" borderRadius="3rem" src={Tennis5Img} />
            <Image w="49%" h="100%" borderRadius="3rem" src={Tennis6Img} />
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
}

// function GalleryImgInLandscape() {
//   return (
//     <div className="w-full portrait:hidden">
//       <ContainerComp direction="row" styles={imagesContainerStyles}>
//         <ImageComp
//           FloatingComp={Image(Tennis1Img)}
//           src={Tennis1Img}
//           styles={imagesStyles}
//         />
//         <ImageComp
//           FloatingComp={Image(Tennis2Img)}
//           src={Tennis2Img}
//           styles={imagesStyles}
//         />
//         <ImageComp
//           FloatingComp={Image(Tennis3Img)}
//           src={Tennis3Img}
//           styles={imagesStyles}
//         />
//       </ContainerComp>
//       <ContainerComp direction="row" styles={imagesContainerStyles}>
//         <ImageComp
//           FloatingComp={Image(Tennis4Img)}
//           src={Tennis4Img}
//           styles={imagesStyles}
//         />
//         <ImageComp
//           FloatingComp={Image(Tennis5Img)}
//           src={Tennis5Img}
//           styles={imagesStyles}
//         />
//         <ImageComp
//           FloatingComp={Image(Tennis6Img)}
//           src={Tennis6Img}
//           styles={imagesStyles}
//         />
//       </ContainerComp>
//     </div>
//   );
// }

// function Image(src: string) {
//   return <PlateBriefComp imageComp={src} text=" " />;
// }
