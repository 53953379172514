import { HStack } from "@chakra-ui/react";

// Stylings
import externalStyles from "./ImagesRow.module.css";

// Custom Components
import ImageComponent from "./ImageComponent";

type ImagesRowProps = {
  first: {
    BedroomNumber: number;
    ImageSrc: string;
    display: (x: any) => any;
  };
  second?: {
    BedroomNumber: number;
    ImageSrc: string;
    display: (x: any) => any;
  };
  three?: {
    BedroomNumber: number;
    ImageSrc: string;
    display: (x: any) => any;
  };
  four?: {
    BedroomNumber: number;
    ImageSrc: string;
    display: (x: any) => any;
  };
};

export default function ImagesRow(props: ImagesRowProps) {
  return (
    <HStack
      className={externalStyles.images_row}
      w="100%"
      h="90%"
      spacing={[
        "1rem",
        "1rem",
        "1rem",
        "1rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "1.5rem",
        "1.5rem",
      ]}
    >
      <ImageComponent
        bedroomNumber={props.first.BedroomNumber}
        imageSrc={props.first.ImageSrc}
        showImage={ShowImage.bind(null, props.first.display)}
      />

      {props.second && (
        <ImageComponent
          bedroomNumber={props.second.BedroomNumber}
          imageSrc={props.second.ImageSrc}
          showImage={ShowImage.bind(null, props.second.display)}
        />
      )}

      {props.three && (
        <ImageComponent
          bedroomNumber={props.three.BedroomNumber}
          imageSrc={props.three.ImageSrc}
          showImage={ShowImage.bind(null, props.three.display)}
        />
      )}

      {props.four && (
        <ImageComponent
          bedroomNumber={props.four.BedroomNumber}
          imageSrc={props.four.ImageSrc}
          showImage={ShowImage.bind(null, props.four.display)}
        />
      )}
    </HStack>
  );
}

function ShowImage(hide: (x: any) => any) {
  hide(undefined);
}
