import { Center } from "@chakra-ui/react";

import { motion, useAnimation } from "framer-motion";

import { memo, useEffect } from "react";
import { useSelector } from "react-redux";

import MenuToggleButton from "../MenuToggleButton/MenuToggleButton";
import { selectMenuAnimation } from "./NavigationMenuSlice";

function ToggleBtn() {
  const isMenuShown = useSelector(selectMenuAnimation);

  const controls = useAnimation();

  useEffect(() => {
    if (isMenuShown) {
      controls.start({
        opacity: [0, 1],
      });
    } else {
      controls.start({
        opacity: [1, 0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuShown]);

  return (
    <motion.div animate={controls}>
      <Center pos="relative" top="-30px" w="full" h="15%">
        <MenuToggleButton />
      </Center>
    </motion.div>
  );
}

export default memo(ToggleBtn);
