import { VStack } from "@chakra-ui/react";
import { useChoseStyleOnLandscape } from "../../utils/Selecting";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function ProjectsContainer(props: Props) {
  const containerStylesInLandscape = {
    overflow: "auto",
    h: {
      "1xsm": "88%",
    },
    pb: {
      "1xsm": "0.5rem",
    },
    spacing: {
      "1xsm": "0.8rem",
    }
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <VStack
      h="90%"
      w="100%"
      spacing={[
        "1rem",
        "1rem",
        "0.8rem",
        "1rem",
        "0.5rem",
        null,
        null,
        null,
        "1rem",
        "1.5rem",
      ]}
      {...styles}
    >
      {props.children}
    </VStack>
  );
}
