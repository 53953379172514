import { useGetQueryValue } from "../../utils/Values";

export default function useQueryBedroomNumbers() {
  const bdQueryValue = useGetQueryValue("bd");

  if (!bdQueryValue) {
    return 0;
  }

  const bedroomNumbers = parseInt(bdQueryValue);
  
  if (isNaN(bedroomNumbers)) {
    console.error(`Invalid bedroom number: ${bdQueryValue}`);
    return 0;
  }

  return bedroomNumbers;
}
