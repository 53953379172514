import { useBoolean } from "@chakra-ui/react";

// Custom Components
import HeroImage from "./hero-image/HeroImage";
import OptionsContainer from "./options-container/OptionsContainer";
import LocationIndicator from "../sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../common/page-container-component/PageContainerComponent";

// Custom Hooks
import useVerifyAuth from "../../hooks/navigation/useVerifyAuth";

import { useQuery } from "../../utils/Values";
import { useGetStackTypeElOnPageOrientation } from "../../utils/Values";

export default function ProjectZonePage() {
  // Auth before accessing this page
  useVerifyAuth();

  //? Hiding and displaying props
  const [unit1Visibility, changeUnit1Visibility] = useBoolean();
  const [unit2Visibility, changeUnit2Visibility] = useBoolean();
  const [unit3Visibility, changeUnit3Visibility] = useBoolean();
  const [unit4Visibility, changeUnit4Visibility] = useBoolean();

  const StackType = useGetStackTypeElOnPageOrientation();

  // Urls Destinations Location
  const projectName: string | null = useQuery().get("p") || null;
  const projectTitle = projectName ? "Celltek Zayed" : "Celltek New Cairo";
  const destination = `project-master-plane-page?p=${projectName}&title=${projectTitle}`;
  
  return (
    <PageContainerComponent spacing={{ base: "0rem" }} overflow="hidden">
      <LocationIndicator pageUrl={destination} text="Celltek New Cairo" />
      <StackType w="full" h="90%" spacing={0}>
        <HeroImage
          firstBuildingVisibility={unit1Visibility}
          secondBuildingVisibility={unit2Visibility}
          thirdBuildingVisibility={unit3Visibility}
          forthBuildingVisibility={unit4Visibility}
        />
        <OptionsContainer
          unitOne={changeUnit1Visibility}
          unitTwo={changeUnit2Visibility}
          unitThree={changeUnit3Visibility}
          unitFour={changeUnit4Visibility}
        />
      </StackType>
    </PageContainerComponent>
  );
}
