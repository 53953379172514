import { SectionProps } from "./SectionOne";

// import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

const SectionEight = (props: SectionProps) => {
  return (
    <>
     
    </>
  );
};

export default SectionEight;
