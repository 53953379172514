import { SectionProps } from "./SectionOne";

import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

const SectionFour = (props: SectionProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        rotateDegree={65}
        buttonWidth={null}
        buttonHeight={null}
        topOffset={["34%", "33.8%%", "33.8%%", "34%", "33.8%", "34.5%"]}
        leftOffset={["19%", "20.6%", "20.6%", "20.6%", "20.6%", "20.7%"]}
        plotNumber="181"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        rotateDegree={65}
        buttonWidth={null}
        buttonHeight={null}
        topOffset="36.5%"
        leftOffset="21.2%"
        plotNumber="182"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        rotateDegree={65}
        buttonWidth={null}
        buttonHeight={null}
        topOffset="38.7%"
        leftOffset="21.6%"
        plotNumber="183"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        rotateDegree={65}
        buttonWidth={null}
        buttonHeight={null}
        topOffset="40.5%"
        leftOffset="22%"
        plotNumber="184"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        rotateDegree={67}
        buttonWidth={null}
        buttonHeight={null}
        topOffset="43.5%"
        leftOffset="22.8%"
        plotNumber="185"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="45.4%"
        leftOffset="23.2%"
        plotNumber="186"
        hasUnits={props.hasUnits}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="47.4%"
        leftOffset="23.5%"
        plotNumber="187"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="49.3%"
        leftOffset="24%"
        plotNumber="188"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="51.3%"
        leftOffset="24.4%"
        plotNumber="189"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="53%"
        leftOffset="25%"
        plotNumber="190"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="61.7%"
        leftOffset="26.8%"
        plotNumber="191"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="63.5%"
        leftOffset="27.3%"
        plotNumber="192"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="65.4%"
        leftOffset="27.7%"
        plotNumber="193"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="67.2%"
        leftOffset="28.1%"
        plotNumber="194"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="69.2%"
        leftOffset="28.5%"
        plotNumber="195"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="71.1%"
        leftOffset="29%"
        plotNumber="196"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="74.2%"
        leftOffset="29.7%"
        plotNumber="197"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="76.2%"
        leftOffset="30.1%"
        plotNumber="198"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="78%"
        leftOffset="30.5%"
        plotNumber="199"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="79.7%"
        leftOffset="30.9%"
        plotNumber="200"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="81.9%"
        leftOffset="31.3%"
        plotNumber="201"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={67}
        topOffset="83.6%"
        leftOffset="31.8%"
        plotNumber="202"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

   
    </>
  );
};

export default SectionFour;
