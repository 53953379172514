import { memo, Suspense } from "react";
import { Center, Spinner } from "@chakra-ui/react";

//? Header Child elements
import Container from "./Container";
import CompanyLogo from "./CompanyLogo";
import IconContainer from "./IconContainer";
import FormBookComp from "../../../standalone-components/FormBookComp";

import { FormBookCompProps } from "../../../standalone-components/FormBookComp/FormBookComp";

export interface HeaderProps {
  formData?: FormBookCompProps;
}

function Header(props: HeaderProps) {
  return (
    <Suspense fallback={<LazyLoadingComp />}>
      <Container>
        <CompanyLogo />
        {props.formData ? <FormBookComp {...props.formData} /> : null}
        <IconContainer />
      </Container>
    </Suspense>
  );
}

export default memo(Header);

function LazyLoadingComp() {
  return (
    <Center w="full" h="full">
      <Spinner
        sx={{
          "--spinner-size": "15rem",
        }}
        thickness="7px"
        color="red.500"
        emptyColor="gray.200"
        speed="0.85s"
        size="xl"
      />
    </Center>
  );
}
