import { NavButton } from "components/project-zone-page/NavButtons/NavButton";
import { SectionProps } from "./SectionOne";

const SectionSeven = (props: SectionProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={4}
        topOffset="35.4%"
        leftOffset="65.8%"
        plotNumber="238"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={4}
        topOffset="35.3%"
        leftOffset="64.6%"
        plotNumber="239"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={4}
        topOffset="35.3%"
        leftOffset="63.4%"
        plotNumber="240"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={4}
        topOffset="35.3%"
        leftOffset="62.2%"
        plotNumber="241"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={330}
        topOffset="36.4%"
        leftOffset="60.2%"
        plotNumber="242"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={326}
        topOffset="37.6%"
        leftOffset="59.3%"
        plotNumber="243"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={326}
        topOffset="38.8%"
        leftOffset="58.4%"
        plotNumber="244"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={320}
        topOffset="40.0%"
        leftOffset="57.5%"
        plotNumber="245"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={312}
        topOffset="42.7%"
        leftOffset="56.1%"
        plotNumber="246"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={312}
        topOffset="44.3%"
        leftOffset="55.5%"
        plotNumber="247"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={312}
        topOffset="46.0%"
        leftOffset="54.8%"
        plotNumber="248"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={312}
        topOffset="47.4%"
        leftOffset="54.1%"
        plotNumber="249"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
    </>
  );
};

export default SectionSeven;
