import { useState } from "react";
import { Link } from "react-router-dom";
import { VStack, Image, Box, Center, Text, Skeleton } from "@chakra-ui/react";

type PropertyTypeProps = {
  propertyName: string;
  image: string;
};

export default function PropertyTypeComp(props: PropertyTypeProps) {
  const url = `project-with-property-type?title=${props.propertyName}`;

  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <VStack
      as={Link}
      to={url}
      position="relative"
      w="100%"
      h="100%"
      spacing="2rem"
    >
      <Skeleton
        w="100%"
        h="100%"
        borderRadius={["1rem", "1rem", "1rem", "1rem", "1rem", "1.7rem"]}
        isLoaded={isLoaded}
      >
        <Image
          w="100%"
          h="100%"
          borderRadius={{ base: "1rem", mobile8: "2rem", tablet12: "2rem" }}
          src={props.image}
          onLoad={() => setIsLoaded(true)}
        />
      </Skeleton>
      <Box
        w="100%"
        h={["23%", null, null, null, null, null, null, null, "26%", "23%"]}
        position="absolute"
        bottom="0"
        left="0"
        borderBottomRadius={{ base: "1rem", mobile8: "2rem", tablet12: "2rem" }}
        bg="#000000db"
      >
        <Center w="100%" h="100%">
          <Text
            fontSize={{
              base: "1rem",
              miniMobile12: "1.3rem",
              mobile8: "1.7rem",
              tablet12: "5xl",
            }}
            color="white"
          >
            {props.propertyName}
          </Text>
        </Center>
      </Box>
    </VStack>
  );
}
