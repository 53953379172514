import Container from "./Container";
import Header from "./Header";
import SendingComp from "./sending-component/SendingComp";

import ErrorMsg from "./ErrorMsg";
import Disclaimer from "./Disclaimer";

export default function SharingComp() {
  return (
    <Container>
      <Header />
      <SendingComp />
      <Disclaimer />
      <ErrorMsg />
    </Container>
  );
}
