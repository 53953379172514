import { memo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Flex, HStack, Image, Text } from "@chakra-ui/react";

// Stylings
import styles from "./LocationIndicator.module.css";

// Utils Functions
import { useChoseOnLightMode } from "../../../utils/Selecting";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

// Interfaces
import { ILocationIndicatorProps } from "../../../interfaces/Text";

// Icon
import BackIcon from "../../../img/icons/Back-Icon.svg";
import BackDarkIcon from "../../../img/icons/Back-Icon-Dark.svg";

function LocationIndicator(props: ILocationIndicatorProps) {
  const history = useHistory();

  const projectName = useQueryProjectName();
  const pageTitle = props.text || projectName;

  const themeIcon = useChoseOnLightMode(BackIcon, BackDarkIcon);

  const txtColor = useChoseOnLightMode("black", "gray.200");

  return (
    <Flex align="center" justify="flex-start" w="100%" h="10%">
      <HStack
        as={props.pageUrl ? Link : undefined}
        to={props.pageUrl || ""}
        spacing={{
          base: "5px",
          miniMobile10: "0.4rem",
          mobile13: "1rem",
          tablet2: "1rem",
          tablet16: "1.5rem",
          desktop5: "1rem",
        }}
        w="100%"
        h="100%"
      >
        <Image
          loading="lazy"
          onClick={props.pageUrl ? undefined : history.goBack}
          h={["60%", "66%"]}
          src={themeIcon}
        />
        <Text
          className={styles.indicator_text}
          color={txtColor}
          fontSize={
            props.fontSize || {
              base: "1rem",
              miniMobile8: "1.5rem",
              miniMobile10: "1.2rem",
              mobile8: "2rem",
              mobile9: "1.1rem",
              mobile10: "1.1rem",
              mobile11: "1.2rem",
              mobile12: "1.5rem",
              mobile13: "2.1rem",
              mobile14: "1.5rem",
              mobile15: "1.5rem",
              tablet1: "1.7rem",
              tablet2: "2.4rem",
              tablet3: "2.5rem",
              tablet9: "1.9rem",
              tablet7: "2rem",
              tablet12: "2.3rem",
              tablet13: "2.3rem",
              tablet15: "2.2rem",
              tablet16: "3.5rem",
              tablet19: "2.5rem",
              desktop5: "2.5rem",
            }
          }
        >
          {pageTitle}
        </Text>
      </HStack>
    </Flex>
  );
}

export default memo(LocationIndicator);
