import { useGetQueryValue } from "utils/Values";

export default function useQueryFloorNumber() {
  const floorNumberQueryValue = useGetQueryValue("fn");

  if (!floorNumberQueryValue) {
    return "";
  }

  return floorNumberQueryValue;
}
