import { HStack } from "@chakra-ui/react";
import { memo } from "react";
import { useQueryContainsValue } from "../../../utils/Containing";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

import Project from "../project-comp/Project";

type Props = {
  images: {
    firstImage: string;
    secondImage: string;
  };
  names: {
    firstName: string;
    secondName: string;
  };
  availability: {
    project1HasVilla: boolean;
    project2HasVilla: boolean;
  };
};
function ImagesRow2Comp(props: Props) {
  const { images, names, availability } = props;

  const isVilla = useQueryContainsValue("title", "villa");
  const isApartment = !isVilla;

  const { project1HasVilla, project2HasVilla } = availability;
  const project1Availability = (project1HasVilla && isVilla) || isApartment;
  const project2Availability = (project2HasVilla && isVilla) || isApartment;

  // Landscape styling option.
  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
    h: {
      "1xsm": "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <HStack
      w="100%"
      h="49%"
      spacing={{ base: "1rem", tablet16: "3rem" }}
      {...styles}
    >
      <Project
        image={images.firstImage}
        projectName={names.firstName}
        isAvailable={project1Availability}
        availableZone={isVilla ? 2 : undefined}
      />
      <Project
        image={images.secondImage}
        projectName={names.secondName}
        isAvailable={project2Availability}
      />
    </HStack>
  );
}

export default memo(ImagesRow2Comp);
