import { VStack } from "@chakra-ui/react";

import { Link } from "react-router-dom";

import ProjectImage from "./ProjectImage";
import ProjectTitle from "./ProjectTitle";

type Props = {
  projectName: string;
  image: string;
  isAvailable?: boolean;
  availableZone?: string | number;
  availableBuilding?: string | number;
};

export default function Project(props: Props) {
  //? Navigation functionality
  const { projectName, image, isAvailable, availableZone } = props;

  const title = `Celltek ${projectName} Project`;
  const query = `title=${title}&p=${projectName}&active-zone=${availableZone}`;
  const destinationUrl = `project-master-plane-page?${query}`;

  return (
    <VStack
      as={isAvailable ? Link : undefined}
      to={destinationUrl}
      position="relative"
      w="49%"
      h="100%"
    >
      <ProjectImage image={image} isAvailable={isAvailable} />
      <ProjectTitle projectName={projectName} />
    </VStack>
  );
}
