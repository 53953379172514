import { FaMoon } from "react-icons/fa";
import { BsSunFill } from "react-icons/bs";

import { Center, Icon } from "@chakra-ui/react";
import { useChoseOnLightMode } from "../../../utils/Selecting";

import { selectTheme, makeDarkTheme } from "../../../features/theme/themeSlice";
import { makeLightTheme } from "../../../features/theme/themeSlice";
import { useSelector, useDispatch } from "react-redux";

export default function HeaderIcon() {
  const toggleIcon = useChoseOnLightMode(FaMoon, BsSunFill);
  const toggleIconColor = useChoseOnLightMode("gray.200", "rgb(218, 49, 67)");

  const isLightTheme = useSelector(selectTheme) === "light";
  const dispatch = useDispatch();

  function toggleTheme() {
    return isLightTheme
      ? dispatch(makeDarkTheme())
      : dispatch(makeLightTheme());
  }

  return (
    <Center w="100%" h="100%">
      <Icon
        onClick={toggleTheme}
        as={toggleIcon}
        color={toggleIconColor}
        w={["70%", "70%", "70%", "70%", "100%"]}
        h={["70%", "70%", "70%", "70%", "100%"]}
      />
    </Center>
  );
}
