import { memo, useEffect } from "react";

import MotionBox from "../../animated-components/MotionBox/MotionBox";

import { IconsNames } from "./utils";

import { useAnimation } from "framer-motion";
import { useSelector } from "react-redux";
import { selectActiveIcons } from "../../../app/map/activeCompSlice";

import PinIcon, { PinIconProps } from "./PinIcon";

const variants = {
  bouncing: {
    y: [0, -20, 0],
  },
  standStill: {
    y: 0,
  },
};

export interface PinAnimatedContainerProps extends PinIconProps {}

function PinAnimatedContainer(props: PinAnimatedContainerProps) {
  const IconName = props.iconName || IconsNames.Club;

  const controls = useAnimation();

  const isAnimated = useSelector(selectActiveIcons)[IconName];

  useEffect(() => {
    if (isAnimated) controls.start("bouncing");
    else controls.stop();

    return controls.stop;
  }, [isAnimated, controls]);

  return (
    <MotionBox
      initial="standStill"
      animate={controls}
      exit="standStill"
      variants={variants}
      transition={{
        repeat: Infinity,
        repeatType: "reverse",
      }}
      w="full"
      h="full"
    >
      <PinIcon
        iconName={props.iconName}
        idleColor={props.idleColor}
        isResidential={props.isResidential}
      />
    </MotionBox>
  );
}

export default memo(PinAnimatedContainer);
