import { memo } from "react";

import ImagesRow2Comp from "../images-row/ImagesRow2Comp";
import Container from "./Container";

import OneBRCelltekZayed from "../../../img/projects_page/downtown.jpg";
import TwoBRCelltekZayed from "../../../img/projects_page/new-cairo.jpg";
import ThreeBRCelltekZayed from "../../../img/projects_page/mokattam.jpg";
import FourBRCelltekZayed from "../../../img/projects_page/zayed.png";

function ProjectsContainer() {
  return (
    <Container>
      <ImagesRow2Comp
        images={{
          firstImage: TwoBRCelltekZayed,
          secondImage: FourBRCelltekZayed,
        }}
        names={{
          firstName: "New Cairo",
          secondName: "Zayed",
        }}
        availability={{
          project1HasVilla: true,
          project2HasVilla: false,
        }}
      />
      <ImagesRow2Comp
        images={{
          firstImage: OneBRCelltekZayed,
          secondImage: ThreeBRCelltekZayed,
        }}
        names={{
          firstName: "Downtown",
          secondName: "Mokattam",
        }}
        availability={{
          project1HasVilla: false,
          project2HasVilla: false,
        }}
      />
    </Container>
  );
}

export default memo(ProjectsContainer);
