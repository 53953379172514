import {motion} from "framer-motion";

import {memo} from "react";

import {Flex} from "@chakra-ui/react";


import styles from "./NavigationMenuSlice.module.css"

import OptionsButtons from "../../project-presentation-page/OptionsButtons";
import Container from "./Container";
import ToggleBtn from "./ToggleBtn";

function NavigationMenu() {
    return (
        <Container>
            <Flex w="100%" alignItems={"center"} justifyContent={"center"}>
                <Flex w={{base: "8%"}}>
                    <ToggleBtn/>
                </Flex>
            </Flex>
            <motion.div className={styles.menu_options_btn_container}>
                <OptionsButtons height="85%"/>
            </motion.div>
        </Container>
    );
}

export default memo(NavigationMenu);
