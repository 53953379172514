import axios, {  AxiosResponse } from "axios";

import useSWR from "swr";

// Constants.
import baseURL from "../constants/baseURL";

const fetcher = <T>(url: string) =>
  axios
    .get<T, AxiosResponse<T>>(url, {
      withCredentials: true,
      baseURL,
    })
    .then((response) => {
      return response.data;
    });

export default function useFetcher<T>(url: string,refreshInterval?: number): T[] {
  const { data } = useSWR<T[], any, string>(url, fetcher, {
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: refreshInterval || 10000,
  });

  return data ? data : [];
}


export function useFetcherSingle<T>(url: string,refreshInterval?: number): T | null {
  const { data } = useSWR<T, any, string>(url, fetcher, {
    revalidateOnMount: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: refreshInterval || 10000,
    
  });

  return data ? data : null;
}
