import Slider from "react-slick";

import {
  VStack,
  Image,
  Center,
  Text,
  CloseButton,
  Flex,
} from "@chakra-ui/react";

export default function ImagePopoverBrief(props: ImageShowCompProps) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Center
      position="absolute"
      top="0"
      left="0"
      w="full"
      h="full"
      zIndex={props.zIndex}
      display={props.display}
      onClick={props.setDisplay.bind(null, "none")}
    >
      <VStack
        p="0.5rem"
        w="75%"
        h={["48%", "48%", "55%", "45%"]}
        bg="white"
        borderRadius={["1rem", "1rem", "1rem", "1rem", "1rem", "2rem"]}
        borderWidth="medium"
        borderColor="red.500"
        spacing="0.1rem"
      >
        <Flex
          align="center"
          justify="space-between"
          w="90%"
          h="18%"
          borderRadius="2rem"
        >
          <Text
            fontSize={["1rem", "1rem", "1rem", "1rem", "1rem", "2.2rem"]}
            color="#ff0000"
            fontWeight="semibold"
          >
            Celltek New Cairo
          </Text>
          <CloseButton
            w={["12%", "12%", "12%", "12%", "12%", "10%"]}
            h={["60%"]}
            bg="#ff0000"
            fontSize={["1rem", "1rem", "0.7rem", "0.7rem", "1rem", "1.5rem"]}
            color="white"
            onClick={props.setDisplay.bind(null, "none")}
          />
        </Flex>
        <Center w="full" h="85%">
          <Slider className="slider-sizes" {...settings}>
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.apartment_1BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.apartment_2BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.apartment_3BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.apartment_4BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.penthouse_3BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.penthouse_4BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.duplex_3BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.duplex_4BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.townhouse_3BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.townhouse_4BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.villa_a_3BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.villa_b_3BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.villa_4BR}
            />
            <Image
              w="3rem"
              h="37rem"
              borderRadius="2rem"
              src={props.images.villa_5BR}
            />
          </Slider>
        </Center>
      </VStack>
    </Center>
  );
}

type ImageShowCompProps = {
  display: undefined | "none";
  setDisplay: (x: undefined | "none") => any;
  images: IProps;
  zIndex: string | number;
};

type IProps = {
  apartment_1BR: string;
  apartment_2BR: string;
  apartment_3BR: string;
  apartment_4BR: string;
  penthouse_3BR: string;
  penthouse_4BR: string;
  duplex_3BR: string;
  duplex_4BR: string;
  townhouse_3BR: string;
  townhouse_4BR: string;
  villa_a_3BR: string;
  villa_b_3BR: string;
  villa_4BR: string;
  villa_5BR: string;
};
