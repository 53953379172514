import { Box } from "@chakra-ui/react";
import MenuToggleButton from "../common/MenuToggleButton/MenuToggleButton";

import NavigationMenu from "../common/NavigationMenu/NavigationMenu";

export default function TestingComp() {
  return (
    <Box pos="relative" w="full" h="90%" bg="blue.900">
      <MenuToggleButton />
      <NavigationMenu />
    </Box>
  );
}
