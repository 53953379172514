import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

import { SectionProps } from "./SectionOne";

const SectionThree = (props: SectionProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="11.4%"
        leftOffset="39.6%"
        plotNumber="156"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="10.3%"
        leftOffset="38.6%"
        plotNumber="157"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="9.2%"
        leftOffset="37.4%"
        plotNumber="158"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="8.3%"
        leftOffset="36.4%"
        plotNumber="159"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="6.8%"
        leftOffset="34.9%"
        plotNumber="160"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="5.8%"
        leftOffset="33.9%"
        plotNumber="161"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="4.7%"
        leftOffset="32.9%"
        plotNumber="162"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="3.7%"
        leftOffset="32.0%"
        plotNumber="163"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="2.8%"
        leftOffset="31.0%"
        plotNumber="164"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={25}
        topOffset="1.9%"
        leftOffset="30.0%"
        plotNumber="165"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={-4}
        topOffset="0.4%"
        leftOffset="27.9%"
        plotNumber="166"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={153}
        topOffset="0.4%"
        leftOffset="25.9%"
        plotNumber="167"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={106}
        topOffset="2.4%"
        leftOffset="23.9%"
        plotNumber="168"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={106}
        topOffset="6.5%"
        leftOffset="23.2%"
        plotNumber="169"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={106}
        topOffset="8.5%"
        leftOffset="23.0%"
        plotNumber="170"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="10.5%"
        leftOffset="22.8%"
        plotNumber="171"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="12.5%"
        leftOffset="22.5%"
        plotNumber="172"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="14.6%"
        leftOffset="22.3%"
        plotNumber="173"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="16.8%"
        leftOffset="22.1%"
        plotNumber="174"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="20.0%"
        leftOffset="21.7%"
        plotNumber="175"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="22.0%"
        leftOffset="21.5%"
        plotNumber="176"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="24.1%"
        leftOffset="21.3%"
        plotNumber="177"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="26.0%"
        leftOffset="21.1%"
        plotNumber="178"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="28.0%"
        leftOffset="20.8%"
        plotNumber="179"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />

      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={102}
        topOffset="30.2%"
        leftOffset="20.6%"
        plotNumber="180"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
    </>
  );
};

export default SectionThree;
