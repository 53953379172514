import {Box, Center, Image} from "@chakra-ui/react";
import {LazyLoadImage} from "react-lazy-load-image-component";

// Stylings
import externalStyles from "./ZoneImage.module.css";

// Images
import zoneImage from "../../../img/master_plan_page/New-Cairo-Mastr-Plan.jpg";
import zoneImageZone1Active from "../../../img/maps/NewCairoImages/CellTek-Master-Plan-Zone-1-Acitve.png";
import zoneImageZone2Active from "../../../img/maps/NewCairoImages/CellTek-Master-Plan-Zone-2-Acitve.png";

// Utils
import {useGetQueryValue} from "../../../utils/Values";
import {
    useChoseStyleOnLandscape,
    useChoseValueOnLandscape,
} from "../../../utils/Selecting";

export default function ZoneImage() {
    const activeZone = useGetQueryValue("active-zone");

    const image = choseImageOnActiveZone(activeZone, {
        zone1: zoneImageZone1Active,
        zone2: zoneImageZone2Active,
        noZone: zoneImage,
    });

    // Landscape Design.
    const imgStylesInLandscape = {
        borderRadius: {
            "1xsm": "1rem",
        },
        w: {
            "1xsm": "54%",
        },
        h: {
            "1xsm": "130%"
        },
        transform: "rotate(90deg)",
    };
    const styles = useChoseStyleOnLandscape(imgStylesInLandscape);

    const containerStylesInLandscape = {
        bg: "transparent",
        borderRadius: {
            "1xsm": "2rem",
        },
    };
    const containerStyles = useChoseStyleOnLandscape(containerStylesInLandscape);

    // Landscape Components Switch
    const ImageContainer = useChoseValueOnLandscape(Center, Box);

    return (
        <ImageContainer w="full" h="full" {...containerStyles}>
            <Image
                className={externalStyles.zone_image}
                as={LazyLoadImage}
                borderRadius={{base: "1.5rem", mobile8: "2.5rem" ,mobile13: "3rem", tablet13: "4rem" ,  tablet16: "3rem"}}
                w="full"
                h="full"
                src={image}
                {...styles}
            />
        </ImageContainer>
    );
}

type ZonesImages = {
    zone1: string;
    zone2: string;
    noZone: string;
};

function choseImageOnActiveZone(
    activeZone: string | null,
    zonesImages: ZonesImages
) {
    switch (activeZone) {
        case "1":
            return zonesImages.zone1;
        case "2":
            return zonesImages.zone2;
        default:
            return zonesImages.noZone;
    }
}
