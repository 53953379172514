import {HStack, Button} from "@chakra-ui/react";
import ElementStyleProps from "../../../utils/ElementStylesProps";

import {useChoseOnLightMode} from "../../../utils/Selecting";

type OptionBtnProps = {
    active: () => any;
    btnName: string | number;
};

export default function OptionBtn(props: OptionBtnProps) {
    const bgColor = useChoseOnLightMode("transparent", "gray.300");
    const borderColor = useChoseOnLightMode("red.500", "transparent");

    const containerStyle: ElementStyleProps = {
        w: {base: "32%", tablet16: "full"},
        h: "full",
        spacing: "2px",
        bg: bgColor,
        borderColor,
        borderWidth: {base: "1px", miniMobile10: "2px", tablet16: "medium"},
        borderRadius: {base: "0.4rem", mobile8: "0.8rem", tablet16: "1.5rem"},
        py: {
            base: "0.5rem",
            tablet16: "2rem",
            desktop5: "1rem",
        },
        px: {
            base: "0.3rem",
            tablet16: "1rem",
            desktop5:"1rem",
        },
    };

    return (
        <HStack
            {...containerStyle}
            _hover={{
                bg: "red.500",
                color: "white",
            }}
            _focus={{
                bg: "red.500",
                color: "white",
            }}
            _active={{
                bg: "red.500",
                color: "white",
            }}
            onClick={props.active}
        >
            <Button
                _hover={{
                    outline: "none",
                    bg: "red.500",
                    color: "white",
                }}
                _focus={{
                    outline: "none",
                    bg: "red.500",
                    color: "white",
                }}
                _active={{
                    outline: "none",
                    bg: "red.500",
                    color: "white",
                }}
                w="100%"
                fontSize={{
                    base: "0.5rem",
                    miniMobile10: "0.6rem",
                    mobile1:"0.7rem",
                    mobile8: "0.8rem",
                    mobile13: "1rem",
                    tablet2: "1.3rem",
                    tablet7: "1.5rem",
                    tablet16: "1.9rem",
                }}
                bg="transparent"
                onClick={props.active}
            >
                {props.btnName}
            </Button>
        </HStack>
    );
}
