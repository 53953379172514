import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type Property = {
  type: string;
  price: number;
  bedroomsNumber: number;
};

const initialState: Property = {
  type: "",
  price: 0,
  bedroomsNumber: 0,
};

const propertySlice = createSlice({
  name: "property",
  initialState,
  reducers: {
    setProperty: (
      state: Property,
      action: { type: string; payload: Property }
    ) => {
      state = action.payload;
    },
  },
});

// Reducers
export const { setProperty } = propertySlice.actions;

export default propertySlice.reducer;

export const selectProperty = (state: RootState) => state.property;
