import { Box, Image, Skeleton } from "@chakra-ui/react";
import { useState } from "react";

import { useChoseOnQueryValue } from "../../../utils/Selecting";

import Zone1Img from "../../../img/maps/Zone-1.png";
import Zone2Img from "../../../img/maps/Zone-2.png";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function Img(props: Props) {
  const zoneImage = useChoseOnQueryValue("z", "1", Zone1Img, Zone2Img);

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Skeleton
      w="100%"
      h="80%"
      flex={3}
      borderRadius={{base: "1rem", mobile8: "2rem", tablet16: "3rem"}}
      isLoaded={isLoaded}
    >
      <Box
        position="relative"
        w="100%"
        h="100%"
        borderRadius={{base: "1rem", mobile8: "2rem", tablet16: "3rem"}}
      >
        <Image
          onLoad={() => setIsLoaded(true)}
          w="100%"
          h="100%"
          borderRadius={{base: "1rem", mobile8: "2rem", tablet16: "3rem"}}
          objectFit="cover"
          src={zoneImage}
        />
        {props.children}
      </Box>
    </Skeleton>
  );
}
