import {Text} from "@chakra-ui/react";

import {
    useChoseOnLightMode,
    useChoseStyleOnLandscape,
} from "../../../utils/Selecting";
import {useGetQueryValue} from "../../../utils/Values";

export default function Txt() {
    const txtColor = useChoseOnLightMode("black", "gray.200");

    const zoneNumber = useGetQueryValue("z") || "1";

    // Landscape Design.
    const containerStylesInLandscape = {
        fontSize: {
            "1xsm": "1.5rem",
        },
    };
    const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

    return (
        <Text
            w="100%"
            h="20%"
            flex={1}
            fontSize={{
                base: '1rem',
                miniMobile8: "1.5rem",
                miniMobile12: "1.8rem",
                mobile8: "2.3rem",
                tablet7: "3.5rem",
                tablet16: "4rem"
            }}
            color={txtColor}
            {...styles}
        >
            Zone {zoneNumber}
        </Text>
    );
}
