import { useEffect } from "react";

export type ReactSetState<T> = React.Dispatch<React.SetStateAction<T>>;

export default function useHideAfterTimeIfOpen(
  isOpen: boolean,
  closeElement: ReactSetState<boolean>,
  time?: number
) {
  // convert from milliseconds to 10 minutes
  const tenMinutes = 600000;

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (isOpen) id = setTimeout(() => closeElement(false), time || tenMinutes);

    return () => clearTimeout(id);
  }, [closeElement, isOpen, time]);
}
