import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  useChoseOnLightMode,
  useChoseStyleOnLandscape,
} from "../../../utils/Selecting";
import { Location } from "../../project-facilities-page/hero-image/project-pins/utils";

type Props = {
  location: Location;
  isDisabled?: boolean;
  btnTxt: string;
};

export default function ZoneBtn(props: Props) {
  const destinationUrl = `property-display-page?p=zayed&type=${props.btnTxt}`;

  const ZoneBtnBgColor = useChoseOnLightMode("White", "gray.300");
  const borderColor = useChoseOnLightMode("red.500", "transparent");

  // Landscape Styling.
  const containerStylesInLandscape = {
    w: {
      "1xsm": "20%",
    },
    p: {
      "1xsm": "0.8rem",
    },
    borderRadius: {
      "1xsm": "0.5rem",
    },
    fontSize: {
      "1xsm": "0.7rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Button
      as={props.isDisabled ? undefined : Link}
      to={destinationUrl}
      position="absolute"
      top={props.location.top}
      left={props.location.left}
      fontSize={{base: '1rem', mobile8: "1.5rem", tablet13: "2.5rem", tablet16: "3rem"}}
      p={["0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.5rem", "1rem"]}
      w="30%"
      h="10%"
      bg={ZoneBtnBgColor}
      borderColor={borderColor}
      borderWidth={{base: "thin", mobile13: "2px", tablet16: "medium"}}
      color="red.500"
      borderRadius={{base: "7px", mobile13: "0.5rem", tablet16: "1rem"}}
      disabled={props.isDisabled}
      {...styles}
    >
      {props.btnTxt}
    </Button>
  );
}
