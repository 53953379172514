import { HStack, Image } from "@chakra-ui/react";

import InstaIcon from "../../../../img/icons/Insta-Icon.svg";
import EmailInput from "./EmailInput/EmailInput";
import SendingBtn from "./SendingBtn";

export default function SendingComp() {
  return (
    <HStack w="full" h={["50%", null, null, null, null, null, "70%"]}>
      <Image src={InstaIcon} h="70%" />
      <EmailInput />
      <SendingBtn />
    </HStack>
  );
}
