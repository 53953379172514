import { Box, Flex } from "@chakra-ui/react";

export default function Container(props: any) {
  return (
    <Box w="100%" h="10%">
      <Flex align="center" w="100%" h="100%" justify="space-between">
        {props.children}
      </Flex>
    </Box>
  );
}
