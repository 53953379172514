import { SectionProps } from "./SectionOne";

import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

const SectionSix = (props: SectionProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={340}
        topOffset="64.4%"
        leftOffset="56.0%"
        plotNumber="218"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={340}
        topOffset="63.8%"
        leftOffset="57.1%"
        plotNumber="219"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={340}
        topOffset="62.9%"
        leftOffset="58.2%"
        plotNumber="220"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={340}
        topOffset="62.0%"
        leftOffset="59.2%"
        plotNumber="221"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={336}
        topOffset="61.0%"
        leftOffset="60.2%"
        plotNumber="222"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={336}
        topOffset="60.3%"
        leftOffset="61.2%"
        plotNumber="223"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={335}
        topOffset="58.8%"
        leftOffset="62.9%"
        plotNumber="224"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={338}
        topOffset="58.1%"
        leftOffset="63.9%"
        plotNumber="225"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={340}
        topOffset="57.2%"
        leftOffset="65.0%"
        plotNumber="226"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={340}
        topOffset="56.5%"
        leftOffset="66.0%"
        plotNumber="227"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={340}
        topOffset="55.7%"
        leftOffset="67.0%"
        plotNumber="228"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth={null}
        buttonHeight={null}
        rotateDegree={340}
        topOffset="54.7%"
        leftOffset="68.0%"
        plotNumber="229"
        hasUnits={props.hasUnits}
        page={`${props.propertyUrl}`}
      />
    </>
  );
};

export default SectionSix;
