import {Button} from "@chakra-ui/react";

import {useChoseOnFlag} from "../../../../utils/Selecting";

type Props = {
    buttonName: string;
    isActive?: boolean;
};

export default function OptionBtn(props: Props) {
    const activeColor = useChoseOnFlag(props.isActive, "white", undefined);

    return (
        <Button
            color={activeColor}
            fontSize={{base: "0.5rem", mobile8: "1rem", tablet16: "2rem"}}
            bg="transparent"
            p="0rem"
            h="full"
            _hover={{
                bg: "transparent",
            }}
            _active={{
                bg: "transparent",
            }}
            _focus={{
                outline: "none",
            }}
        >
            {props.buttonName}
        </Button>
    );
}
