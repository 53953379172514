import { useState } from "react";
import { Heading, useMediaQuery, VStack } from "@chakra-ui/react";
import { useChoseStyleOnLandscape } from "../../../../utils/Selecting";

// Styling
import externalStyles from "../OptionImages.module.css";

//? Component's Child Components
import ImagesRow from "./ImagesRow/ImagesRow";
import ImagePopoverBrief from "../ImagePopoverBrief/ImagePopoverBrief";

// Images.
// New Cairo.
// Zone 1.
import apartment1BRImage from "../../../../img/project_gallery/Floor_Plans/1-BR-App-New Cairo.jpg";
import apartment2BRImage from "../../../../img/project_gallery/Floor_Plans/2-BR-App-New Cairo.jpg";
import apartment3BRImage from "../../../../img/project_gallery/Floor_Plans/3-BR-App-New Cairo.jpg";
import apartment4BRImage from "../../../../img/project_gallery/Floor_Plans/3-BR-App-New Cairo.jpg";
import penthouse3BRImage from "../../../../img/project_gallery/Floor_Plans/3 BR_Penthouse_New_Cairo.jpg";
import penthouse4BRImage from "../../../../img/project_gallery/Floor_Plans/4_BR_Penthouse_New_Cairo.jpg";
import duplex3BRImage from "../../../../img/project_gallery/Floor_Plans/3-BR-Duplx New Cairo.jpg";
import duplex4BRImage from "../../../../img/project_gallery/Floor_Plans/4BR-Duplx New Cairo.jpg";
import townhouseImage from "../../../../img/project_gallery/Floor_Plans/Town_House_New_Cairo.jpg";
// Zone 2.
import BR3VillaA from "../../../../img/project_gallery/Floor_Plans/3_BR_Villa_Type_A_New_Cairo.jpg";
import BR3VillaB from "../../../../img/project_gallery/Floor_Plans/3_BR_Villa_Type_A_New_Cairo.jpg";
import BR4Villa from "../../../../img/project_gallery/Floor_Plans/4BR-Villa-New-Cairo.jpg";
import BR5Villa from "../../../../img/project_gallery/Floor_Plans/5BR-Villa-New-Cairo.jpg";

type DisplayState = undefined | "none";

export const NewCairoFloorsPlans = () => {
  //? Change the visibility state
  const [isDisplayed, setIsDisplayed] = useState<DisplayState>("none");

  const [isMinH896, isMinH812] = useMediaQuery([
    "(min-height:896px)",
    "(min-height:812px)",
  ]);

  const hOf375 = isMinH812 ? "85%" : "77%";
  const hOf414 = isMinH896 ? "85%" : "77%";

  // Landscape Styling.
  const containerStylesInLandscape = {
    h: {
      "1xsm": "100%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <VStack
      className={externalStyles.option_images}
      w="100%"
      h={[
        "85%",
        "85%",
        "77%",
        hOf375,
        hOf414,
        "77%",
        null,
        null,
        "72%",
        "77%",
        "65vh",
      ]}
      spacing={[
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "0.5rem",
        "1rem",
        "2rem",
      ]}
      overflow="auto"
      {...styles}
    >
      <Heading as="h3" w="full" fontSize="4rem" color="white">
        Apartments
      </Heading>
      <ImagesRow
        first={{
          BedroomNumber: 1,
          ImageSrc: apartment1BRImage,
          display: setIsDisplayed,
        }}
        second={{
          BedroomNumber: 2,
          ImageSrc: apartment2BRImage,
          display: setIsDisplayed,
        }}
        three={{
          BedroomNumber: 3,
          ImageSrc: apartment3BRImage,
          display: setIsDisplayed,
        }}
        four={{
          BedroomNumber: 4,
          ImageSrc: apartment4BRImage,
          display: setIsDisplayed,
        }}
      />

      <Heading as="h3" w="full" fontSize="4rem" color="white">
        Penthouse
      </Heading>
      <ImagesRow
        first={{
          BedroomNumber: 3,
          ImageSrc: penthouse3BRImage,
          display: setIsDisplayed,
        }}
        second={{
          BedroomNumber: 4,
          ImageSrc: penthouse4BRImage,
          display: setIsDisplayed,
        }}
      />

      <Heading as="h3" w="full" fontSize="4rem" color="white">
        Duplex
      </Heading>
      <ImagesRow
        first={{
          BedroomNumber: 3,
          ImageSrc: duplex3BRImage,
          display: setIsDisplayed,
        }}
        second={{
          BedroomNumber: 4,
          ImageSrc: duplex4BRImage,
          display: setIsDisplayed,
        }}
      />

      <Heading as="h3" w="full" fontSize="4rem" color="white">
        Townhouse
      </Heading>
      <ImagesRow
        first={{
          BedroomNumber: 3,
          ImageSrc: townhouseImage,
          display: setIsDisplayed,
        }}
        second={{
          BedroomNumber: 4,
          ImageSrc: townhouseImage,
          display: setIsDisplayed,
        }}
      />

      <Heading as="h3" w="full" fontSize="4rem" color="white">
        Villa 3BR A
      </Heading>
      <ImagesRow
        first={{
          BedroomNumber: 3,
          ImageSrc: BR3VillaA,
          display: setIsDisplayed,
        }}
      />

      <Heading as="h3" w="full" fontSize="4rem" color="white">
        Villa 3BR B
      </Heading>
      <ImagesRow
        first={{
          BedroomNumber: 3,
          ImageSrc: BR3VillaB,
          display: setIsDisplayed,
        }}
      />

      <Heading as="h3" w="full" fontSize="4rem" color="white">
        Villa 4BR
      </Heading>
      <ImagesRow
        first={{
          BedroomNumber: 4,
          ImageSrc: BR4Villa,
          display: setIsDisplayed,
        }}
      />

      <Heading as="h3" w="full" fontSize="4rem" color="white">
        Villa 5BR
      </Heading>
      <ImagesRow
        first={{
          BedroomNumber: 5,
          ImageSrc: BR5Villa,
          display: setIsDisplayed,
        }}
      />

      <ImagePopoverBrief
        display={isDisplayed}
        setDisplay={setIsDisplayed}
        zIndex="20"
        images={{
          apartment_1BR:apartment1BRImage,
          apartment_2BR: apartment2BRImage,
          apartment_3BR: apartment3BRImage,
          apartment_4BR: apartment4BRImage,
          duplex_3BR : duplex3BRImage,
          duplex_4BR : duplex4BRImage,
          penthouse_3BR:penthouse3BRImage,
          penthouse_4BR: penthouse4BRImage,
          townhouse_3BR:townhouseImage,
          townhouse_4BR:townhouseImage,
          villa_4BR: BR4Villa,
          villa_5BR: BR5Villa,
          villa_a_3BR : BR3VillaA,
          villa_b_3BR : BR3VillaB,
        }}
      />
    </VStack>
  );
};
