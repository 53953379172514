import { NavButton } from "components/project-zone-page/NavButtons/NavButton";
import { SectionOneProps } from "./SectionOne";

const SectionThree = (props: SectionOneProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.7%"
        rotateDegree={29}
        topOffset="47.1%"
        leftOffset="41.7%"
        hasUnits={true}
        plotNumber="254"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={40}
        topOffset="45.0%"
        leftOffset="40.4%"
        hasUnits={true}
        plotNumber="255"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={59}
        topOffset="42.5%"
        leftOffset="38.8%"
        hasUnits={true}
        plotNumber="256"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={70}
        topOffset="39.5%"
        leftOffset="38.1%"
        hasUnits={true}
        plotNumber="257"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={76}
        topOffset="36.1%"
        leftOffset="37.8%"
        hasUnits={true}
        plotNumber="258"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={89}
        topOffset="32.8%"
        leftOffset="37.6%"
        hasUnits={true}
        plotNumber="259"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={92}
        topOffset="29.2%"
        leftOffset="37.4%"
        hasUnits={true}
        plotNumber="260"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={73}
        topOffset="25.6%"
        leftOffset="37.2%"
        hasUnits={true}
        plotNumber="261"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={52}
        topOffset="22.3%"
        leftOffset="36.6%"
        hasUnits={true}
        plotNumber="262"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={40}
        topOffset="19.6%"
        leftOffset="35.6%"
        hasUnits={true}
        plotNumber="263"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={40}
        topOffset="17.1%"
        leftOffset="34.3%"
        hasUnits={true}
        plotNumber="264"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={12}
        topOffset="15.8%"
        leftOffset="32.6%"
        hasUnits={true}
        plotNumber="265"
        page="/facade/townhouse"
      />
    </>
  );
};

export default SectionThree;
