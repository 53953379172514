import {VStack} from "@chakra-ui/react";
import {useChoseStyleOnLandscape} from "../../../utils/Selecting";

import ZoneImage from "./ZoneImage";
import ZonesContainer from "./ZonesContainer";

export default function NewCairoHeroImage() {
    const containerStylesInLandscape = {
        h: {
            "1xsm": "100%",
        }
    };
    const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

    return (
        <VStack
            w="100%"
            h={{base: "80%"}}
            position="relative"
            {...styles}
        >
            <ZoneImage/>
            <ZonesContainer/>
        </VStack>
    );
}
