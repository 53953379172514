import { SectionOneProps } from "./SectionOne";

import { NavButton } from "components/project-zone-page/NavButtons/NavButton";

const SectionFour = (props: SectionOneProps) => {
  return (
    <>
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={338}
        topOffset="16.3%"
        leftOffset="29.4%"
        hasUnits={true}
        plotNumber="267"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={315}
        topOffset="18.0%"
        leftOffset="27.9%"
        hasUnits={true}
        plotNumber="268"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={296}
        topOffset="20.9%"
        leftOffset="26.9%"
        hasUnits={true}
        plotNumber="269"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={296}
        topOffset="24.1%"
        leftOffset="26.4%"
        hasUnits={true}
        plotNumber="270"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={281}
        topOffset="27.4%"
        leftOffset="26.0%"
        hasUnits={true}
        plotNumber="271"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={269}
        topOffset="30.4%"
        leftOffset="26.0%"
        hasUnits={true}
        plotNumber="272"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={253}
        topOffset="34.0%"
        leftOffset="26.3%"
        hasUnits={true}
        plotNumber="273"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={245}
        topOffset="37.3%"
        leftOffset="27.1%"
        hasUnits={true}
        plotNumber="274"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={240}
        topOffset="40.1%"
        leftOffset="28.1%"
        hasUnits={true}
        plotNumber="275"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={216}
        topOffset="43.1%"
        leftOffset="29.4%"
        hasUnits={true}
        plotNumber="276"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={216}
        topOffset="45.1%"
        leftOffset="31.0%"
        hasUnits={true}
        plotNumber="277"
        page="/facade/townhouse"
      />
      <NavButton
        isDisplayed={props.display}
        buildingType={props.type}
        buttonWidth="1.1%"
        buttonHeight="3.4%"
        rotateDegree={216}
        topOffset="47.5%"
        leftOffset="32.2%"
        hasUnits={true}
        plotNumber="278"
        page="/facade/townhouse"
      />
    </>
  );
};

export default SectionFour;
