import useQueryProjectName, {
  ProjectNames,
} from "hooks/query_values/useQueryProjectName";

// Custom Components.
import ZayedFloorsPlans from "./ZayedFloorsPlans";
import NewCairoFloorsPlans from "./NewCairoFloorsPlans";

export default function OptionImages() {
  const projectName = useQueryProjectName();

  return projectName === ProjectNames.NEW_CAIRO ? (
    <NewCairoFloorsPlans />
  ) : (
    <ZayedFloorsPlans />
  );
}
