import { Box, Center } from "@chakra-ui/react";

import BtnTxt from "./BtnTxt";

type Props = {
  txt: string;
};

function BtnContainer(props: Props) {
  return (
    <Box
      w="100%"
      h={["30%", "30%", "30%", "30%", "30%", null, null, null, "35%", "25%"]}
      position="absolute"
      bottom="0"
      left="0"
      borderBottomRadius={{
        base: "1rem",
        mobile8: "1.5rem",
        mobile10: "1.9rem",
        tablet12: "3.0rem",
        desktop15: "3.3rem",
      }}
      bg="#000000bf"
    >
      <Center w="100%" h="100%">
        <BtnTxt txt={props.txt} />
      </Center>
    </Box>
  );
}

export default BtnContainer;
