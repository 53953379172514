import {Button} from "@chakra-ui/react";
import {
    useChoseOnLightMode,
    useChoseStyleOnLandscape,
} from "../../../../utils/Selecting";

type Props = {
    optionName: string;
    btnStyles?: any;
};

export default function Btn(props: Props) {
    const textColor = useChoseOnLightMode("black", "white");

    // Landscape Styling.
    const containerStylesInLandscape = {
        fontSize: {
            "1xsm": "0.8rem",
            xl: "1.5rem",
            lg: "1.2rem",
            ipadPro: "1.4rem",
            MacBookPro16: "1.7rem",
        },
        h: {
            "1xsm": "100%",
        },
    };
    const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

    return (
        <Button
            color={textColor}
            _active={{
                bg: "transparent",
                color: "white",
            }}
            _focus={{
                bg: "transparent",
                color: "white",
            }}
            _hover={{
                bg: "transparent",
                color: "white",
            }}
            _groupHover={{
                bg: "transparent",
                color: "white",
            }}
            _groupActive={{
                bg: "transparent",
                color: "white",
            }}
            _groupFocus={{
                bg: "transparent",
                color: "white",
            }}
            fontSize={{base: "0.8rem", mobile8: "xl", tablet13: "3xl", tablet16: "4xl"}}
            bg="transparent"
            {...styles}
            {...props.btnStyles}
        >
            {props.optionName}
        </Button>
    );
}
