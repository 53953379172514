import { HStack } from "@chakra-ui/react";
import {
  useChoseOnLightMode,
  useChoseStyleOnLandscape,
} from "../../../../utils/Selecting";

export interface ContainerProps {
  activateOption?: (x: any) => any;
  children?: any;
  isResidential?: boolean;
}

export default function Container(props: ContainerProps) {
  const bgColor = useChoseOnLightMode("transparent", "blue.700");
  const borderColor = useChoseOnLightMode("red.500", "transparent");

  // Landscape Styling.
  const containerStylesInLandscape = {
    w: {
      "1xsm": "100%",
    },
    h: {
      "1xsm": "28%",
      sm: "28%",
      ipadPro: "23%",
    },
    borderRadius: {
      "1xsm": "0.5rem",
      ipadPro: "1.2rem",
    },
    p: {
      "1xsm": "0.3rem",
      sm: "0.3rem",
      xl: "1rem",
      lg: "1rem",
      MacBookPro16: "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  const stylingObj = {
    "& svg path": {
      fill: `#E53E3E !important`,
    },
    "&:hover svg path": {
      fill: `white !important`,
    },
    "&:focus svg path": {
      fill: `white !important`,
    },
    "&:active svg path": {
      fill: `white !important`,
    },
  };

  return (
    <HStack
      role="group"
      w={{ base: "20%", mobile8: "32%", tablet16: "32%" }}
      h="100%"
      flex={1}
      spacing="2px"
      bg={bgColor}
      color="red.500"
      borderColor={borderColor}
      borderWidth={{ base: "thin", mobile8: "medium" }}
      borderRadius={{ base: "0.7rem", tablet16: "1.5rem" }}
      p={{ base: "0.6rem", tablet16: "1.5rem" }}
      boxShadow={{
        tablet16: "2px 0px 2px 1px red",
      }}
      _active={{
        bg: "red.500",
        color: "white",
      }}
      _focus={{
        bg: "red.500",
        color: "white",
      }}
      _hover={{
        bg: "red.500",
        color: "white",
      }}
      onClick={props.activateOption}
      {...styles}
      sx={!props.isResidential && stylingObj}
    >
      {props.children}
    </HStack>
  );
}
