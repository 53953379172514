import { useState } from "react";

// Custom Components
import BookDisplayOverlay from "./BookDisplayOverlay";
import BookDisplayTriggerButton from "./BookDisplayTriggerButton";
// import useMakeUnitUnavailable from "./hooks/useMakeUnitUnavailable";

// Interfaces
import { DisplayBookingInfoProps } from "./BookComp/DisplayBookingInfo/DisplayBookingInfo";
import useMakeUnitPending from "./hooks/useMakeUintPending";

export interface FormBookCompProps extends DisplayBookingInfoProps {
  id?: string;
  isFloorUnitPage?: boolean;
}

export const FormBookComp = (props: FormBookCompProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useMakeUnitPending(props.id || "62637640768e23170908ea6d", {
    closeElement: setIsOpen,
    isOpen,
    time: 120000, // Two Minutes,
  });

  return (
    <>
      <BookDisplayTriggerButton onOpen={setIsOpen} />
      <BookDisplayOverlay
        isDisplayed={isOpen}
        onClose={setIsOpen}
        Price={props.Price}
        plotArea={props.plotArea}
        projectName={props.projectName}
        unitName={props.unitName}
        unitTextInfo={props.unitTextInfo}
        propertyId={!props.isFloorUnitPage ? props.id : undefined}
      />
    </>
  );
};
