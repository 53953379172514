import { memo } from "react";

// Custom Components
import HeroImg from "./hero-img/HeroImg";
import Header from "../../../components/common/header/Header";
import LocationIndicator from "../../../components/sub-components/LocationIndicator/LocationIndicator";
import FloorPlateNavOptions from "../../../components/common/floor-plate-nav-options/FloorPlateNavOptions";
import PageContainerComponent from "../../../components/common/page-container-component/PageContainerComponent";

// Custom Hooks
// import useSingleFetcher from "../../../hooks/useSingleFetcher";
import useVerifyAuth from "../../../hooks/navigation/useVerifyAuth";
import useFetchProperty from "hooks/fetching_values/useFetchProperty";
import useQueryProjectName from "hooks/query_values/useQueryProjectName";

import { useQuery } from "../../../utils/Values";
import { useGetQueryData } from "../../../utils/Navigation";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";
import { useGetStackTypeElOnPageOrientation } from "../../../utils/Values";

// Utils
// import { updateUnitToUnavailable } from "standalone-components/FormBookComp/hooks/useMakeUnitUnavailable";
// import { updateUnitToItsPrevStatus } from "standalone-components/FormBookComp/hooks/useMakeUnitUnavailable";

// Interfaces
// import Property from "../../../interfaces/property";

// Enums
// import { Status } from "../../../interfaces/property";

export const TownhousePlatePage = memo(() => {
  // Auth before accessing this page
  useVerifyAuth();

  const { pageHeader } = useGetQueryData();

  // Landscape Styles.
  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  const subContainerStylesInLandscape = {
    spacing: {
      "1xsm": "1rem",
    },
    minH: {
      "1xsm": "75%",
    },
  };
  const subContainerStyles = useChoseStyleOnLandscape(
    subContainerStylesInLandscape
  );

  // Landscape Switched Components
  const StackType = useGetStackTypeElOnPageOrientation();

  // Url Navigation.
  const query = useQuery();
  const zoneNumber = query.get("z") || "1";
  const projectName = useQueryProjectName();
  const url = `project-zone-page?z=${zoneNumber}&p=${projectName}`;
  // const bedRoomsNumber = useQueryBedroomNumbers();
  // const type = query.get("type") || "";
  // const unitNumber = query.get("unitNumber") || "1";
  // const unitName = `${bedRoomsNumber}-BR-${type[0]?.toUpperCase()}-${unitNumber}`;
  // const unitTextInfo = `${bedRoomsNumber} Bedrooms ${type} Unit`;

  const id = query.get("id");

  const propertyInfo = useFetchProperty();
  

  return (
    <PageContainerComponent
      spacing={{ base: "0rem", tablet16: "2rem" }}
      overflow="hidden"
      h="100%"
      {...styles}
    >
      <Header
      // formData={{
      //   GSA: propertyInfo ? propertyInfo.GSA : 20000,
      //   projectName: projectName,
      //   plotArea: propertyInfo ? propertyInfo.plotArea : 840000,
      //   unitName: propertyInfo ? propertyInfo.name : unitName,
      //   unitTextInfo: propertyInfo ? propertyInfo.type : unitTextInfo,
      //   id: id || "62637640768e23170908ea6d",
      //   isFloorUnitPage: true,
      // }}
      />
      <LocationIndicator
        pageUrl={url}
        text= {propertyInfo ? propertyInfo.Description : pageHeader }
      />
      <StackType
        w="100%"
        minH="75%"
        spacing={{ base: "2vh", tablet16: "3rem" }}
        {...subContainerStyles}
      >
        <HeroImg
          // bedroomsNumber={bedRoomsNumber || propertyInfo?.bedroomsNumber}
        />
        <FloorPlateNavOptions
          // bedroomsNumber={propertyInfo?.bedroomsNumber}
          id={id}
          isPlanPage={true}
        />
      </StackType>
    </PageContainerComponent>
  );
});
