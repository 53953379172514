import { memo } from "react";

// Stylings
import styles from "./InfoTxt.module.css";

// Custom Components
import MotionText from "../../../../animated-components/MotionText/MotionText";

// Utils
import { useChoseOnLightMode } from "../../../../../utils/Selecting";
import { useChoseOnProjectName } from "../../../../../utils/Selecting";

type Props = {
  boxTxt?: string;
  fontSize?: any;
};

function InfoTxt(props: Props) {
  const modeTxtColor = useChoseOnLightMode("black", "gray.200");

  const NewCairoText =
    "Located in the heart of New Cairo, close to many key attractions, just 15 min away from AUC and 10 min from Suez road.";
  const ZayedText =
    "Located in the heart of Shakh Zayed, close to many key attractions, just 10 min away from Hyper 1 and Mehwar road.";
  const locationTxt = useChoseOnProjectName("zayed", ZayedText, NewCairoText);

  return (
    <MotionText
      className={styles.info_txt}
      initial={{
        opacity: 0,
        display: "none",
      }}
      animate={{
        opacity: 1,
        display: "block",
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        delay: 1,
        duration: 1,
      }}
      w="full"
      h="full"
      fontSize={
        props.fontSize || {
          base: "0.94rem",
          sm: "1.1rem",
          miniMobile10: "0.8rem",
          tablet16: "1.4rem",
          desktop5: "1.5rem",
        }
      }
      color={modeTxtColor}
    >
      {props.boxTxt || locationTxt}
    </MotionText>
  );
}

export default memo(InfoTxt);
