import { lazy, memo, Suspense } from "react";
import { Center, Spinner } from "@chakra-ui/react";

// Custom Hooks
import useVerifyAuth from "../../../hooks/navigation/useVerifyAuth";

import { useChoseStyleOnLandscape } from "../../../utils/Selecting";

import useQueryPlotNumber from "hooks/query_values/useQueryPlotNumber";
import useQueryUnitType from "hooks/query_values/useQueryUnitType";
// Custom Components
import LocationIndicator from "../../../components/sub-components/LocationIndicator/LocationIndicator";
import PageContainerComponent from "../../../components/common/page-container-component/PageContainerComponent";

// Lazy Loading Custom Components
const HeroImage = lazy(() => import("./ProjectHeroImage/ProjectHeroImage"));

function PropertyDisplayPage() {
  // Auth before accessing this page
  useVerifyAuth();


  const containerStylesInLandscape = {
    spacing: {
      "1xsm": "0.9rem",
    },
  };

  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);


  const unitType = useQueryUnitType();
  const plotNumber = useQueryPlotNumber();

  const header = `${unitType}s Plot-${plotNumber}`;

  return (
    <PageContainerComponent {...styles}>
      <LocationIndicator text={header} />
      <Suspense fallback={<LazyLoading />}>
        <HeroImage />
      </Suspense>
    </PageContainerComponent>
  );
}

const MemoizedPropertyDisplayPage = memo(PropertyDisplayPage);

export { MemoizedPropertyDisplayPage as DuplexFacadePage };

export function LazyLoading() {
  return (
    <Center w="full" h="full">
      <Spinner
        size="xl"
        emptyColor="gray.200"
        thickness="2px"
        speed="0.85s"
        color="red.500"
      />
    </Center>
  );
}
