import { Center } from "@chakra-ui/react";

import BookComp from "../BookComp";

import { BookCompProps } from "../BookComp/BookComp";

export interface BookDisplayOverlayProps extends BookCompProps {
  isDisplayed: boolean;
}

export const BookDisplayOverlay = (props: BookDisplayOverlayProps) => {
  return (
    <Center
      display={props.isDisplayed ? undefined : "none"}
      zIndex="10000"
      pos="fixed"
      top="0"
      left="0"
      w="100vw"
      h="100vh"
      bgColor="#000000aa"
    >
      <BookComp {...props} />
    </Center>
  );
};
