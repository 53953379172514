import { Image } from "@chakra-ui/react";
import GoogleMapComp from "../../common/google-map-comp/GoogleMapComp";

import MarkerIcon from "../../../img/icons/Marker-Icon-Bg-White.png";

import Pin from "../pins/Pin";
import { IconsNames } from "../pins/utils";

export default function Map() {
  return (
    <GoogleMapComp>
      <Image
        lat={30.01148915262006}
        lng={31.543827276987642}
        src={MarkerIcon}
        w={["70px", "35px", "35px", "35px", "35px", "90px"]}
        h={["70px", "35px", "35px", "35px", "35px", "90px"]}
      />
      {/* Universities */}
      <Pin
        lat={30.037575023545205}
        lng={31.486846950738013}
        iconName={IconsNames.University}
      />
      <Pin
        lat={30.01881277777923}
        lng={31.501451277401625}
        iconName={IconsNames.University}
      />
      <Pin
        lat={30.037303132302107}
        lng={31.46737451518652}
        iconName={IconsNames.University}
      />
      <Pin
        lat={30.038118803793267}
        lng={31.54400797122786}
        iconName={IconsNames.University}
      />
      <Pin
        lat={29.991614815925487}
        lng={31.475854446797655}
        iconName={IconsNames.University}
      />
      {/* Residential */}
      <Pin
        lat={30.0048256889594}
        lng={31.52153102818278}
        iconName={IconsNames.Residential}
        isResidential
      />
      <Pin
        lat={30.00839336595411}
        lng={31.490116994570887}
        iconName={IconsNames.Residential}
        isResidential
      />
      <Pin
        lat={30.026824319701547}
        lng={31.45475474908428}
        iconName={IconsNames.Residential}
        isResidential
      />
      <Pin
        lat={30.060704550633247}
        lng={31.417847554366098}
        iconName={IconsNames.Residential}
        isResidential
      />
      {/* Shopping */}
      <Pin
        lat={30.027112290071848}
        lng={31.46659935569942}
        iconName={IconsNames.Shopping}
      />
      <Pin
        lat={30.054157890145824}
        lng={31.48908699724673}
        iconName={IconsNames.Shopping}
      />
      <Pin
        lat={30.004519099633953}
        lng={31.460591207194415}
        iconName={IconsNames.Shopping}
      />
      {/* Schools */}
      <Pin
        lat={30.06707440136251}
        lng={31.43724522646765}
        iconName={IconsNames.School}
      />
      <Pin
        lat={30.049393881606964}
        lng={31.452179767037237}
        iconName={IconsNames.School}
      />
      <Pin
        lat={30.0382490750523}
        lng={31.426258897772783}
        iconName={IconsNames.School}
      />
      {/* Medical */}
      <Pin
        lat={30.06146814227007}
        lng={31.495189625098167}
        iconName={IconsNames.Medical}
      />
      <Pin
        lat={30.034281674300058}
        lng={31.49885060972475}
        iconName={IconsNames.Medical}
      />
      {/* Clubs */}
      <Pin
        lat={30.063341851292424}
        lng={31.526831074123482}
        iconName={IconsNames.Club}
      />
      <Pin
        lat={30.01737246934265}
        lng={31.489766837911617}
        iconName={IconsNames.Club}
      />
      {/* ----------- Zayed Images --------- */}
      <Image
        lat={30.06292412070828}
        lng={30.967756401665575}
        src={MarkerIcon}
        w="40px"
        h="40px"
      />
      {/* Zayed Universities */}
      <Pin
        lat={30.04995247411315}
        lng={30.936427874970046}
        iconName={IconsNames.University}
      />
      <Pin
        lat={30.041420429605226}
        lng={30.978645800718375}
        iconName={IconsNames.University}
      />
      <Pin
        lat={30.048835587005824}
        lng={31.009816012087587}
        iconName={IconsNames.University}
      />
      {/* Zayed Residential */}
      <Pin
        lat={30.047852515343106}
        lng={30.989042447889137}
        iconName={IconsNames.Residential}
        isResidential
      />
      <Pin
        lat={30.053201660136722}
        lng={30.999342129864903}
        iconName={IconsNames.Residential}
        isResidential
      />
      <Pin
        lat={30.05684188511902}
        lng={30.98912827857227}
        iconName={IconsNames.Residential}
        isResidential
      />
      {/* Zayed Shopping */}
      <Pin
        lat={30.027788330498456}
        lng={31.004835285601644}
        iconName={IconsNames.Shopping}
      />
      <Pin
        lat={30.054536583807387}
        lng={30.961404958142843}
        iconName={IconsNames.Shopping}
      />
      <Pin
        lat={30.06144537534049}
        lng={30.959087529602556}
        iconName={IconsNames.Shopping}
      />
      {/* Zayed Schools */}
      <Pin
        lat={30.053197024689307}
        lng={30.953594356558053}
        iconName={IconsNames.School}
      />
      <Pin
        lat={30.050151019053228}
        lng={30.978056102261128}
        iconName={IconsNames.School}
      />
      <Pin
        lat={30.03291331998261}
        lng={30.974279552047324}
        iconName={IconsNames.School}
      />
      {/* Zayed Medical */}
      <Pin
        lat={30.0609950258086}
        lng={30.982347627497877}
        iconName={IconsNames.Medical}
      />
      <Pin
        lat={30.03744361829012}
        lng={31.011701722341567}
        iconName={IconsNames.Medical}
      />
      {/* Zayed Clubs */}
      <Pin
        lat={30.076981191981983}
        lng={30.95771421132431}
        iconName={IconsNames.Club}
      />
    </GoogleMapComp>
  );
}
