import _ from "lodash";

import { IconType } from "react-icons";

import Button from "./Btn";
import Icon, { OptionIconProps } from "./OptionIcon";
import Container, {ContainerProps} from "./Container";

import { useSelector, useDispatch } from "react-redux";
import { activateIcon } from "../../../../app/map/activeCompSlice";
import { deactivateIcon } from "../../../../app/map/activeCompSlice";
import { selectActiveIcons } from "../../../../app/map/activeCompSlice";

export interface SingleOptionButtonProps extends OptionIconProps, ContainerProps {
  icon: IconType | any;
  optionName: string;
}

export default function SingleOptionButton(props: SingleOptionButtonProps) {
  const activeIcons = useSelector(selectActiveIcons);
  const dispatch = useDispatch();

  function toggleActivationForIcon() {
    const isActive = !!_.propertyOf(activeIcons)(props.optionName);
    if (isActive) return dispatch(deactivateIcon(props.optionName));

    return dispatch(activateIcon(props.optionName));
  }

  return (
    <Container activateOption={toggleActivationForIcon} isResidential={props.isResidential}>
      <Icon
        iconStyle={{
          flex: 1,
        }}
        icon={props.icon}
      />
      <Button
        btnStyles={{
          flex: 3,
        }}
        optionName={props.optionName}
      />
    </Container>
  );
}
