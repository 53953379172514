import { PropertyTypes } from "interfaces/property";
import { NavButtonVillas } from "../NavButtons/NavButton";
import OptionButtonsContainer from "./FirstOptionButtons/OptionButtonsContainer";

export function ApartmentOption(props: { displayed: boolean }) {
  const display = props.displayed ? undefined : "none";
  const type = PropertyTypes.APARTMENT;

  return (
    <>
      <OptionButtonsContainer
        hasUnits={true}
        propertyUrl="/facade/apartment"
        display={display}
        type={type}
      />
    </>
  );
}

export function TwoBedRoomVillaOption(props: { displayed: boolean }) {
  const display = props.displayed ? undefined : "none";
  const type = PropertyTypes.VILLA;
  const bedroom = 3;

  return (
    <>
      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="51.3%"
        leftOffset="30.9%"
        buttonWidth="3%"
        buttonHeight="6%"
        rotateDegree={19}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="61.2%"
        leftOffset="27.3%"
        buttonWidth="3%"
        buttonHeight="7%"
        rotateDegree={22}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="66.5%"
        leftOffset="34%"
        buttonWidth="3%"
        buttonHeight="7%"
        rotateDegree={22}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="71.8%"
        leftOffset="39.9%"
        buttonWidth="3%"
        buttonHeight="2.5rem"
        rotateDegree={22}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="80%"
        leftOffset="53%"
        buttonWidth="3%"
        buttonHeight="2.5rem"
        rotateDegree={0}
        hasUnits
        plotNumber="360"
      />

      <NavButtonVillas
        bedroomNumber={bedroom}
        buildingType={type}
        isDisplayed={display}
        topOffset="77%"
        leftOffset="46.3%"
        buttonWidth="3%"
        buttonHeight="2.5rem"
        rotateDegree={22}
        hasUnits
        plotNumber="360"
      />
    </>
  );
}
