import { useState } from "react";
import { VStack, Image, Center, AspectRatio } from "@chakra-ui/react";

import View360Icon from "../../../../img/icons/360-Icon.svg";

// Custom Hooks.
import useSelect360PropertyView from "../../../../hooks/selection/useSelect360PropertyView";
import useSelectPropertyPlanImage from "../../../../hooks/selection/useSelectPropertyPlanImage";

export interface HeroImgProps {
  bedroomsNumber?: number;
}

export default function HeroImg(props: HeroImgProps) {
  const heroImage = useSelectPropertyPlanImage(props.bedroomsNumber);
  const _360Link = useSelect360PropertyView(props.bedroomsNumber);

  const [showTour, setShowTour] = useState(false);

  return (
    <VStack
      position="relative"
      w="100%"
      h={{ base: "91%" }}
      spacing="2rem"
      bg="white"
      borderRadius={{ base: "1rem", tablet16: "3rem" }}
    >
      <Center w="100%" h="100%">
        <Image w="100%" h="100%" borderRadius="3rem" src={heroImage} />
        <Image
          src={View360Icon}
          w={{ base: "20%", tablet16: "17%" }}
          position="absolute"
          top="0"
          left="0"
          zIndex="50"
          onClick={() => setShowTour(!showTour)}
        />
        {showTour ? (
          <AspectRatio
            position="absolute"
            top="0"
            left="0"
            zIndex="40"
            w="full"
            h="full"
          >
            <iframe
              title="360 tour for the villas"
              src={_360Link}
              width="100%"
              height="100%"
              style={{
                borderRadius: "3%",
              }}
            />
          </AspectRatio>
        ) : null}
      </Center>
    </VStack>
  );
}
