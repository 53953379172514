import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useChoseStyleOnLandscape } from "../../../utils/Selecting";
import { useThemeValueBool } from "../../../utils/Values";

type DeliveryYearOptionProps = {
  availableYear: string | number;
};
export default function DeliveryYearOption(props: DeliveryYearOptionProps) {
  const isLightTheme = useThemeValueBool();
  const bgColor = isLightTheme ? "transparent" : "gray.300";
  const borderColor = isLightTheme ? "red.500" : "transparent";
  const url = `delivery-year-page?title=${props.availableYear}`;

  // Landscape Styling.
  const containerStylesInLandscape = {
    fontSize: {
      "1xsm": "1rem",
      desktop5: "2.0rem",
    },
    borderRadius: {
      "1xsm": "0.5rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <Button
      as={Link}
      to={url}
      bg={bgColor}
      borderColor={borderColor}
      borderWidth={{ base: "thin", tablet16: "medium" }}
      fontSize={{
        base: "1rem",
        mobile8: "1.5rem",
        tablet16: "2.3rem",
        desktop5: "2.5rem",
      }}
      w="100%"
      h="90%"
      borderRadius={{ base: "0.7rem", mobile8: "0.9rem", tablet16: "1rem" }}
      {...styles}
    >
      {props.availableYear}
    </Button>
  );
}
