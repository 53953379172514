import { useGetQueryValue } from "utils/Values";

export default function useQueryUnitNumber() {
  const unitNumber = useGetQueryValue("unit");

  if (!unitNumber) {
    return "";
  }

  return unitNumber;
}
