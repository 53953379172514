import { Input } from "@chakra-ui/react";

import { memo, useEffect, useRef } from "react";

import isEmail from "validator/es/lib/isEmail";

import { useDispatch, useSelector } from "react-redux";

import { useChoseStyleOnLandscape } from "../../../../../utils/Selecting";

import {
  showErrorMsg,
  hideErrorMsg,
  selectSharingVisibility,
} from "../../sharingSlice";

function InputEl(props: any) {
  const isSharingComponentShown = useSelector(selectSharingVisibility);
  const dispatch = useDispatch();

  function getEmail(e: { target: any }) {
    const inputValue = e.target.value;
    const emailType =
      props.selectorRef.current && props.selectorRef.current["value"];

    const fullEmail = inputValue + emailType;
    const isValidEmail = isEmail(fullEmail);
    if (isValidEmail) {
      return dispatch(hideErrorMsg(fullEmail));
    } else {
      return dispatch(showErrorMsg());
    }
  }

  // Landscape Styling.
  const containerStylesInLandscape = {
    borderLeftRadius: {
      "1xsm": "0.7rem",
    },
    fontSize: {
      "1xsm": "0.6rem",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSharingComponentShown.isComponentVisible) {
      inputRef.current?.focus();
    }
  }, [isSharingComponentShown]);

  return (
    <Input
      ref={inputRef}
      w="70%"
      h="70%"
      borderWidth="1px"
      borderColor="red.500"
      borderLeftRadius={{ base: "0.8rem", tablet16: "1rem" }}
      placeholder="Please Enter A Valid Email"
      fontSize="1.6rem"
      type="email"
      onChange={getEmail}
      {...styles}
    />
  );
}

export default memo(InputEl);
