import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

import { ActiveState } from "../../../app/map/activeCompSlice";

type ComponentVisibility = {
  isComponentVisible: ActiveState;
  isErrorMsgVisible: ActiveState;
  isDisclaimerVisible: ActiveState;
  clientEmail: string;
};

const initialState: ComponentVisibility = {
  isComponentVisible: ActiveState.NotActive,
  isErrorMsgVisible: ActiveState.NotActive,
  isDisclaimerVisible: ActiveState.NotActive,
  clientEmail: "ahmed.m.a.karmy05@gmail.com",
};

export const sharingSlice = createSlice({
  name: "sharingVisibility",
  initialState,
  reducers: {
    hideSharing: (state: any) => {
      state.isComponentVisible = ActiveState.NotActive;
    },
    hideErrorMsg: (state: any, action: { payload: string }) => {
      state.isErrorMsgVisible = ActiveState.NotActive;
      state.clientEmail = action.payload;
    },
    showSharing: (state: any) => {
      state.isComponentVisible = ActiveState.Active;
    },
    showErrorMsg: (state: any) => {
      state.isErrorMsgVisible = ActiveState.Active;
    },
    setClientEmail: (state: any, action: { payload: string }) => {
      state.clientEmail = action.payload;
    },
    hideDisclaimer: (state: any) => {
      state.isDisclaimerVisible = ActiveState.NotActive;
    },
    showDisclaimer: (state: any) => {
      state.isDisclaimerVisible = ActiveState.Active;
    },
  },
});

export const { hideSharing, showSharing } = sharingSlice.actions;
export const { hideErrorMsg, showErrorMsg } = sharingSlice.actions;
export const { hideDisclaimer, showDisclaimer } = sharingSlice.actions;
export const { setClientEmail } = sharingSlice.actions;

export const selectSharingVisibility = (state: RootState) =>
  state.sharingVisibility;

export const selectErrorMsgVisibility = (state: RootState) =>
  state.sharingVisibility.isErrorMsgVisible;

export const selectClientEmail = (state: RootState) =>
  state.sharingVisibility.clientEmail;

export const selectDisclaimerVisibility = (state: RootState) =>
  state.sharingVisibility.isDisclaimerVisible;

export default sharingSlice.reducer;
