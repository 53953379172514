// 1. Import the utilities
import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

// 2. Update the breakpoints as key-value pairs
const breakpoints = createBreakpoints({
  "7xsm": "360px",
  "6xsm": "375px",
  "5xsm": "390px",
  "4xsm": "412px",
  "3xsm": "414px",
  "2xsm": "428px",
  "1xsm": "480px",
  xsm2: "600px",
  xsm: "700px",
  sm: "800px",
  md: "960px",
  md2: "1000px",
  lg: "1080px",
  xl: "1280px",
  ipadPro: "1366px",
  "2xl": "1440px",
  MacBookPro16: "1536px",
  "3xl": "1920px",
  "4xl": "3840px",
  // ExtraSmallMobiles
  xsMobile1: "100px",
  xsMobile2: "110px",
  xsMobile3: "120px",
  xsMobile4: "130px",
  xsMobile5: "140px",
  xsMobile6: "150px",
  xsMobile7: "160px",
  xsMobile8: "170px",
  // MiniMobile
  miniMobile1: "180px",
  miniMobile2: "200px",
  miniMobile3: "220px",
  miniMobile4: "240px",
  miniMobile5: "260px",
  miniMobile6: "280px",
  miniMobile7: "300px",
  miniMobile8: "320px",
  miniMobile9: "340px",
  miniMobile10: "360px",
  miniMobile11: "380px",
  miniMobile12: "390px",
  // Mobiles
  mobile1: "400px",
  mobile2: "420px",
  mobile3: "440px",
  mobile4: "460px",
  mobile5: "480px",
  mobile6: "500px",
  mobile7: "530px",
  mobile8: "600px",
  mobile9: "620px",
  mobile10: "640px",
  mobile11: "660px",
  mobile12: "680px",
  mobile13: "700px",
  mobile14: "720px",
  mobile15: "740px",
  mobile16: "760px",
  // Tablets
  tablet1: "780px",
  tablet2: "800px",
  tablet3: "820px",
  tablet4: "840px",
  tablet5: "860px",
  tablet6: "880px",
  tablet7: "900px",
  tablet8: "920px",
  tablet9: "940px",
  tablet10: "960px",
  tablet11: "980px",
  tablet12: "1000px",
  tablet13: "1020px",
  tablet14: "1040px",
  tablet15: "1060px",
  tablet16: "1080px",
  tablet17: "1100px",
  tablet18: "1120px",
  tablet19: "1130px",
  tablet20: "1140px",
  tablet21: "1160px",
  tablet22: "1180px",
  // Desktop
  desktop1: "1200px",
  desktop2: "1220px",
  desktop3: "1240px",
  desktop4: "1260px",
  desktop5: "1280px",
  desktop6: "1300px",
  desktop7: "1320px",
  desktop8: "1360px",
  desktop9: "1380px",
  desktop10: "1400px",
  desktop11: "1420px",
  desktop12: "1460px",
  desktop13: "1480px",
  desktop14: "1500px",
  desktop15: "1520px",
  desktop16: "1540px",
  desktop17: "1560px",
  desktop18: "1600px",
  desktop19: "1640px",
});

// 3. Extend the theme
const theme = extendTheme({ breakpoints });

export default theme;
