import { HStack } from "@chakra-ui/react";

import { useChoseStyleOnLandscape } from "../../utils/Selecting";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export default function ProjectsDisplayRow(props: Props) {
  const containerStylesInLandscape = {
    h: {
      "1xsm": "67%",
    },
  };
  const styles = useChoseStyleOnLandscape(containerStylesInLandscape);

  return (
    <HStack
      w="100%"
      justifyContent="space-between"
      h={["80%", "18%", "18%", "22%"]}
      spacing={{
        base: "0.5rem",
        miniMobile9: "0.9rem",
        miniMobile12: "1rem",
        mobile5: "1.2rem",
        mobile10: "1.7rem",
      }}
      {...styles}
    >
      {props.children}
    </HStack>
  );
}
